import React from 'react';

import Text from '@components/atoms/text';
import { RankingComponentItem } from '@components/molecules/ranking';
import style from './style.module.css';

import { ExpiredBids } from '@hooks/get-mcp-proposals/types';

interface ExpiredBidProps {
  bid: ExpiredBids;
  displayBtn: boolean;
  onClick: () => void;
}

const ExpiredBid: React.FC<ExpiredBidProps> = ({ bid, onClick, displayBtn }) => {
  return (
    <>
      <Text className={style.RankingTitleProposalItem}>Sua última proposta expirada</Text>
      <RankingComponentItem
        deadline={bid.deadline}
        id={bid.id}
        price={bid.spread}
        status={displayBtn ? 'EXPIRED' : undefined}
        supplier=""
        onClick={onClick}
      />
    </>
  );
};

export default ExpiredBid;
