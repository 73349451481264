import { gql } from '@apollo/client';

export const CREATE_BID = gql`
  mutation CreateMcpBid($input: CreateMCPBidInput!) {
    createMcpBid(input: $input) {
      id
      bestOffer
      spread
      deadline
    }
  }
`;
