import React from 'react';
import { twMerge } from 'tailwind-merge';

import ButtonComponent, { ButtonComponentProps } from '../button';

import style from './style.module.css';

interface HeaderButtonsProps {
  buttons: ButtonComponentProps[];
  extraClassName?: string;
}

const HeaderButtons: React.FC<HeaderButtonsProps> = ({ buttons, extraClassName }) => {
  return (
    <div className={twMerge(style.layoutHeaderLeftComponent, extraClassName)}>
      {buttons && buttons.map((button, idx) => <ButtonComponent {...button} key={`btn-header-${idx}`} />)}
    </div>
  );
};

export default HeaderButtons;
