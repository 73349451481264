import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useAuth from '@hooks/auth';
import { useUserTrader } from '@hooks/get-user-trader/useUserTrader';
import { useCreateIndicativePrices } from '@hooks/indicative-prices/mutations/create/useCreateIndicativePrices';
import { IndicativePriceInput } from '@hooks/indicative-prices/mutations/create/types';
import { EnergyType, SubmarketType } from '@hooks/indicative-prices/types';
import ButtonComponent from '@components/atoms/button';
import { CheckboxComponent } from '@components/atoms/checkbox';
import { ProgressComponent } from '@components/atoms/progress';
import { INDICATIVE_PRICES_DASHBOARD } from '@routers/constants';
import TitleSection from '@components/molecules/indicative-prices-form/title-section';
import SubmarketSection from '@components/molecules/indicative-prices-form/submarket-section';
import { submarkets } from './helper';

export interface IndicativePricesByEnergyTypeSectionProps {
  title: string;
  description: string;
  energyType: EnergyType;
  progressValue: number;
  step: number;
  isChecked: boolean;
  setIsChecked: (checked: boolean) => void;
  nextStep: () => void;
  prevStep: () => void;
  onFormSuccess: () => void;
  areAllCheckboxesChecked: () => boolean;
  setOpenErrorModal: (open: boolean) => void;
  handleCopyData: (data: Record<string, any>, energyType: string, submarketType: string) => void;
  handlePasteData: (setValue: any, destEnergyType: string, destSubmarketType: string) => void;
  submarketCheckboxStates: Record<SubmarketType, boolean>;
  onSubmarketCheckboxChange: (energyType: EnergyType, submarketType: SubmarketType, checked: boolean) => void;
}

const IndicativePricesByEnergyTypeSection: React.FC<IndicativePricesByEnergyTypeSectionProps> = ({
  title,
  description,
  energyType,
  progressValue,
  step,
  isChecked,
  setIsChecked,
  nextStep,
  prevStep,
  onFormSuccess,
  areAllCheckboxesChecked,
  setOpenErrorModal,
  handleCopyData,
  handlePasteData,
  submarketCheckboxStates,
  onSubmarketCheckboxChange,
}) => {
  const navigate = useNavigate();
  const { createIndicativePrices, loading, success } = useCreateIndicativePrices();
  const { handleSubmit, clearErrors } = useFormContext();
  const { user } = useAuth();
  const { userTrader } = useUserTrader(user.id);

  const handleNavigateToDashboardClick = () => {
    navigate(INDICATIVE_PRICES_DASHBOARD);
  };

  const transformData = (data: Record<string, number>) => {
    const transformed: IndicativePriceInput[] = [];

    Object.entries(data).forEach(([key, value]) => {
      if (value !== null && value >= 0) {
        const [energyType, submarketType, rawYear] = key.split('-');
        transformed.push({
          traderId: userTrader.id,
          year: Number(rawYear),
          price: value,
          energyType: EnergyType[energyType as keyof typeof EnergyType],
          submarketType: SubmarketType[submarketType as keyof typeof SubmarketType],
        });
      }
    });

    return transformed;
  };

  const handleFinalSubmit = (data: any) => {
    if (areAllCheckboxesChecked()) {
      setOpenErrorModal(true);
      return;
    }

    const parsedData = transformData(data);
    createIndicativePrices({
      variables: {
        input: parsedData,
      },
    });
  };

  const handleCheckboxChange = (checked: boolean) => {
    setIsChecked(checked);
    const fieldsToClearErrors: string[] = [];

    const currentYear = new Date().getFullYear();
    const submarketTypes = Object.values(SubmarketType).filter((value) => typeof value === 'string');
    submarketTypes.forEach((submarketType) => {
      for (let i = 0; i < 5; i++) {
        const year = currentYear + i;
        const fieldName = `${energyType}-${submarketType}-${year}`;
        fieldsToClearErrors.push(fieldName);
      }
    });

    clearErrors(fieldsToClearErrors);
  };

  useEffect(() => {
    if (success) {
      onFormSuccess();
    }
  }, [success]);

  return (
    <form className="flex flex-col w-vw" onSubmit={handleSubmit(step === 3 ? handleFinalSubmit : nextStep)}>
      <TitleSection title={title} />
      <div className="flex flex-col gap-10 mb-10">
        {submarkets.map((submarket) => (
          <SubmarketSection
            key={submarket.id}
            title={submarket.name}
            submarketType={submarket.type}
            description={description}
            energyType={energyType}
            isRequired={!isChecked}
            handleCopyData={handleCopyData}
            handlePasteData={handlePasteData}
            isDisabled={submarketCheckboxStates[submarket.type] ?? false}
            onCheckboxChange={(submarketType, checked) => onSubmarketCheckboxChange(energyType, submarketType, checked)}
          />
        ))}
      </div>
      <CheckboxComponent.Root className="mb-10" size="large">
        <CheckboxComponent.Indicator checked={isChecked} onCheckedChange={(checked) => handleCheckboxChange(checked)} />
        <CheckboxComponent.Label className="text-caption-small" content="Opto por não preencher este tipo de energia" />
      </CheckboxComponent.Root>
      <div className="flex flex-col gap-8 justify-between items-center sm:flex-row">
        <div className="flex flex-col gap-2 w-full sm:flex-row sm:gap-4">
          <ButtonComponent
            kind="primary"
            screen="desktop"
            label={step === 3 ? 'Enviar preços' : 'Avançar'}
            size="2"
            type="submit"
            loading={loading}
            className="w-full md:w-auto"
          />
          <ButtonComponent
            kind="secondary"
            screen="desktop"
            label={step === 1 ? ' Cancelar' : 'Voltar'}
            size="2"
            type="button"
            onClick={step !== 1 ? prevStep : handleNavigateToDashboardClick}
            className="w-full md:w-auto"
          />
        </div>
        <div className="w-full sm:w-[26rem]">
          <ProgressComponent.Root>
            <ProgressComponent.Bar size="large" progressValue={progressValue} />
          </ProgressComponent.Root>
        </div>
      </div>
    </form>
  );
};

export default IndicativePricesByEnergyTypeSection;
