import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { CREATE_BID_PATH } from '@routers/constants';
import { ExpiredBids, MCPBidsType, McpProposalType } from '@hooks/get-mcp-proposals/types';
import { EditMcpBidPayload } from '@hooks/edit-mcp-bid/types';
import { useMcpProposalContext } from '@hooks/use-mcp-proposal';

import AccordionComponent from '@components/atoms/accordion';
import NuggetInfoCollection from '@components/atoms/nugget-info';
import ButtonComponent from '@components/atoms/button';
import ModalEditProposal from '@components/molecules/modal-edit-proposal';
import ExpiredBid from '@components/molecules/proposal-bids/expired';
import ValidRanking from '@components/molecules/proposal-bids/valid';
import DetailProposalItem from './detail-proposal';

import {
  tagsInfoList,
  energyProposalList,
  dataProposalList,
  rankingList,
  TagsInfoData,
  ProposalInfo,
  NuggetProposalInfo,
} from './helper';

import style from './style.module.css';
import { checkIfDateIsBeforeCurrentDate } from '@utils/dayjs';

interface ProposalProps {
  proposal: McpProposalType;
  validBids: McpProposalType['mcpBids'];
  expiredBids: McpProposalType['expiredMcpBids'];
  traderId: string;
  isAdmin: boolean;
  userProposals: McpProposalType[];
}

const ProposalItem = ({
  validBids,
  expiredBids,
  proposal,
  traderId,
  isAdmin,
  userProposals,
}: ProposalProps): JSX.Element => {
  const { proposal: updatedProposal } = useMcpProposalContext();
  const currentProposal = updatedProposal && updatedProposal.id === proposal.id ? updatedProposal : proposal;
  const userSentProposal = userProposals.some((proposal) => proposal.id === currentProposal.id);

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [traderBid, setTraderBid] = useState<MCPBidsType | null>(null);
  const [traderBidExpired, setTraderBidExpired] = useState<ExpiredBids | null>(null);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [bidToEdit, setBidToEdit] = useState<EditMcpBidPayload>();

  const navigate = useNavigate();

  useEffect(() => {
    if (validBids) {
      const bid = validBids.find((bid) => bid.trader.id === traderId);
      if (bid) {
        setTraderBid(bid);
      } else {
        setTraderBid(null);
      }
    }

    if (expiredBids) {
      const bid = expiredBids.find((bid) => bid.trader.id === traderId);
      if (bid) {
        setTraderBidExpired(bid);
      } else {
        setTraderBidExpired(null);
      }
    }
  }, [validBids, expiredBids, traderId]);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 767);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const proposaExpired = checkIfDateIsBeforeCurrentDate(currentProposal.deadline, 'YYYY-MM-DD HH:mm:ss');

  const displayAccordionContent = () => {
    const withoutBidsForTraderUser = !traderBid && !traderBidExpired && !isAdmin;
    const hasAnyBidForTrader = traderBid || traderBidExpired;
    const hasExpiredForTrader = expiredBids && traderBidExpired && !traderBid;
    const proposalIsActiveForTraderBid = hasAnyBidForTrader && !proposaExpired;
    const hasFinishedProposalWithBid = hasAnyBidForTrader && currentProposal.status === 'FINISHED';

    if (withoutBidsForTraderUser && !proposaExpired && !userSentProposal) {
      return (
        <ButtonComponent
          kind="primary"
          screen={isSmallScreen ? 'mobile' : 'desktop'}
          size="1"
          disabled={currentProposal.status !== 'ACTIVE'}
          label={'Enviar proposta'}
          className="mt-6 mb-5"
          onClick={() => navigate(`${CREATE_BID_PATH}/${proposal.id}`)}
        />
      );
    }

    if (userSentProposal || isAdmin) {
      return (
        <DetailProposalItem
          proposal={currentProposal}
          displayAdminButtons={isAdmin && currentProposal.status === 'ACTIVE'}
          isAdmin={isAdmin}
        />
      );
    }

    if (proposalIsActiveForTraderBid || hasFinishedProposalWithBid) {
      return (
        <>
          {validBids && (
            <ValidRanking
              ranking={rankingList(validBids, traderId, currentProposal.status, !proposaExpired) || []}
              onClick={(value?: EditMcpBidPayload) => {
                if (value) setBidToEdit({ ...value, proposalDeadline: currentProposal.deadline });
                setEditModal(true);
              }}
            />
          )}
          {hasExpiredForTrader && (
            <ExpiredBid
              bid={traderBidExpired}
              onClick={() => navigate(`${CREATE_BID_PATH}/${proposal.id}`)}
              displayBtn={currentProposal.status !== 'FINISHED'}
            />
          )}
        </>
      );
    }
  };

  return (
    <AccordionComponent
      tagsInfos={tagsInfoList(TagsInfoData(currentProposal, traderId, isAdmin, userSentProposal))}
      energyProposals={energyProposalList(ProposalInfo(currentProposal, userSentProposal))}
    >
      <div className={style.NuggetInfoCollectionProposalItem}>
        <NuggetInfoCollection infos={dataProposalList(NuggetProposalInfo(currentProposal))} />
      </div>
      {displayAccordionContent()}
      {bidToEdit && <ModalEditProposal initialValues={bidToEdit} isOpen={editModal} setOpen={setEditModal} />}
    </AccordionComponent>
  );
};

export default ProposalItem;
