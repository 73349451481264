import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';

import TreeIcon from '@assets/tree-icon.svg';

import ButtonComponent, { ButtonComponentProps } from '@components/atoms/button';
import PriceConditions from '@components/molecules/proposal-form/price-conditions';
import NuggetInfoCollection from '@components/atoms/nugget-info';
import NuggetIconsComponent from '@components/atoms/nugget-info/nugget-icons';

import style from './style.module.css';

interface ContainerProps {
  proposalInfo: any;
  buttons: ButtonComponentProps[];
  loading: boolean;
}

const Container: React.FC<ContainerProps> = ({ proposalInfo, buttons, loading }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 767);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const proposalInfoList = [
    {
      icon: (
        <NuggetIconsComponent
          iconName="ClockIcon"
          title="Icone de Relogio"
          extraClassNames="rounded-small p-2 bg-brand-light-30 text-brand-gray-70"
        />
      ),
      title: { value: 'Limite para o envio de propostas' },
      content: { value: proposalInfo.deadline },
    },
    {
      icon: (
        <NuggetIconsComponent
          iconName="CalendarIcon"
          title="Icone de Calendario"
          extraClassNames="rounded-small p-2 bg-brand-light-30 text-brand-gray-70"
        />
      ),
      title: { value: 'Período do fornecimento' },
      content: { value: proposalInfo.supply },
    },
    {
      icon: (
        <NuggetIconsComponent
          iconPath={TreeIcon}
          title="Icone de Arvore"
          extraClassNames="rounded-small p-2 bg-brand-light-30 text-brand-gray-70"
        />
      ),
      title: { value: 'Tipo de energia' },
      content: { value: proposalInfo.energyType },
    },
    {
      icon: (
        <NuggetIconsComponent
          iconName="GlobeAmericasIcon"
          title="Icone do Planeta"
          extraClassNames="rounded-small p-2 bg-brand-light-30 text-brand-gray-70"
        />
      ),
      title: { value: 'Submercado' },
      content: { value: proposalInfo.submarket },
    },
  ];

  return (
    <>
      {loading ? (
        <Skeleton width="100%" height="4.5rem" className="mb-14" />
      ) : (
        <div className={style.ProposalNuggetInfoCollection}>
          <NuggetInfoCollection infos={proposalInfoList} />
        </div>
      )}

      <PriceConditions proposalEnergyType={proposalInfo.energyType} loading={loading} />

      <div className={style.ProposalButtons}>
        {buttons.map((button, idx) => (
          <ButtonComponent {...button} screen={isSmallScreen ? 'mobile' : 'desktop'} key={`btn-${idx}`} />
        ))}
      </div>
    </>
  );
};

export default Container;
