import { useQuery } from '@apollo/client';
import parseDistinctFilters from './parser';
import { GET_DISTINCT_FILTERS_FOR_INDICATIVE_PRICES } from './query';
import { GetDistinctFiltersData } from './types';

export const useGetDistinctFilters = (isAdmin: boolean) => {
  if (isAdmin) {
    const { data, error, loading, refetch } = useQuery<GetDistinctFiltersData>(
      GET_DISTINCT_FILTERS_FOR_INDICATIVE_PRICES,
      {
        variables: {
          input: {
            getTraders: true,
            getWeeks: true,
            getYears: true,
          },
        },
      },
    );

    return {
      distinctFilters: parseDistinctFilters(data?.getDistinctFilters || { traders: [], weeks: [], years: [] }),
      distinctFiltersRawData: data?.getDistinctFilters || {},
      error,
      loading,
      refetch,
    };
  } else {
    return {
      distinctFilters: { traders: [], weeks: [], years: [] },
      distinctFiltersRawData: {},
      error: null,
      loading: false,
      refetch: () => {},
    };
  }
};
