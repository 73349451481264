import React from 'react';

import ButtonComponent from '@components/atoms/button';
import TagComponent from '@components/atoms/tag';
import { Filters } from '@pages/mcp-proposals-page';

export interface FilterBtnAndTagsProps {
  setOpenFilters: React.Dispatch<React.SetStateAction<boolean>>;
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  setRefetchProposals: React.Dispatch<React.SetStateAction<boolean>>;
  ownership: string | null;
  setOwnership: React.Dispatch<React.SetStateAction<string | null>>;
}

type TagKey =
  | 'ACTIVE'
  | 'FINISHED'
  | 'SALE'
  | 'PURCHASE'
  | 'SOUTHEAST'
  | 'SOUTH'
  | 'NORTH'
  | 'NORTHEAST'
  | 'INCENTIVIZED_100'
  | 'INCENTIVIZED_50'
  | 'CONVENTIONAL'
  | 'MY_HISTORY'
  | 'ALL_PROPOSALS'
  | '100_MWH'
  | '301_MWH'
  | '501_MWH'
  | '701_MWH'
  | '1000_MWH';

const FilterBtnAndTags: React.FC<FilterBtnAndTagsProps> = ({
  filters,
  setOpenFilters,
  setFilters,
  setRefetchProposals,
  ownership,
  setOwnership,
}) => {
  const tagsLabelMapping: Record<TagKey, string> = {
    ACTIVE: 'Em andamento',
    FINISHED: 'Concluído',
    SALE: 'Compra',
    PURCHASE: 'Venda',
    SOUTHEAST: 'SE/CO',
    SOUTH: 'S',
    NORTH: 'N',
    NORTHEAST: 'NE',
    INCENTIVIZED_100: 'I100',
    INCENTIVIZED_50: 'I50',
    CONVENTIONAL: 'Convencional',
    MY_HISTORY: 'Minhas cotações',
    ALL_PROPOSALS: 'Todas as cotações',
    '100_MWH': '100 MWh - 300 MWh',
    '301_MWH': '301 MWh - 500 MWh',
    '501_MWH': '501 MWh - 700 MWh',
    '701_MWH': '701 MWh - 1000 MWh',
    '1000_MWH': 'Mais de 1000 MWh',
  };

  const renderListTagComponent = (list: string[], keyTarget: 'submarket' | 'energyType') => {
    return list.map((tagLabel) => (
      <TagComponent
        key={tagLabel}
        color="primaryLight"
        label={tagsLabelMapping[tagLabel as TagKey]}
        kind="remove"
        size="2"
        icon="XMarkIcon"
        onRemoveHandleAction={() => {
          const filteredList = handleRemoveFromFilter(list, tagLabel);
          setFilters((prev) => ({ ...prev, [keyTarget]: filteredList }));
          setRefetchProposals(true);
        }}
      />
    ));
  };

  const renderVolumeTagsComponent = (volumes: Filters['volume']) => {
    return volumes.map(({ volMin }) => (
      <TagComponent
        key={`${volMin}`}
        color="primaryLight"
        label={tagsLabelMapping[`${volMin}_MWH` as TagKey]}
        kind="remove"
        size="2"
        icon="XMarkIcon"
        onRemoveHandleAction={() => {
          const filteredList = volumes.filter((item) => item.volMin !== volMin);
          setFilters((prev) => ({ ...prev, volume: filteredList }));
          setRefetchProposals(true);
        }}
      />
    ));
  };

  const handleRemoveFromFilter = (list: string[], value: string) => list.filter((item) => item !== value);

  return (
    <div className="flex flex-col-reverse gap-2 md:flex-row md:ml-auto">
      <div className="flex flex-wrap gap-2 self-end md:self-center">
        {ownership?.length !== 0 && (
          <TagComponent
            color="primaryLight"
            label={tagsLabelMapping[ownership === null ? 'ALL_PROPOSALS' : ('MY_HISTORY' as TagKey)]}
            kind="remove"
            size="2"
            icon="XMarkIcon"
            onRemoveHandleAction={() => {
              setOwnership('');
              setRefetchProposals(true);
            }}
          />
        )}
        {filters.status && (
          <TagComponent
            color="primaryLight"
            label={tagsLabelMapping[filters.status as TagKey]}
            kind="remove"
            size="2"
            icon="XMarkIcon"
            onRemoveHandleAction={() => {
              setFilters((prev) => ({ ...prev, status: null }));
              setRefetchProposals(true);
            }}
          />
        )}
        {filters.transactionType && (
          <TagComponent
            color="primaryLight"
            label={tagsLabelMapping[filters.transactionType as TagKey]}
            kind="remove"
            size="2"
            icon="XMarkIcon"
            onRemoveHandleAction={() => {
              setFilters((prev) => ({ ...prev, transactionType: null }));
              setRefetchProposals(true);
            }}
          />
        )}
        {filters.energyType.length > 0 && renderListTagComponent(filters.energyType, 'energyType')}
        {filters.submarket.length > 0 && renderListTagComponent(filters.submarket, 'submarket')}
        {filters.volume.length > 0 && renderVolumeTagsComponent(filters.volume)}
      </div>
      <div className="ml-auto md:ml-0">
        <ButtonComponent
          label="Filtros"
          kind="secondary"
          icon="FunnelIcon"
          iconPosition="left"
          size="2"
          onClick={() => setOpenFilters((prev) => !prev)}
        />
      </div>
    </div>
  );
};

export default FilterBtnAndTags;
