import React from 'react';

import { Status } from '@components/organisms/proposal-item/helper';
import EditButton from './edit-button';

export interface BestPriceProps {
  onClick: () => void;
  status: Status;
}

const BestPrice: React.FC<BestPriceProps> = ({ onClick, status }) =>
  status === 'BEST PRICE EDITION' && <EditButton onClick={onClick} />;

export default BestPrice;
