export enum EnergyTypeEnum {
  CONVENTIONAL = 'Convencional',
  INCENTIVIZED_0 = 'Incentivada 0%',
  INCENTIVIZED_50 = 'Incentivada 50%',
  INCENTIVIZED_80 = 'Incentivada 80%',
  INCENTIVIZED_100 = 'Incentivada 100%',
}

export enum SubmarketEnum {
  NORTH = 'Norte',
  NORTHEAST = 'Nordeste',
  SOUTH = 'Sul',
  SOUTHEAST = 'Sudeste',
}

export enum TransactionTypeEnum {
  SALE = 'venda',
  PURCHASE = 'compra',
}
