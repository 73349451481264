import ButtonComponent from '@components/atoms/button';
import ModalComponent from '@components/atoms/modal';
import { TabsComponent } from '@components/atoms/tabs';
import ToastComponent from '@components/atoms/toast/toast';
import IndicativePricesChart from '@components/molecules/indicative-prices-chart';
import IndicativePricesTable from '@components/molecules/indicative-prices-table';
import NoPriceSubmissionReminder from '@components/molecules/no-price-submission-reminder';
import NoSubmarketAverageMessage from '@components/molecules/no-submarket-average-message';
import ListSkeleton from '@components/molecules/skeleton/list-skeleton';
import EditIndicativePriceForm from '@components/organisms/edit-indicative-price-form';
import useAuth from '@hooks/auth';
import { useUserTrader } from '@hooks/get-user-trader/useUserTrader';
import { ENERGY_TYPE_TITLE_GETTER, SUBMARKET_TYPE_TITLE_GETTER } from '@hooks/indicative-prices/helper';
import { IndicativePriceInput } from '@hooks/indicative-prices/mutations/create/types';
import { useEditIndicativePrices } from '@hooks/indicative-prices/mutations/edit-price/useEditIndicativePrice';
import { useCheckCurrentWeekPriceSubmission } from '@hooks/indicative-prices/queries/check-price-submission/useCheckCurrentWeekPriceSubmission';
import { IndicativePriceParsed } from '@hooks/indicative-prices/queries/get-by-submarket/parser';
import { GetBySubmarketFilter, OrderDirectionType } from '@hooks/indicative-prices/queries/get-by-submarket/types';
import { useGetIndicativePricesBySubmarket } from '@hooks/indicative-prices/queries/get-by-submarket/useGetBySubmarket';
import { useGetAllAveragePrices } from '@hooks/indicative-prices/queries/get_averages/useGetAllAveragePrices';
import { SubmarketType } from '@hooks/indicative-prices/types';
import { SEND_INDICATIVE_PRICES } from '@routers/constants';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { COLORS_GETTER, TABS_TRIGGERS } from './helper';

const IndicativePricesDashboard: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { userTrader } = useUserTrader(user.id);

  const isAdmin = user.roles?.includes('admin');
  const isSeller = user.roles?.includes('seller');
  const isAuthorizedUser = isAdmin || isSeller;

  const { submarketType, setSubmarketType, averagePrices, loading: isGettingAverage } = useGetAllAveragePrices();
  const {
    indicativePricesBySubmarket,
    indicativePricesBySubmarketRawData,
    page,
    setPage,
    pageLimit,
    submarket,
    setSubmarket,
    queryTotal,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
    refetch,
    loading: isGettingPricesBySubmarket,
  } = useGetIndicativePricesBySubmarket(!!isAdmin);

  const {
    editIndicativePrices,
    loading: editLoading,
    success: hasSendIndicativePrices,
  } = useEditIndicativePrices({
    onSuccess: (message: string) => {
      setToastMessage(message);
    },
    onError: (message: string) => {
      setToastMessage(message);
    },
  });
  const {
    setTraderId,
    success: hasCurrentWeekPriceSubmission,
    loading: isCheckLoading,
  } = useCheckCurrentWeekPriceSubmission(!!isAuthorizedUser);

  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [indicativePricesToEditId, setIndicativePricesToEditId] = useState<string>('');
  const [toastMessage, setToastMessage] = useState<string>('');
  const [initialPrice, setInitialPrice] = useState<number>(0);

  const onEditClick = (indicativePriceId: string) => {
    const indicativePrice = indicativePricesBySubmarketRawData.find((item) => item.id === indicativePriceId);
    if (indicativePrice) {
      setIndicativePricesToEditId(indicativePriceId);
      setInitialPrice(indicativePrice.price);
      setOpenEditModal(true);
    }
  };

  const onSubmit = (values: IndicativePriceInput) => {
    setToastMessage('');
    const [indicativePricesToEdit] = indicativePricesBySubmarketRawData.filter(
      (item) => item.id === indicativePricesToEditId,
    );

    if (!indicativePricesToEdit) {
      return setToastMessage('Erro ao enviar os preços.');
    }

    const inputData: IndicativePriceInput = {
      id: indicativePricesToEdit.id,
      traderId: indicativePricesToEdit.trader.id,
      year: Number(indicativePricesToEdit.year),
      energyType: indicativePricesToEdit.energyType,
      submarketType: indicativePricesToEdit.submarketType,
      price: values.price,
    };

    const filter: GetBySubmarketFilter = {
      page,
      pageLimit,
      submarket,
      orderBy,
      orderDirection: validOrderDirection(orderDirection) ? orderDirection : undefined,
    };

    editIndicativePrices(inputData, filter);
  };

  const validOrderDirection = (direction: string): direction is OrderDirectionType => {
    return direction === 'asc' || direction === 'desc';
  };

  useEffect(() => {
    if (submarketType) {
      setSubmarket(submarketType);
    }
  }, [submarketType]);

  useEffect(() => {
    if (hasSendIndicativePrices) {
      setOpenEditModal(false);
    }
  }, [hasSendIndicativePrices]);

  useEffect(() => {
    if (userTrader.id) {
      setTraderId(userTrader.id);
    }
  }, [userTrader]);

  const handleSort = (column: string) => {
    if (orderBy === column) {
      setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(column);
      setOrderDirection('asc');
    }
    refetch && refetch();
  };

  return (
    <section className="flex flex-col gap-6 min-width">
      <div className="block lg:hidden z-[100]">
        <ButtonComponent
          type="button"
          kind="primary"
          icon="PlusIcon"
          iconPosition="left"
          className="fixed z-20 right-[0.9375rem] bottom-[0.9375rem]"
          onClick={() => navigate(SEND_INDICATIVE_PRICES)}
        />
      </div>
      {isGettingAverage || isCheckLoading ? (
        <ListSkeleton />
      ) : (
        <>
          {hasCurrentWeekPriceSubmission ?? isAuthorizedUser ? (
            <TabsComponent.Root
              defaultValue={SubmarketType.SOUTHEAST}
              tabValue={submarketType}
              setTabValue={setSubmarketType}
            >
              <TabsComponent.List tabsTriggers={TABS_TRIGGERS} />
              {TABS_TRIGGERS.map((item) => (
                <TabsComponent.Content key={item.name} triggerName={item.name}>
                  <div className="flex flex-col gap-8 mt-8 mb-8">
                    {averagePrices.length > 0 ? (
                      averagePrices.map((average, idx) => (
                        <IndicativePricesChart
                          key={idx}
                          title={`Preços indicativos de energia ${ENERGY_TYPE_TITLE_GETTER[average.energyType]} - ${SUBMARKET_TYPE_TITLE_GETTER[submarketType]} (R$/MWh)`}
                          subtitle={
                            average.indicativePricesCount
                              ? `${average.indicativePricesCount} Fornecedoras enviaram preço essa semana.`
                              : ''
                          }
                          data={average}
                          colors={COLORS_GETTER[average.energyType]}
                        />
                      ))
                    ) : (
                      <NoSubmarketAverageMessage />
                    )}
                  </div>
                  {isAdmin && averagePrices.length > 0 && (
                    <IndicativePricesTable
                      title={`Tabela de preços indicativos - ${SUBMARKET_TYPE_TITLE_GETTER[submarketType]}`}
                      tableData={indicativePricesBySubmarket}
                      onEditClick={onEditClick}
                      totalRows={queryTotal}
                      rowsByPage={pageLimit}
                      currentPage={page}
                      setCurrentPage={setPage}
                      onSort={handleSort}
                      sortedColumn={orderBy as keyof IndicativePriceParsed}
                      isAscOrder={orderDirection === 'asc'}
                      isLoading={isGettingPricesBySubmarket}
                    />
                  )}
                </TabsComponent.Content>
              ))}
            </TabsComponent.Root>
          ) : (
            <NoPriceSubmissionReminder />
          )}
          <ModalComponent
            className="w-[17rem]"
            title={'Editar valores'}
            isOpen={openEditModal}
            setOpen={setOpenEditModal}
          >
            <EditIndicativePriceForm
              isLoading={editLoading}
              initialPrice={initialPrice}
              onSubmit={onSubmit}
              onCancelEdit={() => setOpenEditModal(false)}
            />
          </ModalComponent>
        </>
      )}
      <ToastComponent type={toastMessage.length > 0} label={toastMessage} />
    </section>
  );
};

export default IndicativePricesDashboard;
