import React from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import parse from 'html-react-parser';
import style from './style.module.css';

type ContentSide = 'top' | 'right' | 'bottom' | 'left';

interface TooltipContentProps {
  content: string;
  side: ContentSide;
}

export const TooltipContent: React.FC<TooltipContentProps> = ({ content, side }) => {
  return (
    <Tooltip.Content className={style.contentContainer} sideOffset={5} side={side}>
      <span className={style.contentText}>{parse(content)}</span>
      <Tooltip.Arrow className={style.contentArrow} />
    </Tooltip.Content>
  );
};
