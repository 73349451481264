import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useFormContext } from 'react-hook-form';

import { Input } from '@components/atoms/form/input';
import FormSection from '../form';
import { InputSelect } from '@components/atoms/form/select';
import { validateHour } from '@components/organisms/create-proposal-form/validation';
import { validateCompleteDate } from '@components/organisms/create-proposal-bid-form/validation';
import {
  paymentOptions,
  tusdPaymentOption,
  KeyEnergy,
  TusdPaymentOptionValue,
} from '@components/molecules/proposal-form/helper';

interface PriceConditionsProps {
  proposalEnergyType: KeyEnergy;
  loading: boolean;
}

const PriceConditions: React.FC<PriceConditionsProps> = ({ proposalEnergyType, loading }) => {
  const [tusdOptions, setTusdOptions] = useState<TusdPaymentOptionValue[]>([]);
  const paymentDayOptions = [...paymentOptions, { value: 'NONE', label: 'Escolher outra data' }];

  const { watch, setValue, clearErrors } = useFormContext();

  const tusdPaymentValue = watch('tusdPayment');
  const paymentValue = watch('paymentDay');

  useEffect(() => {
    if (proposalEnergyType) {
      const proposalValue = tusdPaymentOption[proposalEnergyType];
      const otherValue = tusdPaymentOption.NONE;

      setTusdOptions([proposalValue, otherValue]);
    }
  }, [proposalEnergyType]);

  useEffect(() => {
    if (paymentValue === 'NONE') {
      clearErrors('numericPaymentDay');
    }
  }, [paymentValue]);

  useEffect(() => {
    if (proposalEnergyType) {
      const value = tusdPaymentOption[proposalEnergyType].value;

      if (tusdPaymentValue && value !== tusdPaymentValue) return;

      setValue('tusdPayment', value);
    }
  }, [proposalEnergyType, tusdPaymentValue]);

  if (loading) {
    return (
      <FormSection title="Preço e Condições" description="Dados sobre as condições da proposta e pagamento.">
        <Skeleton height="7rem" className="md:w-[19.5rem] lg:w-[33.3rem] xl:w-[47rem] 2xl:w-[61rem]" />
      </FormSection>
    );
  }

  return (
    <FormSection title="Preço e Condições" description="Dados sobre as condições da proposta e pagamento.">
      <Input.Root>
        <Input.Label
          content="Spread"
          className="font-normal text-paragraph-medium"
          tooltipContent="Lorem ipsum"
          required
        />
        <Input.NumericContent
          fieldName="spread"
          formatProps={{
            placeholder: 'R$0,00',
            prefix: 'R$',
            allowNegative: false,
            fixedDecimalScale: true,
            decimalScale: 2,
            valueIsNumericString: false,
          }}
          rules={{ required: { value: true, message: 'Campo obrigatório' } }}
        />
        <Input.Error fieldName="spread" />
      </Input.Root>

      {paymentValue === 'NONE' ? (
        <Input.Root>
          <Input.Label content="Dia de pagamento" className="font-normal text-paragraph-medium" required />
          <Input.NumericContent
            fieldName="numericPaymentDay"
            formatProps={{
              placeholder: 'dia util',
              suffix: 'º dia útil',
              allowNegative: false,
              fixedDecimalScale: false,
              decimalScale: 0,
              valueIsNumericString: false,
            }}
            rules={{
              required: { value: true, message: 'Campo obrigatório' },
              onChange: (e) => {
                const targetValue = e.target.value;

                if (!targetValue) {
                  setValue('paymentDay', 6);
                }
              },
            }}
          />
          <Input.Error fieldName="numericPaymentDay" />
        </Input.Root>
      ) : (
        <InputSelect.Root>
          <InputSelect.Label content="Dia de pagamento" className="font-normal text-paragraph-medium" required />
          <InputSelect.Input
            options={paymentDayOptions}
            fieldName="paymentDay"
            rules={{
              required: { value: true, message: 'Campo obrigatório' },
            }}
          />
          <Input.Error fieldName="paymentDay" />
        </InputSelect.Root>
      )}

      {tusdPaymentValue === 'NONE' ? (
        <Input.Root>
          <Input.Label content="Valor de Ressarcimento TUSD" className="font-normal text-paragraph-medium" required />
          <Input.NumericContent
            fieldName="numericTusd"
            formatProps={{
              placeholder: 'R$0,00',
              prefix: 'R$',
              allowNegative: false,
              fixedDecimalScale: true,
              decimalScale: 2,
              valueIsNumericString: false,
            }}
            rules={{
              required: { value: true, message: 'Campo obrigatório' },
              onChange: (e) => {
                const value = e.target.value;
                const proposalValue = tusdPaymentOption[proposalEnergyType].value;

                if (!value) {
                  setValue('tusdPayment', proposalValue);
                }
              },
            }}
          />
          <Input.Error fieldName="numericTusd" />
        </Input.Root>
      ) : (
        <Input.Root>
          <InputSelect.Label
            content="Valor de Ressarcimento TUSD"
            className="font-normal text-paragraph-medium"
            required
          />
          <InputSelect.Input
            options={tusdOptions}
            fieldName="tusdPayment"
            rules={{ required: { value: true, message: 'Campo obrigatório' } }}
          />
          <Input.Error fieldName="tusdPayment" />
        </Input.Root>
      )}

      <Input.Root>
        <Input.Label content="Validade da proposta" className="font-normal text-paragraph-medium" required />
        <Input.NumericMask
          fieldName="deadline"
          formatProps={{
            placeholder: 'DD/MM/AAAA - HH:MM',
            format: '##/##/#### - ##:##',
            mask: '_',
            valueIsNumericString: true,
          }}
          rules={{
            required: { value: true, message: 'Campo obrigatório' },
            validate: (value) => {
              const hourValidation = validateHour(value);
              const dateValidation = validateCompleteDate(value);

              if (typeof hourValidation !== 'boolean') return hourValidation;

              if (typeof dateValidation !== 'boolean') return dateValidation;

              return true;
            },
          }}
        />
        <Input.Error fieldName="deadline" />
      </Input.Root>
    </FormSection>
  );
};

export default PriceConditions;
