import { EnergyType } from '@hooks/indicative-prices/types';

export const ENERGY_OPTIONS: { label: string; value: EnergyType }[] = [
  {
    label: 'Convencional',
    value: EnergyType.CONVENTIONAL,
  },
  {
    label: 'I50',
    value: EnergyType.INCENTIVIZED_50,
  },
  {
    label: 'I100',
    value: EnergyType.INCENTIVIZED_100,
  },
];
