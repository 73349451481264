import React from 'react';
import Text from '@components/atoms/text';
import ButtonComponent from '@components/atoms/button';

interface SubmarketFormContainerProps {
  title: string;
  description: string;
  children: React.ReactNode;
  onCopy: () => void;
  onPaste: () => void;
}

const SubmarketFormContainer: React.FC<SubmarketFormContainerProps> = ({
  title,
  description,
  children,
  onCopy,
  onPaste,
}) => {
  return (
    <div className="flex flex-col gap-4 w-full lg:flex-row lg:gap-20">
      <div className="flex flex-col gap-1 mb-4 lg:min-w-[264px] lg:max-w-[264px]">
        <Text weight="font-bold" size="text-paragraph-large md:text-heading-xSmall" color="text-brand-gray-70">
          {title}
        </Text>
        <Text
          className="max-w-[264px]"
          weight="font-light md:font-normal"
          size="text-caption-medium sm:text-paragraph-medium"
          color="text-brand-gray-40"
        >
          {description}
        </Text>
        <div className="flex flex-col gap-2 w-full sm:flex-row sm:gap-1">
          <ButtonComponent
            kind="secondary"
            screen="desktop"
            label="Copiar dados"
            size="2"
            type="button"
            onClick={onCopy}
            className="w-full md:w-auto"
          />
          <ButtonComponent
            kind="secondary"
            screen="desktop"
            label="Colar dados"
            size="2"
            type="button"
            onClick={onPaste}
            className="w-full md:w-auto"
          />
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default SubmarketFormContainer;
