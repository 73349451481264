import React from 'react';
import { twMerge } from 'tailwind-merge';

import HeroIcon, { IconName } from '../hero-icon';
import Text from '../text';

interface IconTextAccordionProps {
  icon: IconName;
  color: 'green' | 'blue';
  text: string;
}

const IconWithText: React.FC<IconTextAccordionProps> = ({ icon, color, text }) => {
  return (
    <div className="flex items-end text-brand-secondary-0">
      <HeroIcon
        icon={icon}
        extraClassNames={twMerge(
          'w-4 h-4',
          color === 'green'
            ? 'stroke-brand-primary-0 fill-brand-primary-0'
            : 'stroke-brand-secondary-0 fill-brand-secondary-0',
        )}
      />
      <Text
        size="text-caption-medium"
        weight="font-bold"
        color={color === 'green' ? 'text-brand-primary-0' : 'text-brand-secondary-0'}
      >
        {text}
      </Text>
    </div>
  );
};

export default IconWithText;
