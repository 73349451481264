import { WinnerInfo } from './index';

export const dataInfoList = (bidInfo: WinnerInfo) => {
    return [
        {
            title: { value: 'Preço' },
            content: { value: bidInfo.price },
        },
    ];
};
