import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import LoadingPage from '@components/templates/loading-page';

interface PrivateRouteProps {
  children: JSX.Element;
}
const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  return children;
};

export default withAuthenticationRequired(PrivateRoute, {
  onRedirecting: () => <LoadingPage />,
});
