import React from 'react';

import style from './style.module.css';
import { CheckboxProps } from './root';
import { twMerge } from 'tailwind-merge';

export interface CheckboxLabelProps extends CheckboxProps {
  required?: boolean;
}

export const CheckboxLabel: React.FC<CheckboxLabelProps> = ({
  id,
  content,
  required,
  className,
  disabled,
  size = 'small',
}) => {
  const labelSizeClassName = `text-paragraph-${size}`;
  const disabledClassName = disabled ? 'text-brand-gray-20' : 'text-brand-gray-80';
  const dynamicClassName = twMerge(labelSizeClassName, disabledClassName, className);
  return (
    <div className={style.labelContainer}>
      <label className={dynamicClassName} htmlFor={id}>
        {content}
      </label>
      {required && <span className="text-palette-orange-30">*</span>}
    </div>
  );
};
