import { $dayjs, checkIfDateIsBeforeCurrentDate, checkIfDateisValid } from '@utils/dayjs';

export const validateMonthYear = (monthYear: string) => {
  const month = monthYear.substring(0, 2);
  const year = monthYear.substring(2);
  const date = $dayjs(`${year}-${month}-01`);

  if (parseInt(month) < 1 || parseInt(month) > 12) {
    return 'Mês inválido';
  }

  if (date.isAfter($dayjs(), 'year') || date.isBefore($dayjs(), 'year')) {
    return 'Ano inválido';
  }

  return true;
};

export const validateCompleteDate = (dateStr: string) => {
  const format = 'DDMMYYYYHHmm';

  const year = parseInt(dateStr.substring(4, 8), 10);
  const currentYear = $dayjs().year();
  const isBeforeCurrentDate = checkIfDateIsBeforeCurrentDate(dateStr, format);

  const isValidDate = checkIfDateisValid(dateStr, format);

  if (dateStr.length !== 12) {
    return 'Data incompleta';
  }

  if (isBeforeCurrentDate) {
    return 'Data menor que a data atual';
  }

  if (!isValidDate) {
    return 'Data inválida';
  }

  if (year !== currentYear) {
    return 'Ano inválido';
  }

  return true;
};

export const validateHour = (dateStr: string) => {
  const hour = parseInt(dateStr.substring(8, 10), 10);
  const minute = parseInt(dateStr.substring(10, 12), 10);

  if (hour < 0 || hour > 23) {
    return 'Hora inválida';
  }

  if (minute < 0 || minute > 59) {
    return 'Minuto inválido';
  }

  return true;
};
