import React from 'react';

import styles from './style.module.css';

interface LayoutHeaderProps {
  children: React.ReactNode;
}

const LayoutHeader: React.FC<LayoutHeaderProps> = ({ children }) => {
  return (
    <div className={styles.layoutHeader}>
      <div className={styles.layoutHeaderContainer}>{children}</div>
    </div>
  );
};

export default LayoutHeader;
