import React from 'react';
import { twMerge } from 'tailwind-merge';
import * as Dialog from '@radix-ui/react-dialog';
import HeroIcon, { IconName } from '@components/atoms/hero-icon';
import ButtonComponent, { ButtonComponentProps } from '@components/atoms/button';
import style from './style.module.css';

export type IconColor = 'green' | 'red' | 'yellow';

interface ModalComponentProps {
  title: string;
  isOpen: boolean;
  setOpen: (key: boolean) => void;
  className?: string;
  icon?: IconName;
  iconColor?: IconColor;
  modalIcon?: boolean;
  description?: string;
  buttonsProps?: ButtonComponentProps[];
  children?: React.ReactNode;
}

const ModalComponent: React.FC<ModalComponentProps> = ({
  icon,
  iconColor = 'green',
  modalIcon,
  title,
  description,
  children,
  setOpen,
  isOpen,
  buttonsProps,
  className,
  ...props
}) => {
  const modalIconClass = modalIcon ? style.DialogIconStatus : style.hidden;
  const modalTitleClass = modalIcon ? style.DialogIconTitleStatus : style.DialogTitleStatus;

  const modalContent = modalIcon ? style.DialogContentWithIcon : style.DialogContentNoIcon;

  const COLOR_ICON_NAME: Record<IconColor, string> = {
    green: style.DialogIconStatusAppearance_Green,
    yellow: style.DialogIconStatusAppearance_Yellow,
    red: style.DialogIconStatusAppearance_Red,
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className={style.DialogOverlay} onClick={() => setOpen(false)} />
        <Dialog.Content
          className={twMerge(style.DialogContent, modalContent, className)}
          onOpenAutoFocus={(event) => event.preventDefault()}
        >
          <div className={modalIconClass}>
            <HeroIcon
              icon={icon as IconName}
              extraClassNames={twMerge('place-self-center w-[60px] h-[60px]', COLOR_ICON_NAME[iconColor])}
            />
          </div>
          <Dialog.Title className={modalTitleClass}>{title}</Dialog.Title>
          {description ? (
            <Dialog.Description className={twMerge(style.DialogDescription, modalIcon ? 'text-center' : '')}>
              {description}
            </Dialog.Description>
          ) : null}
          <div className={twMerge(style.DialogContentAction, !children && style.hidden)} {...props}>
            {children}
          </div>
          {buttonsProps && (
            <div className={style.ButtonsContainer}>
              {buttonsProps.length > 0 &&
                buttonsProps.map((btnProps) => (
                  <ButtonComponent
                    className="flex justify-center items-center w-full"
                    key={btnProps.label}
                    {...btnProps}
                  />
                ))}
            </div>
          )}
          <Dialog.Close asChild>
            <button className={style.IconButton} aria-label="Close" onClick={() => setOpen(false)}>
              <HeroIcon icon="XMarkIcon" extraClassNames="place-self-center w-[10px] h-[10px]" />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ModalComponent;
