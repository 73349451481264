import { useMutation } from '@apollo/client';

import { CREATE_PROPOSAL } from './mutation';

export const useCreateProposal = () => {
  const [createProposalFn, { data, error, loading }] = useMutation(CREATE_PROPOSAL);

  return {
    createProposalFn,
    data,
    error,
    loading,
  };
};
