import ButtonComponent from '@components/atoms/button';
import Drawer from '@components/atoms/drawer';
import Text from '@components/atoms/text';
import FilterDatePickerSection from '@components/molecules/filte-datepicker-section';
import FilterMultiSelectSection from '@components/molecules/filter-multi-select-section';
import ListSkeleton from '@components/molecules/skeleton/list-skeleton';
import { ENERGY_OPTIONS } from '@hooks/indicative-prices/queries/get-distinct-filters/helper';
import { useGetDistinctFilters } from '@hooks/indicative-prices/queries/get-distinct-filters/useDistincFilters';
import { useIndicativePricesFiltersContext } from '@src/context/indicative-prices-filter';
import { formatDate, formatDateString } from '@utils/dayjs';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

interface IndicativePriceFilterDrawerProps {
  actions: {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  };
  onClickCancelAction: () => void;
  onClickAction: () => void;
  loading: boolean;
}

const InidicativePricesFilterDrawer: React.FC<IndicativePriceFilterDrawerProps> = ({
  actions,
  onClickCancelAction,
  onClickAction,
  loading,
}) => {
  const methods = useForm();

  const { priceFilters, setPriceFilters, setLabelMapping } = useIndicativePricesFiltersContext();
  const { distinctFilters, loading: filtersLoading } = useGetDistinctFilters(true);

  const [tempFilters, setTempFilters] = useState(priceFilters);

  const supplierOptions = [...distinctFilters.traders];
  const weekOptions = [...distinctFilters.weeks];
  const yearOptions = [...distinctFilters.years];

  useEffect(() => {
    setTempFilters(priceFilters);
  }, [priceFilters]);

  useEffect(() => {
    setLabelMapping({
      years: yearOptions.reduce((acc, curr) => ({ ...acc, [curr.value]: curr.label }), {}),
      traders: supplierOptions.reduce((acc, curr) => ({ ...acc, [curr.value]: curr.label }), {}),
      weeks: weekOptions.reduce((acc, curr) => ({ ...acc, [curr.value]: curr.label }), {}),
      energyTypes: ENERGY_OPTIONS.reduce((acc, curr) => ({ ...acc, [curr.value]: curr.label }), {}),
      startDate: priceFilters.startDate ? formatDateString(priceFilters.startDate) : null,
      endDate: priceFilters.endDate ? formatDateString(priceFilters.endDate) : null,
    });
  }, [setLabelMapping, priceFilters]);

  const handleFilterSubmit = () => {
    setPriceFilters(tempFilters);
    onClickAction();
  };

  return (
    <Drawer
      openDrawer={actions.isOpen}
      setOpenDrawer={(isOpen) => {
        if (isOpen) {
          actions.setIsOpen(true);
        }
      }}
    >
      <div className="border-b-extraThin border-brand-gray-20">
        <Text size="text-heading-medium " weight="font-bold">
          Filtros
        </Text>
      </div>

      <FormProvider {...methods}>
        {filtersLoading ? (
          <ListSkeleton height="2rem" />
        ) : (
          <div className="grid grid-cols-1 gap-4 mt-6 md:grid-cols-2">
            <FilterMultiSelectSection
              fieldName="year"
              options={yearOptions}
              title="Ano"
              placeholder="Selecione os anos"
              selectedOptions={tempFilters.years || []}
              onChange={(selected) => setTempFilters((prev) => ({ ...prev, years: selected }))}
            />

            <FilterMultiSelectSection
              title="Fornecedor"
              fieldName="supplier"
              options={supplierOptions}
              placeholder="Selecione o fornecedor"
              selectedOptions={tempFilters.traders || []}
              onChange={(selected) => setTempFilters((prev) => ({ ...prev, traders: selected }))}
            />

            <FilterMultiSelectSection
              title="Semana"
              fieldName="week"
              options={weekOptions}
              placeholder="Selecione a semana"
              selectedOptions={tempFilters.weeks || []}
              onChange={(selected) => setTempFilters((prev) => ({ ...prev, weeks: selected }))}
            />

            <FilterMultiSelectSection
              title="Tipo de Energia"
              fieldName="energyType"
              options={ENERGY_OPTIONS}
              placeholder="Selecione o tipo de energia"
              selectedOptions={tempFilters.energyTypes || []}
              onChange={(selected) => setTempFilters((prev) => ({ ...prev, energyTypes: selected }))}
            />

            <FilterDatePickerSection
              title="Data de Envio Inicial"
              fieldName="startDate"
              placeholder="Escolha uma data"
              selectedDate={tempFilters.startDate ? new Date(tempFilters.startDate) : undefined}
              onChange={(selected) =>
                setTempFilters((prev) => ({
                  ...prev,
                  startDate: selected ? formatDate(selected, 'YYYY-MM-DD') : null,
                }))
              }
            />

            <FilterDatePickerSection
              title="Data de Envio Final"
              fieldName="endDate"
              placeholder="Escolha uma data"
              selectedDate={tempFilters.endDate ? new Date(tempFilters.endDate) : undefined}
              onChange={(selected) =>
                setTempFilters((prev) => ({
                  ...prev,
                  endDate: selected ? formatDate(selected, 'YYYY-MM-DD') : null,
                }))
              }
            />
          </div>
        )}
      </FormProvider>

      <div className="flex flex-col gap-2 mt-8 md:flex-row">
        <ButtonComponent kind="primary" label="Filtrar" size="2" onClick={handleFilterSubmit} loading={loading} />
        <ButtonComponent kind="secondary" label="Cancelar" size="2" onClick={onClickCancelAction} />
      </div>
    </Drawer>
  );
};

export default InidicativePricesFilterDrawer;
