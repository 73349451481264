import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Input } from '@components/atoms/form/input';
import FormSection from '../form';

const CommissionSection: React.FC<{ loading: boolean }> = ({ loading }) => {
  const commissionFormat = {
    placeholder: 'R$/MWh',
    suffix: '/MWh',
    prefix: 'R$ ',
    allowNegative: false,
    fixedDecimalScale: true,
    decimalScale: 2,
  };

  return (
    <FormSection title="Comissão da Clarke" description="Valor adicional que deve ser incluso no seu preço de compra.">
      {loading ? (
        <Skeleton height="7rem" className="md:w-[19.5rem] lg:w-[33.3rem] xl:w-[47rem] 2xl:w-[61rem]" />
      ) : (
        <Input.Root>
          <Input.Label content="Valor de Comissão" className="font-normal text-paragraph-medium" required />
          <Input.NumericContent
            fieldName="commission"
            formatProps={commissionFormat}
            rules={{ required: { value: true, message: 'Campo obrigatório' } }}
          />
          <Input.Error fieldName="commission" />
        </Input.Root>
      )}
    </FormSection>
  );
};

export default CommissionSection;
