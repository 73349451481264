import React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { PatternFormat, PatternFormatProps } from 'react-number-format';

import style from './style.module.css';
import { twMerge } from 'tailwind-merge';
import HeroIcon from '@components/atoms/hero-icon';

export interface InputNumericContentProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  fieldName: string;
  inputRef?: ((el: HTMLInputElement) => void) | React.Ref<any> | undefined;
  rules?: RegisterOptions;
  formatProps: Pick<
    PatternFormatProps,
    'mask' | 'format' | 'valueIsNumericString' | 'placeholder' | 'prefix' | 'allowEmptyFormatting'
  >;
}

export const InputNumericMask: React.FC<InputNumericContentProps> = ({
  fieldName,
  inputRef,
  rules,
  formatProps: { placeholder, prefix, allowEmptyFormatting, mask, format, valueIsNumericString },
  ...attrs
}) => {
  const { id, className, disabled, required } = attrs;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const fieldError = errors[fieldName];

  const focusClass = fieldError ? style.contentFocusOnError : style.contentFocus;

  const dynamicClasses = [focusClass, className].join(' ');

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={rules}
      render={({ field: { name, onChange, onBlur, value } }) => (
        <div className={style.inputContainer}>
          <PatternFormat
            id={id}
            name={name}
            disabled={disabled}
            className={twMerge(style.content, dynamicClasses)}
            format={format}
            valueIsNumericString={valueIsNumericString}
            allowEmptyFormatting={allowEmptyFormatting}
            prefix={prefix}
            mask={mask}
            value={value === undefined ? '' : value}
            placeholder={placeholder}
            required={required}
            onBlur={onBlur}
            onValueChange={(v) => {
              if (valueIsNumericString) {
                return onChange(v.value);
              }

              const value = v.floatValue === undefined ? null : v.floatValue;
              onChange(value);
            }}
            getInputRef={inputRef}
          />
          {fieldError && (
            <HeroIcon
              className={twMerge(style.iconOnError, style.icon, style.iconPositionInsideContent)}
              icon={'ExclamationCircleIcon'}
            />
          )}
        </div>
      )}
    />
  );
};
