import React from 'react';
import * as Toast from '@radix-ui/react-toast';
import { twMerge } from 'tailwind-merge';

import ButtonComponent from '../button';
import style from './style.module.css';

type ToastComponentProps = {
  label: string;
  type: boolean;
  className?: string;
  swipeDirection?: 'left' | 'right';
};

const ToastComponent: React.FC<ToastComponentProps> = ({ label, type, className, swipeDirection = 'right' }) => {
  const [open, setOpen] = React.useState(type);
  const timerRef = React.useRef(0);

  React.useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  React.useEffect(() => {
    setOpen(type);
  }, [type]);

  React.useEffect(() => {
    if (open) {
      timerRef.current = window.setTimeout(() => {
        setOpen(false);
      }, 5000);
    }
  }, [open]);

  return (
    <Toast.Provider swipeDirection={swipeDirection}>
      <Toast.Root className={twMerge(style.ToastRoot, className)} open={open} onOpenChange={setOpen} role="alert">
        <Toast.Title className={style.ToastTitle}>{label}</Toast.Title>
        <Toast.Action className={style.ToastAction} asChild altText="Fechar">
          <ButtonComponent
            kind="iconQuaternary"
            icon="XMarkIcon"
            size="1"
            iconPosition="left"
            screen="desktop"
            className="p-2"
            aria-label="Fechar"
          />
        </Toast.Action>
      </Toast.Root>
      <Toast.Viewport
        className={twMerge(style.ToastViewport, swipeDirection === 'right' ? style.swipeRight : style.swipeLeft)}
      />
    </Toast.Provider>
  );
};

export default ToastComponent;
