import React, { useState, useEffect } from 'react';
import { ChevronDownIcon } from '@radix-ui/themes';
import * as Accordion from '@radix-ui/react-accordion';
import { twMerge } from 'tailwind-merge';

import HeroIcon from '@src/components/atoms/hero-icon';
import Text from '@components/atoms/text';
import TagComponent, { TagColor, TagComponentProps } from '@components/atoms/tag';
import style from './style.module.css';
import IconWithText from './icon-text';

interface AccordionComponentProps {
  children: React.ReactNode;
  tagsInfos: TagComponentProps[];
  energyProposals: any[];
  targetProposalOpen?: string | null;
}

interface AccordionTriggerProps {
  children: React.ReactNode;
  className?: string;
  isOpen: boolean;
  onClick: () => void;
  value: string;
}

const AccordionTrigger = React.forwardRef<HTMLButtonElement, AccordionTriggerProps>(
  ({ children, className, isOpen, onClick, value, ...props }, forwardedRef) => {
    return (
      <Accordion.Header className={style.AccordionHeader}>
        <Accordion.Trigger
          className={twMerge(style.AccordionTrigger, className)}
          {...props}
          ref={forwardedRef}
          role="button"
          aria-expanded={isOpen}
          aria-controls={`content-${value}`}
          onClick={onClick}
        >
          {children}
          <div className={style.AccordionHeaderContentRight}>
            <div className={style.AccordionIcon}>
              <ChevronDownIcon className={style.AccordionChevron} aria-hidden />
            </div>
          </div>

          <Text className="sr-only">{isOpen ? 'Fechar' : 'Abrir'}</Text>
        </Accordion.Trigger>
      </Accordion.Header>
    );
  },
);

AccordionTrigger.displayName = style.AccordionTrigger;

interface AccordionContentProps {
  children: React.ReactNode;
  className?: string;
  id: string;
}

const AccordionContent = React.forwardRef<HTMLDivElement, AccordionContentProps>(
  ({ children, className, id, ...props }, forwardedRef) => (
    <Accordion.Content
      className={twMerge(style.AccordionContent, className)}
      {...props}
      ref={forwardedRef}
      id={`content-${id}`}
      role="region"
      aria-labelledby={`trigger-${id}`}
    >
      <div className={twMerge(style.AccordionContentInside, 'justify-between')}>{children}</div>
    </Accordion.Content>
  ),
);

AccordionContent.displayName = style.AccordionContent;

const AccordionComponent: React.FC<AccordionComponentProps> = ({
  children,
  tagsInfos,
  energyProposals,
  targetProposalOpen = null,
}) => {
  const [openPanel, setOpenPanel] = useState<string | null>(targetProposalOpen);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 767);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const handleTriggerClick = (value: string) => {
    setOpenPanel(openPanel === value ? null : value);
  };

  return (
    <Accordion.Root
      className={style.AccordionRoot}
      type="single"
      collapsible
      defaultValue={targetProposalOpen ?? undefined}
    >
      {energyProposals.map((proposal, index) => (
        <Accordion.Item className={style.AccordionItem} value={proposal.id} key={proposal.id}>
          <AccordionTrigger
            key={index}
            isOpen={openPanel === proposal.id}
            onClick={() => handleTriggerClick(proposal.id)}
            value={proposal.id}
            aria-expanded={openPanel === proposal.id}
            aria-controls={`content-${proposal.id}`}
          >
            <div className={style.AccordionHeaderContentLeft}>
              <div className={style.AccordionHeaderContentLeftIconText}>
                <div
                  className={twMerge(
                    'rounded-small p-2',
                    proposal.isSellingStatus ? 'bg-brand-primary-10' : 'bg-brand-secondary-10',
                  )}
                >
                  <HeroIcon
                    icon={proposal.isSellingStatus ? 'MegaphoneIcon' : 'ShoppingCartIcon'}
                    extraClassNames={twMerge(
                      'w-6 h-6',
                      proposal.isSellingStatus ? 'stroke-brand-primary-0' : 'stroke-brand-secondary-30',
                    )}
                  />
                </div>

                <div className={style.AccordionHeaderContent}>
                  <Text size="text-paragraph-medium" weight="font-bold">
                    {proposal.isSellingStatus ? 'Venda' : 'Compra'} de Energia: {proposal.megaWattPerHour} MWh
                  </Text>
                  {proposal.companyName ? (
                    <Text size="text-caption-medium">
                      {proposal.companyName} está {proposal.isSellingStatus ? 'vendendo' : 'comprando'} energia
                    </Text>
                  ) : (
                    <IconWithText
                      icon="UserIcon"
                      color={proposal.isSellingStatus ? 'green' : 'blue'}
                      text="Você abriu essa cotação"
                    />
                  )}
                </div>
              </div>

              <div className={isSmallScreen ? style.AccordionHeaderTagMobile : style.AccordionHeaderTag}>
                {tagsInfos.map(({ color, icon, kind, label = '', headerLabel = '' }) => (
                  <TagComponent
                    key={`tag-${label}`}
                    kind={kind}
                    label={isSmallScreen && color === 'yellowLight' ? headerLabel + label : label}
                    headerLabel={headerLabel}
                    size={isSmallScreen ? '1' : '2'}
                    color={color as TagColor}
                    icon={icon}
                    className="min-w-[10rem]"
                  />
                ))}
              </div>
            </div>
          </AccordionTrigger>
          <AccordionContent id="energyProposal">{children}</AccordionContent>
        </Accordion.Item>
      ))}
    </Accordion.Root>
  );
};

export default AccordionComponent;
