import { useMutation } from '@apollo/client';

import { DELETE_PROPOSAL } from './mutation';

export const useMcpDeleteProposal = () => {
  const [deleteProposalFn, { data, error, loading }] = useMutation(DELETE_PROPOSAL);

  return {
    deleteProposalFn,
    data,
    error,
    loading,
  };
};
