interface ErrorOptions {
  cause?: Error;
}

/**
 * Use this class when there is an uncaught server error.
 * */
export class ServerFaultError extends Error {
  cause: Error | undefined;
  constructor(message: string, options?: ErrorOptions) {
    super(message);
    Object.setPrototypeOf(this, ServerFaultError.prototype);
    if (options && options.cause) {
      this.cause = options.cause;
    }
  }
}

/**
 * Use this class when there is an server side (domain) validation error occurs.
 * */
export class ServerValidationError extends Error {
  cause: Error | undefined;
  constructor(message: string, options?: ErrorOptions) {
    super(message);
    Object.setPrototypeOf(this, ServerValidationError.prototype);
    if (options && options.cause) {
      this.cause = options.cause;
    }
  }
}
