import Skeleton from 'react-loading-skeleton';

const MainContentDrawerSkeleton = () => {
  return (
    <>
      <Skeleton />
      <div className="mt-6">
        <Skeleton className="h-screen" />
      </div>
    </>
  );
};

export default MainContentDrawerSkeleton;
