import React from 'react';
import ProfileImage from '@assets/profile-image.svg';

const ProfileLogo: React.FC = () => {
  return (
    <div className="items-start ">
      <img
        className="w-10 h-10 mr-4"
        src={ProfileImage}
        alt="Foto de perfil do Usuário"
        role="img"
        aria-label="Foto de perfil do Usuário"
      />
    </div>
  );
};

export default ProfileLogo;
