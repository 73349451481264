import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';

import { VIEW_PROPOSAL_LIST_PATH } from '@routers/constants';

import { useEditProposal } from '@hooks/edit-mcp-proposal/useEditProposal';
import { EditProposalPayload } from '@hooks/edit-mcp-proposal/types';
import parsePayload from '@hooks/create-mcp-proposal/parser';
import { useCreateCommercialGroup } from '@hooks/create-commerical-group/useCommercialGorup';
import { CommercialGroupPayload } from '@hooks/create-commerical-group/types';

import Container from '@components/molecules/proposal-form';
import ToastComponent from '@components/atoms/toast/toast';

interface ProposalFormProps {
  isAdmin: boolean;
  setHeaderTitle: React.Dispatch<React.SetStateAction<'PURCHASE' | 'SALE'>>;
  initialValues: EditProposalPayload;
  loading: boolean;
}

const EditProposalForm = ({ isAdmin, setHeaderTitle, initialValues, loading }: ProposalFormProps) => {
  const [toastMessage, setToastMessage] = useState<string>('');
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const navigate = useNavigate();

  const methods = useForm<EditProposalPayload>({
    defaultValues: initialValues,
    mode: 'all',
  });

  const contractType = methods.watch('contractType');

  useEffect(() => {
    if (contractType) setHeaderTitle(contractType as 'SALE' | 'PURCHASE');
  }, [contractType]);

  const { createGroupFn, loading: loadingCreateGroup } = useCreateCommercialGroup();
  const { editProposalFn, loading: loadingEditProposal } = useEditProposal();

  const onSubmit = async ({ id, traderId, ...values }: EditProposalPayload) => {
    setToastMessage('');
    try {
      const groupPayload: CommercialGroupPayload = {
        name: values.name,
        docType: 'CNPJ',
        legalName: '',
        docNumber: values.docNumber,
      };
      const { data } = await createGroupFn({
        variables: { input: { ...groupPayload } },
      });
      const groupId = data?.createCommercialGroup?.id;
      if (groupId && traderId) {
        const parsedValues = parsePayload(values, groupId, traderId);
        await editProposalFn({ variables: { input: { id, ...parsedValues } } });

        setToastMessage('Cotação editada com sucesso!');
        setTimeout(() => navigate(VIEW_PROPOSAL_LIST_PATH), 1000);
      }
    } catch (e) {
      setToastMessage('Erro ao editar Cotação.');
      throw new Error(`Mutation error: ${e}`);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Container
          loading={loading}
          adminMode={isAdmin}
          formType={contractType as 'SALE' | 'PURCHASE'}
          buttons={[
            {
              label: 'Editar Cotação',
              kind: 'primary',
              type: 'submit',
              size: '1',
              loading: loadingCreateGroup || loadingEditProposal,
            },
            {
              label: 'Cancelar',
              kind: 'secondary',
              size: '1',
              onClick: () => navigate(VIEW_PROPOSAL_LIST_PATH),
            },
          ]}
        />
      </form>
      <ToastComponent label={toastMessage} type={toastMessage.length > 0 ? true : false} />
    </FormProvider>
  );
};

export default EditProposalForm;
