import { CalendarButton } from '@components/atoms/calendar/calendar-button';
import { Popover, PopoverContent, PopoverTrigger } from '@components/atoms/popover';
import Text from '@components/atoms/text';
import { cn } from '@utils/cn';
import { formatDate } from '@utils/dayjs';
import { CalendarIcon, X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { DayPicker, getDefaultClassNames } from 'react-day-picker';
import { ptBR } from 'react-day-picker/locale';
import 'react-day-picker/style.css';
import { twMerge } from 'tailwind-merge';

interface FilterDatePickerSectionProps {
  title: string;
  fieldName: string;
  placeholder: string;
  selectedDate: Date | undefined;
  onChange: (date: Date | undefined) => void;
  rules?: object;
  className?: string;
}

const FilterDatePickerSection: React.FC<FilterDatePickerSectionProps> = ({
  title,
  placeholder,
  selectedDate,
  onChange,
  className = '',
}) => {
  const [selected, setSelected] = useState<Date | undefined>(selectedDate);
  const defaultClassNames = getDefaultClassNames();

  useEffect(() => {
    if (selectedDate) {
      const adjustedDate = new Date(selectedDate);
      adjustedDate.setHours(adjustedDate.getHours() + 3);
      setSelected(adjustedDate);
    }
  }, [selectedDate]);

  const displayText = selected ? formatDate(selected, 'DD/MM/YYYY') : placeholder;

  const handleSelect = (date: Date | undefined) => {
    setSelected(date);
    onChange(date);
  };

  const handleClear = () => {
    setSelected(undefined);
    onChange(undefined);
  };

  return (
    <div className="mt-6">
      <div className="pt-4">
        <Text size="text-paragraph-medium" weight="font-bold">
          {title}
        </Text>
      </div>

      <div className={twMerge('mt-2', className)}>
        <Popover>
          <PopoverTrigger asChild>
            <CalendarButton
              variant="select"
              size="sm"
              className={twMerge(
                'w-full justify-start text-left font-normal h-10 flex items-center space-x-2',
                !selectedDate && 'text-muted-foreground',
              )}
            >
              <CalendarIcon className="mr-2 w-4 h-4" />
              <span className="flex-1">{displayText}</span>
              {selected && (
                <button
                  type="button"
                  onClick={handleClear}
                  className="hover:text-red-500 text-muted-foreground"
                  aria-label="Limpar data"
                >
                  <X className="w-4 h-4" />
                </button>
              )}
            </CalendarButton>
          </PopoverTrigger>
          <PopoverContent className="p-0 w-auto z-[9999]">
            <DayPicker
              mode="single"
              showOutsideDays={true}
              selected={selected}
              locale={ptBR}
              onSelect={handleSelect}
              className={cn('p-3 bg-white', className)}
              classNames={{
                selected: `bg-brand-primary-0 text-white`,
                chevron: `${defaultClassNames.chevron} fill-brand-primary-0`,
                today: `${defaultClassNames.day} text-brand-primary-0`,
              }}
            />
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};

export default FilterDatePickerSection;
