import ErrorMessage from './error-message';
import InputLabel from './label';
import MultiSelect from './multi-select';
import Select from './select';
import InputSelectRoot from './select-root';

export const InputSelect = {
  Root: InputSelectRoot,
  Input: Select,
  MultiInput: MultiSelect,
  Label: InputLabel,
  Error: ErrorMessage,
};
