import { useContextSelector } from 'use-context-selector';

import { McpProposalContext, McpProposalContextType } from '@src/context/mcp-proposal';

export const useMcpProposalContext = (): McpProposalContextType => {
  const proposal = useContextSelector(McpProposalContext, (proposal) => proposal.proposal);
  const setProposal = useContextSelector(McpProposalContext, (proposal) => proposal.setProposal);
  const deletedProposalId = useContextSelector(McpProposalContext, (proposal) => proposal.deletedProposalId);
  const setDeletedProposalId = useContextSelector(McpProposalContext, (proposal) => proposal.setDeletedProposalId);

  return { proposal, setProposal, deletedProposalId, setDeletedProposalId };
};
