import React, { LabelHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

import style from '../input/style.module.css';
interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  required?: boolean;
}

const InputLabel: React.FC<LabelProps> = ({ content, required, className }) => {
  return (
    <div className={style.labelContainer}>
      <label className={twMerge(style.labelContent, className)}>
        {content}
        {required && <span className="text-palette-orange-30">*</span>}
      </label>
    </div>
  );
};

export default InputLabel;
