import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useFormContext } from 'react-hook-form';

import { useGroupInfo } from '@hooks/get-group-info/useGroupInfo';
import { ProposalPayload } from '@hooks/create-mcp-proposal/types';

import { Input } from '@components/atoms/form/input';
import FormSection from '../form';

interface ContractTypeSection {
  type: 'SALE' | 'PURCHASE' | '';
  loading: boolean;
}

const GroupInfoSection: React.FC<ContractTypeSection> = ({ type, loading }) => {
  const { getGroupInfo, data, error, loading: loadingGroup } = useGroupInfo();
  const { setValue, watch, clearErrors } = useFormContext<ProposalPayload>();
  const docNumber = watch('docNumber');

  useEffect(() => {
    if (data) {
      clearErrors('name');
      const { name, legalName } = data;
      setValue('name', name);
      setValue('legalName', legalName);
    } else {
      setValue('name', '');
      setValue('legalName', '');
    }
  }, [data, docNumber]);

  useEffect(() => {
    if (docNumber && docNumber.length !== 14) {
      setValue('name', '');
      setValue('legalName', '');
      return;
    }

    if (docNumber && docNumber.length == 14) getGroupInfo({ variables: { docNumber } });
  }, [docNumber]);

  const handleTitle = () => {
    if (!type) return type;
    if (type === 'SALE') return 'Vendedor';
    return 'Comprador';
  };

  const docNumberFormat = {
    placeholder: '00.000.000/0000-00',
    format: '##.###.###/####-##',
    mask: '_',
    valueIsNumericString: true,
  };

  return (
    <FormSection title={`Informações do Grupo ${handleTitle()}`} description="Dados sobre a empresa cedente">
      {loading ? (
        <Skeleton height="7rem" className="md:w-[19.5rem] lg:w-[33.3rem] xl:w-[47rem] 2xl:w-[61rem]" />
      ) : (
        <>
          <Input.Root>
            <Input.Label content="CNPJ" className="font-normal text-paragraph-medium" required />
            <Input.NumericMask
              fieldName="docNumber"
              formatProps={docNumberFormat}
              rules={{
                required: { value: true, message: 'Campo obrigatório' },
              }}
            />
            <Input.Error fieldName="docNumber" />
          </Input.Root>

          <Input.Root>
            <Input.Label content="Nome" className="font-normal text-paragraph-medium" required />
            {loadingGroup ? (
              <Skeleton height="1.7rem" className="px-4 w-full h-7 py-[0.375rem]" />
            ) : (
              <Input.TextContent
                fieldName="name"
                placeholder="Grupo"
                rules={{ required: { value: true, message: 'Campo obrigatório' } }}
                disabled={!error}
              />
            )}
            <Input.Error fieldName="name" />
          </Input.Root>
        </>
      )}
    </FormSection>
  );
};

export default GroupInfoSection;
