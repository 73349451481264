import React from 'react';

export interface TitleSectionProps {
  title: string;
}

const TitleSection: React.FC<TitleSectionProps> = ({ title }) => {
  return <h1 className="text-heading-small font-bold mb-10">{title}</h1>;
};

export default TitleSection;
