import { useLazyQuery } from '@apollo/client';

import GET_MCP_PROPOSAL_BY_ID, { GET_MCP_PROPOSAL_BY_ID_TO_CREATE_BID } from './query';
import { McpProposalById, McpProposal } from './types';
import parseProposalInfo from './parser';

const useProposalById = (getToCreateBid: boolean) => {
  const QUERY = getToCreateBid ? GET_MCP_PROPOSAL_BY_ID_TO_CREATE_BID : GET_MCP_PROPOSAL_BY_ID;
  const [getProposal, { data, error, loading }] = useLazyQuery<McpProposalById>(QUERY);

  const dataParsed = data ? parseProposalInfo(data?.mcpProposalById as unknown as McpProposal) : null;

  return {
    getProposal,
    rawData: data?.mcpProposalById,
    data: dataParsed || null,
    error,
    loading,
  };
};

export default useProposalById;
