import React, { useEffect, useState } from 'react';
import { Control, useController } from 'react-hook-form';

interface MultiSelectOption {
  value: string | number;
  label: string;
}

interface MultiSelectProps {
  options: MultiSelectOption[];
  name: string;
  control: Control;
  placeholder?: string;
  label?: string;
  errorMessage?: string;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  options,
  name,
  control,
  placeholder = 'Select options...',
  label,
  errorMessage,
}) => {
  const { field } = useController({
    name,
    control,
  });

  const [selectedOptions, setSelectedOptions] = useState<(string | number)[]>(field.value || []);

  const handleOptionToggle = (optionValue: string | number) => {
    const updatedSelected = selectedOptions.includes(optionValue)
      ? selectedOptions.filter((value) => value !== optionValue)
      : [...selectedOptions, optionValue];

    setSelectedOptions(updatedSelected);
    field.onChange(updatedSelected);
  };

  useEffect(() => {
    setSelectedOptions(field.value || []);
  }, [field.value]);

  return (
    <div className="w-full">
      {label && <label className="block mb-2 text-sm font-medium text-gray-700">{label}</label>}
      <div className="flex flex-wrap gap-2 p-2 rounded-md border border-gray-300">
        {options.map((option) => (
          <div
            key={option.value}
            className={`cursor-pointer px-2 py-1 rounded-md text-sm transition-all ${
              selectedOptions.includes(option.value)
                ? 'bg-blue-600 text-white'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
            onClick={() => handleOptionToggle(option.value)}
          >
            {option.label}
          </div>
        ))}
        {selectedOptions.length === 0 && <span className="text-sm text-gray-500">{placeholder}</span>}
      </div>
      {errorMessage && <span className="block mt-1 text-sm text-red-600">{errorMessage}</span>}
    </div>
  );
};

export default MultiSelect;
