import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App.tsx';

import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './index.css';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter>
      <SkeletonTheme baseColor="#cbcbcb" highlightColor="#f0f0f0">
        <App />
      </SkeletonTheme>
    </BrowserRouter>
  </React.StrictMode>,
);
