import React from 'react';
import * as Menubar from '@radix-ui/react-menubar';
import { INDICATIVE_PRICES_DASHBOARD, VIEW_PROPOSAL_LIST_PATH } from '@routers/constants';
import useAuth from '@hooks/auth';
import CompanyLogo from '@components/atoms/company-logo';
import HeroIcon from '@components/atoms/hero-icon';
import NavigationLinks, { NavigationLinkProps } from '@components/molecules/menu-items';
import Profile from '@components/molecules/menu-profile';
import styles from './style.module.css';

const SidebarMobile: React.FC = () => {
  const { user } = useAuth();

  const indicativePricesLinks: NavigationLinkProps = {
    label: 'Dashboard',
    path: INDICATIVE_PRICES_DASHBOARD,
    iconName: 'ChartBarIcon',
  };

  const mcpLinks: NavigationLinkProps = {
    label: 'Cotações',
    path: VIEW_PROPOSAL_LIST_PATH,
    iconName: 'MegaphoneIcon',
  };

  return (
    <Menubar.Root className={styles.MenubarRoot}>
      <div className={styles.MenuBarMainLeft}>
        <CompanyLogo />
      </div>
      <div className={styles.MenuBarMainRight}>
        <Menubar.Menu>
          <Menubar.Trigger className={styles.MenubarTrigger}>
            <div className={styles.IconButton} aria-label="Icone de Menu">
              <HeroIcon icon="Bars3Icon" extraClassNames="place-self-center w-[18px] h-[18px]" />
            </div>
          </Menubar.Trigger>
          <Menubar.Portal>
            <Menubar.Content className={styles.MenubarContent} align="start">
              <div className="flex flex-col">
                <Profile picture={user?.pictureURL} name={`${user?.name}`} collapsed={false} />
              </div>
              <div className={styles.line}></div>
              <Menubar.Item className={styles.MenubarItem}>
                <NavigationLinks
                  links={[
                    {
                      label: 'Cotações',
                      path: VIEW_PROPOSAL_LIST_PATH,
                      iconName: 'MegaphoneIcon',
                    },
                  ]}
                />
              </Menubar.Item>
              <Menubar.Item className={styles.MenubarItem}>
                <NavigationLinks links={[mcpLinks, indicativePricesLinks]} />
              </Menubar.Item>
            </Menubar.Content>
          </Menubar.Portal>
        </Menubar.Menu>
      </div>
    </Menubar.Root>
  );
};

export default SidebarMobile;
