import { EnergyType, SubmarketType } from '@hooks/indicative-prices/types';

interface Submarkets {
  id: string;
  name: string;
  type: SubmarketType;
}

export const submarkets: Submarkets[] = [
  {
    id: '1',
    name: 'Sudeste / Centro Oeste',
    type: SubmarketType.SOUTHEAST,
  },
  {
    id: '2',
    name: 'Sul',
    type: SubmarketType.SOUTH,
  },
  {
    id: '3',
    name: 'Nordeste',
    type: SubmarketType.NORTHEAST,
  },
  {
    id: '4',
    name: 'Norte',
    type: SubmarketType.NORTH,
  },
];

export type SubmarketCheckboxStates = {
  [key in EnergyType]: {
    [key in SubmarketType]: boolean;
  };
};

export const initialSubmarketCheckboxStates: SubmarketCheckboxStates = {
  [EnergyType.INCENTIVIZED_50]: {
    [SubmarketType.SOUTHEAST]: false,
    [SubmarketType.SOUTH]: false,
    [SubmarketType.NORTHEAST]: false,
    [SubmarketType.NORTH]: false,
  },
  [EnergyType.INCENTIVIZED_100]: {
    [SubmarketType.SOUTHEAST]: false,
    [SubmarketType.SOUTH]: false,
    [SubmarketType.NORTHEAST]: false,
    [SubmarketType.NORTH]: false,
  },
  [EnergyType.CONVENTIONAL]: {
    [SubmarketType.SOUTHEAST]: false,
    [SubmarketType.SOUTH]: false,
    [SubmarketType.NORTHEAST]: false,
    [SubmarketType.NORTH]: false,
  },
};
