export interface ProposalPayload {
  contractType: string;
  name: string;
  legalName: string;
  docNumber: string;
  energyType: string;
  volume: number | null;
  supply: string;
  submarket: string;
  commission?: number | null;
  deadline: string;
}

export const EMPTY_PROPOSAL: ProposalPayload = {
  contractType: '',
  name: '',
  legalName: '',
  docNumber: '',
  energyType: '',
  volume: null,
  supply: '',
  submarket: '',
  commission: null,
  deadline: '',
};
