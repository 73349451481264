import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { ApolloError } from '@apollo/client';

import { VIEW_PROPOSAL_LIST_PATH } from '@routers/constants';

import { $dayjs } from '@utils/dayjs';

import { ProposalInfoParsed } from '@hooks/get-mcp-proposal-by-id/parser';
import { useCreateBid } from '@hooks/create-mcp-bid/useCreateBid';
import formatBidPaylod from '@hooks/create-mcp-bid/parser';
import { EMPTY_PROPOSAL_BID_PAYLOAD, ProposalBidPayload } from '@hooks/create-mcp-bid/types';

import ToastComponent from '@components/atoms/toast/toast';
import ModalSubmitBidComponent from '@components/molecules/modal-submit-bid';
import Container from '@components/molecules/bid-form';
import ModalEditProposal from '@components/molecules/modal-edit-proposal';

interface BidFormProps {
  traderId: string;
  setTransactionType: React.Dispatch<React.SetStateAction<'' | 'PURCHASE' | 'SALE'>>;
  proposal: ProposalInfoParsed;
  loading: boolean;
}

const BidForm = ({ traderId, setTransactionType, proposal, loading }: BidFormProps) => {
  const [showModalProposalBidForm, setModalProposalBidForm] = useState<boolean>(false);
  const [openModalEdition, setOpenModalEdition] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const navigate = useNavigate();

  const redirectToProposalListPage = () => {
    setTimeout(() => {
      navigate(VIEW_PROPOSAL_LIST_PATH);
    }, 2000);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const { createBidFn, data: bidData, loading: loadingCreation } = useCreateBid(proposal.id);

  const transactionType = proposal?.transactionType;
  const bidsInfo = proposal?.bids
    ? proposal.bids.map(({ bestOffer, spread, trader }) => ({
        bestOffer: bestOffer,
        spread: spread,
        userTraderPrice: trader.id === traderId,
      }))
    : [];

  useEffect(() => {
    if (transactionType) setTransactionType(transactionType);
  }, [transactionType]);

  const methods = useForm<any>({
    defaultValues: EMPTY_PROPOSAL_BID_PAYLOAD,
    mode: 'all',
  });

  const onSubmit = async (values: ProposalBidPayload) => {
    setToastMessage('');
    try {
      const parsedPayload = formatBidPaylod(values, traderId, proposal.id, transactionType);

      const response = await createBidFn({
        variables: { input: { ...parsedPayload } },
      });

      if (response) {
        setToastMessage('Proposta enviada com sucesso!');
        setModalProposalBidForm(true);
      }
    } catch (error) {
      const creationError = error as ApolloError;
      const errorMessage = creationError.message;

      let errorToastMessage = 'Erro ao enviar proposta.';

      if (errorMessage.includes('duplicate key value')) {
        errorToastMessage = 'Já existe uma proposata enviada anteriormente.';
      }
      setToastMessage(errorToastMessage);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Container
          proposalInfo={proposal}
          buttons={
            !loading
              ? [
                  {
                    label: 'Enviar Proposta',
                    kind: 'primary',
                    type: 'submit',
                    size: '1',
                    loading: loadingCreation,
                  },
                  {
                    label: 'Cancelar',
                    kind: 'secondary',
                    size: '1',
                    onClick: () => navigate(`${VIEW_PROPOSAL_LIST_PATH}`),
                  },
                ]
              : []
          }
          loading={loading}
        />
      </form>
      <ModalSubmitBidComponent
        hasBestPrice={bidData?.bestOffer}
        submitBidModal={showModalProposalBidForm}
        setSubmitBidModal={setModalProposalBidForm}
        ranking={bidsInfo}
        actionOnClick={() => {
          setModalProposalBidForm(false);
          setOpenModalEdition(!openModalEdition);
        }}
        cancelOnClick={() => {
          setModalProposalBidForm(false);
          navigate(VIEW_PROPOSAL_LIST_PATH);
        }}
      />

      {bidData && (
        <ModalEditProposal
          initialValues={{
            id: bidData?.id,
            deadline: $dayjs(bidData?.deadline).format('DDMMYYYYHHmm'),
            spread: bidData?.spread,
            proposalDeadline: proposal.deadline,
          }}
          isOpen={openModalEdition}
          setOpen={setOpenModalEdition}
          onActionCallback={() => redirectToProposalListPage()}
          onCancelCallback={() => redirectToProposalListPage()}
        />
      )}

      <ToastComponent label={toastMessage} type={toastMessage.length > 0} />
    </FormProvider>
  );
};

export default BidForm;
