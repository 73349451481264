import React from 'react';
import { twMerge } from 'tailwind-merge';

import MenuIcon from '@assets/menu-icon.svg';
import CompanyLogo from '@components/atoms/company-logo';
import Profile from '@components/molecules/menu-profile';
import NavigationLinks, { NavigationLinkProps } from '@components/molecules/menu-items';
import style from './style.module.css';
import { INDICATIVE_PRICES_DASHBOARD, VIEW_PROPOSAL_LIST_PATH } from '@routers/constants';
import useAuth from '@hooks/auth';

interface SidebarProps {
  collapsed: boolean;
  setCollapsed(collapsed: boolean): void;
}

const Sidebar: React.FC<SidebarProps> = ({ collapsed, setCollapsed }) => {
  const { user } = useAuth();

  const indicativePricesLinks: NavigationLinkProps = {
    label: 'Dashboard',
    path: INDICATIVE_PRICES_DASHBOARD,
    iconName: 'ChartBarIcon',
    isMenuCollapsed: collapsed,
  };

  const mcpLinks: NavigationLinkProps = {
    label: 'Cotações',
    path: VIEW_PROPOSAL_LIST_PATH,
    iconName: 'MegaphoneIcon',
    isMenuCollapsed: collapsed,
  };

  return (
    <div
      className={twMerge(style.sidebarContainer, collapsed ? 'pl-4 pt-4' : 'p-4')}
      role="navigation"
      aria-label="Menu"
    >
      <div className={style.sidebarContent}>
        <div className={twMerge(style.logoContainer, collapsed ? 'justify-center' : 'justify-between')}>
          {!collapsed && <CompanyLogo />}
          <button
            className={style.collapseButton}
            onClick={() => setCollapsed(!collapsed)}
            aria-label={collapsed ? 'Expandir menu' : 'Recolher menu'}
          >
            <img src={MenuIcon} alt="Ícone do Menu" className="cursor-pointer" />
          </button>
        </div>
        <div className="flex flex-col gap-3 mb-3">
          {user && <Profile picture={user?.pictureURL} name={`${user?.name}`} collapsed={collapsed} />}
        </div>
      </div>
      <span className={`${twMerge(style.textSubMenu)} ${collapsed ? twMerge(style.hiddenContent) : ''}`} role="text">
        Mercado de curto prazo
      </span>
      <NavigationLinks links={[mcpLinks]} />
      <span className={`${twMerge(style.textSubMenu)} ${collapsed ? twMerge(style.hiddenContent) : ''}`} role="text">
        Preços Indicativos
      </span>
      <NavigationLinks links={[indicativePricesLinks]} />
    </div>
  );
};

export default Sidebar;
