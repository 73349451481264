import React, { SelectHTMLAttributes } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import style from './style.module.css';

interface Options {
  value: string | number;
  label: string;
}

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  fieldName: string;
  options: Options[];
  placeholder?: string;
  rules?: RegisterOptions;
  error?: string;
}

const Select: React.FC<SelectProps> = ({ fieldName, options, placeholder, rules, ...restAttr }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const fieldError = errors[fieldName];

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={rules}
      render={({ field: fieldProps }) => (
        <select
          {...fieldProps}
          id={restAttr.id}
          className={twMerge(fieldError ? style.errorContainer : style.selectContainer, style.base, restAttr.className)}
          aria-invalid={!!fieldError}
          {...restAttr}
        >
          <option hidden value="">
            {placeholder || 'Selecione uma opção'}
          </option>

          {options.map(({ value, label }, idx) => (
            <option value={value} key={`option-${idx}-${label}`}>
              {label}
            </option>
          ))}
        </select>
      )}
    />
  );
};

export default Select;
