import React, { useState } from 'react';

import { useMcpWinnerBid } from '@hooks/create-winner-mcp-bid/useWinnerBid';

import NuggetInfoCollection from '@components/atoms/nugget-info';
import ModalComponent from '@components/atoms/modal';
import Text from '@components/atoms/text';
import { dataInfoList } from './helper';
import { useMcpProposalContext } from '@hooks/use-mcp-proposal';
import ToastComponent from '@components/atoms/toast/toast';

type Actions = {
  setSuccessAction: React.Dispatch<React.SetStateAction<boolean>>;
  setAcceptBid: React.Dispatch<React.SetStateAction<boolean>>;
};

export interface WinnerInfo {
  proposalId: string;
  bidId: string;
  price: string;
}

interface AcceptBidModalProps {
  acceptBid: boolean;
  actions: Actions;
  winnerInfo: WinnerInfo;
}

const AcceptBidModal = ({
  acceptBid,
  actions: { setAcceptBid, setSuccessAction },
  winnerInfo,
}: AcceptBidModalProps): JSX.Element => {
  const { setProposal } = useMcpProposalContext();
  const [toastMessage, setToastMessage] = useState<string>('');

  const { winnerBidFn, loading } = useMcpWinnerBid();

  return (
    <>
      <ModalComponent
        isOpen={acceptBid}
        setOpen={setAcceptBid}
        modalIcon={true}
        icon="CheckCircleIcon"
        title="Finalizar Operação"
        buttonsProps={[
          {
            label: 'Voltar',
            kind: 'secondary',
            screen: 'desktop',
            size: '2',
            onClick: () => setAcceptBid(false),
          },
          {
            label: 'Aceitar Proposta',
            kind: 'primary',
            screen: 'desktop',
            size: '2',
            loading: loading,
            onClick: async () => {
              setToastMessage('');
              try {
                const {
                  data: {
                    setWinningMcpBid: { mcpProposal },
                  },
                } = await winnerBidFn({
                  variables: {
                    mcpProposalId: winnerInfo.proposalId,
                    winnerMcpBidId: winnerInfo.bidId,
                  },
                });

                setProposal(mcpProposal);
                setAcceptBid(false);
                setSuccessAction(true);
                setToastMessage('A proposta foi aceita com sucesso!');
              } catch (error) {
                setAcceptBid(false);
                setSuccessAction(false);
                setToastMessage('Erro ao aceitar proposta.');
                throw new Error(`Error accept proposal:${error}`);
              }
            },
          },
        ]}
      >
        <>
          <Text className="flex flex-wrap p-2">
            Você escolheu a seguinte proposta para finalizar sua oferta de venda:
          </Text>
          <div className="flex justify-between">
            <NuggetInfoCollection infos={dataInfoList(winnerInfo)} />
          </div>
        </>
      </ModalComponent>
      <ToastComponent type={toastMessage.length > 0 ? true : false} label={toastMessage} swipeDirection="left" />
    </>
  );
};

export default AcceptBidModal;
