import React from 'react';
import { twMerge } from 'tailwind-merge';

import Text from '@components/atoms/text';
import { CheckboxComponent } from '@components/atoms/checkbox';

export interface FilterSectionProps {
  title: string;
  filters: {
    label: string;
    isChecked: boolean;
    onCheckedChange: (e: any) => void;
  }[];
  className?: string;
}

const FilterSection: React.FC<FilterSectionProps> = ({ title, filters, className = '' }) => {
  return (
    <div className="mt-6">
      <div className="border-t-extraThin border-brand-gray-20 pt-4">
        <Text size="text-paragraph-medium" weight="font-bold">
          {title}
        </Text>
      </div>

      <div className={twMerge('mt-2', className)}>
        {filters.map(({ label, isChecked, onCheckedChange }) => (
          <CheckboxComponent.Root key={label}>
            <CheckboxComponent.Indicator
              checked={isChecked}
              onCheckedChange={(checked) => onCheckedChange(checked)}
              type="semi-rounded"
            />
            <CheckboxComponent.Label content={label} />
          </CheckboxComponent.Root>
        ))}
      </div>
    </div>
  );
};

export default FilterSection;
