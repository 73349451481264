import { ParsedBidPayload, TransactionType, ProposalBidPayload } from './types';
import { formatDateFromString } from '@utils/dayjs';

const formatBidPaylod = (
  payload: ProposalBidPayload,
  traderId: string,
  proposalId: string,
  proposalTransaction?: TransactionType,
): ParsedBidPayload => ({
  traderId,
  mcpProposalId: proposalId,
  deadline: formatDateFromString(payload.deadline, 'DDMMYYYYHHmm', 'YYYY-MM-DD HH:mm:ss'),
  paymentDay:
    payload.paymentDay === 'NONE' ? (payload.numericPaymentDay as number) : (Number(payload.paymentDay) as number),
  spread: payload.spread as number,
  tusdRefund: payload.tusdPayment === 'NONE' ? (payload.numericTusd as number) : (payload.tusdPayment as number),
  transactionType: proposalTransaction === 'SALE' ? 'PURCHASE' : 'SALE',
});

export default formatBidPaylod;
