import { gql } from '@apollo/client';

export const GET_TRADER_FOR_USER = gql`
  query GetTraderForUser($userId: ID!) {
    getTraderForUser(userId: $userId) {
      trader {
        id
      }
    }
  }
`;

export interface Trader {
  id: string;
}

export interface GetTraderForUserType {
  getTraderForUser: {
    trader: {
      id: string;
    };
  };
}
