import React, { useState, useEffect } from 'react';

import Drawer from '@components/atoms/drawer';
import { NuggetButtonsProps } from '../../molecules/nugget-buttons';
import AcceptBidModal, { WinnerInfo } from '@components/molecules/modal-accept-bid';
import RefuseBidModal from '@components/molecules/modal-refuse-bid';
import MainContentDrawer from '@components/molecules/accept-or-refuse-drawer-content/main-content';
import MainContentDrawerSkeleton from '@components/molecules/accept-or-refuse-drawer-content/skeleton';

import { NuggetInfo, buildButtonsNuggetInfo } from './helper';

type NuggetKey = 'title' | 'content' | 'iconName';

type Nugget = {
  [key in NuggetKey]: string;
};
export interface MainInfoItem {
  id: string;
  volume: Nugget;
  energyType: Nugget;
  submarket: Nugget;
  period: Nugget;
}

interface ProposalDrawerProps {
  loading: boolean;
  title: string;
  content: {
    nuggetButtons: Record<string, NuggetButtonsProps['infos']>;
    mainInfo: MainInfoItem;
  };
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  isAdmin: boolean;
}

const ProposalDrawer: React.FC<ProposalDrawerProps> = ({
  loading,
  title,
  content: { nuggetButtons, mainInfo },
  isOpen,
  setOpen,
}) => {
  const [bidsNuggetButtons, setBidsNuggetButtons] = useState<Record<string, NuggetInfo[]>>(nuggetButtons);
  const [openAcceptModal, setOpenAcceptModal] = useState<boolean>(false);
  const [openRefusedModal, setOpenRefusedModal] = useState<boolean>(false);
  const [selectedBidId, setSelectedBidId] = useState<string>('');
  const [successAction, setSuccessAction] = useState<boolean>(false);
  const [acceptSuccessAction, setAcceptSuccessAction] = useState<boolean>(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    if (nuggetButtons) {
      setBidsNuggetButtons(nuggetButtons);
    }
  }, [nuggetButtons]);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 767);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const { id, ...proposalInfo } = mainInfo;

  const handleOpenAcceptModal = () => setOpenAcceptModal(!openAcceptModal);
  const handleOpenRefusedModal = () => setOpenRefusedModal(!openRefusedModal);

  const firstItemButtons = buildButtonsNuggetInfo(true, handleOpenAcceptModal, handleOpenRefusedModal, isSmallScreen);
  const othersItemButtons = buildButtonsNuggetInfo(false, handleOpenAcceptModal, handleOpenRefusedModal, isSmallScreen);

  const chosenWinner = {
    bidId: selectedBidId ?? '',
    price: selectedBidId ? bidsNuggetButtons[selectedBidId][1].content?.value : '',
    proposalId: id,
  };

  const previousWinner = Object.keys(bidsNuggetButtons).find((id) => bidsNuggetButtons[id][0].winner);

  const winnerBid = () => {
    if (previousWinner) return bidsNuggetButtons[previousWinner];

    if (selectedBidId && acceptSuccessAction) {
      return bidsNuggetButtons[selectedBidId];
    }

    return undefined;
  };

  useEffect(() => {
    if (successAction && selectedBidId) {
      setBidsNuggetButtons((prev) => {
        const updatedNuggetButtons = { ...prev };
        delete updatedNuggetButtons[selectedBidId];
        return updatedNuggetButtons;
      });
      setSelectedBidId('');
      setSuccessAction(false);
    }
  }, [successAction, selectedBidId, openAcceptModal]);

  return (
    <>
      <Drawer openDrawer={isOpen} setOpenDrawer={setOpen}>
        {loading ? (
          <MainContentDrawerSkeleton />
        ) : (
          <MainContentDrawer
            title={title}
            proposalInfo={proposalInfo}
            bids={bidsNuggetButtons}
            nuggetButtons={{
              mainBidButtons: firstItemButtons,
              otherBidsButtons: othersItemButtons,
              onClick: (value: string) => setSelectedBidId(value),
            }}
            winnerBid={winnerBid()}
          />
        )}
      </Drawer>
      <AcceptBidModal
        acceptBid={openAcceptModal}
        actions={{
          setAcceptBid: setOpenAcceptModal,
          setSuccessAction: setAcceptSuccessAction,
        }}
        winnerInfo={chosenWinner as unknown as WinnerInfo}
      />
      <RefuseBidModal
        actions={{
          setRefuseBid: setOpenRefusedModal,
          setSuccessAction,
          setTargetBid: setSelectedBidId,
        }}
        refuseBid={openRefusedModal}
        targetId={selectedBidId}
      />
    </>
  );
};

export default ProposalDrawer;
