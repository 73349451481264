import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { InputSelect } from '@components/atoms/form/select';
import { Input } from '@components/atoms/form/input';
import FormSection from '../form';

const ContractTypeSection: React.FC<{ loading: boolean }> = ({ loading }) => {
  const options = [
    {
      value: 'SALE',
      label: 'Venda',
    },
    {
      value: 'PURCHASE',
      label: 'Compra',
    },
  ];

  return (
    <FormSection
      title="Tipos de Contratos"
      description="Dados sobre o tipo de contrato"
      tooltip={{
        display: true,
        content:
          '<strong>Venda</strong>: você é (ou está representando) uma empresa que está vendendo energia;<br><strong>Compra:</strong> você é (ou está representando) uma empresa que está comprando energia.',
      }}
    >
      {loading ? (
        <Skeleton height="7rem" className="md:w-[19.5rem] lg:w-[33.3rem] xl:w-[47rem] 2xl:w-[61rem]" />
      ) : (
        <InputSelect.Root>
          <InputSelect.Label content="Tipo de contrato" required />
          <InputSelect.Input
            options={options}
            fieldName="contractType"
            rules={{ required: { value: true, message: 'Campo obrigatório' } }}
          />
          <Input.Error fieldName="contractType" />
        </InputSelect.Root>
      )}
    </FormSection>
  );
};

export default ContractTypeSection;
