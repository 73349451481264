import { useState, useEffect } from 'react';
import ButtonComponent, { ButtonComponentProps } from '@components/atoms/button';
import NuggetInfoCollection from '@components/atoms/nugget-info';
import style from './style.module.css';
import { NuggetInfo } from '@components/organisms/proposal-drawer-content/helper';

export interface NuggetButtonsProps {
  infos: NuggetInfo[];
  buttons: ButtonComponentProps[];
}

const NuggetButtons = ({ infos, buttons }: NuggetButtonsProps): JSX.Element => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 767);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  return (
    <>
      <div className={style.flexContainer}>
        <NuggetInfoCollection infos={infos} />
      </div>

      <div
        className={`${
          isSmallScreen && buttons.some((button) => button.label === '')
            ? style.buttonsContainerNoContent
            : style.buttonsContainerContent
        }`}
      >
        {buttons.map((button, idx) => (
          <ButtonComponent
            label={isSmallScreen ? 'Recusar Proposta' : ''}
            screen={isSmallScreen ? 'mobile' : 'desktop'}
            key={`btn-${idx}`}
            {...button}
          />
        ))}
      </div>
    </>
  );
};

export default NuggetButtons;
