import React, { ReactNode } from 'react';

interface ChartRootProps {
  children: ReactNode;
  className?: string;
}

export const ChartRoot: React.FC<ChartRootProps> = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};
