import { NuggetInfoComponent } from '@components/atoms/nugget-info';
import HeroIcon from '@components/atoms/hero-icon';

type NuggetRankingProps = {
  contents: { bestOffer: boolean; spread: string; userTraderPrice: boolean }[];
};

type RankingClassName = 'ranking-0' | 'ranking-1' | 'ranking-2';

const NuggetRanking = ({ contents }: NuggetRankingProps): JSX.Element => {
  const lastBestOfferIndex = contents.reduce((lastIndex, bid, index) => {
    return bid.bestOffer ? index : lastIndex;
  }, -1);

  const rankingIconClassNameGetter = {
    'ranking-0': 'stroke-brand-primary-60 w-5 h-5',
    'ranking-1': 'stroke-brand-gray-40 w-5 h-5',
    'ranking-2': 'stroke-palette-yellow-20 w-5 h-5',
  };

  return (
    <div className="flex flex-col gap-2">
      {contents.map((content, idx) => {
        const rankingIdx = content.bestOffer ? 0 : idx - lastBestOfferIndex;
        const userTraderPriceString = content.userTraderPrice ? '(Seu Preço)' : '';
        return (
          <NuggetInfoComponent
            key={`content-ranking-${rankingIdx}`}
            title={{ value: `${rankingIdx + 1}º Lugar ${userTraderPriceString}` }}
            content={{ value: content.spread }}
            icon={
              <HeroIcon
                name="TrophyIcon"
                icon="TrophyIcon"
                className={rankingIconClassNameGetter[`ranking-${rankingIdx}` as RankingClassName]}
              />
            }
          />
        );
      })}
    </div>
  );
};

export default NuggetRanking;
