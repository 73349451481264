import { useMutation } from '@apollo/client';
import { CreateIndicativePriceData } from '@hooks/indicative-prices/mutations/create/types';
import { CREATE_INDICATIVE_PRICES } from '@hooks/indicative-prices/mutations/create/mutation';

export const useCreateIndicativePrices = () => {
  const [
    createIndicativePrices,
    { data: createIndicativePricesData, loading: isCreatingIndicativePrices, error: indicativePricesError },
  ] = useMutation<CreateIndicativePriceData>(CREATE_INDICATIVE_PRICES);
  return {
    createIndicativePrices,
    success: createIndicativePricesData?.createIndicativePrices.success,
    loading: isCreatingIndicativePrices,
    indicativePricesError,
  };
};
