import { HeaderBreadCrumbProps } from '@components/atoms/header/bread-crumb';

import { VIEW_PROPOSAL_LIST_PATH, CREATE_PROPOSAL_PATH } from '@routers/constants';

export const breadCrumb: HeaderBreadCrumbProps['navigationHistoryLinks'] = [
  { url: VIEW_PROPOSAL_LIST_PATH, label: 'Mercado de Curto Prazo' },
  { url: VIEW_PROPOSAL_LIST_PATH, label: 'Formulário' },
  { url: CREATE_PROPOSAL_PATH, label: 'Contratação de energia' },
];

export const headerTitleTranslate = {
  SALE: 'Venda',
  PURCHASE: 'Compra',
};
