import { ENERGY_TYPE_TITLE_GETTER, SUBMARKET_TYPE_TITLE_GETTER } from '@hooks/indicative-prices/helper';
import { IndicativePrice } from '@hooks/indicative-prices/types';
import { formatDateFromString, getWeekNumber } from '@utils/dayjs';
import { formatNumber } from '@utils/numeral';

export interface IndicativePriceParsed {
  id: string;
  traderName: string;
  year: number;
  price: string;
  energyType: string;
  submarketType: string;
  week: number;
  createdAt: string;
  dataBase: string;
}

const parsePayload = (payload: IndicativePrice[]): IndicativePriceParsed[] => {
  return payload.map((item) => ({
    id: item.id ?? '',
    traderName: item.trader?.name || '',
    year: item.year,
    price: formatNumber(item.price, '0,0.00'),
    energyType: ENERGY_TYPE_TITLE_GETTER[item.energyType] || '',
    submarketType: SUBMARKET_TYPE_TITLE_GETTER[item.submarketType] || '',
    week: item.createdAt ? getWeekNumber(item.createdAt) : 0,
    createdAt: item.createdAt ? formatDateFromString(item.createdAt, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY') : '',
    dataBase: item.dataBase ? formatDateFromString(item.dataBase, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY') : '',
  }));
};

export default parsePayload;
