import { InputLabel } from './label';
import { InputNumericContent } from './numeric-content';
import { InputNumericMask } from './numeric-mask';
import { InputRoot } from './root';
import { InputTextContent } from './text-content';
import { InputPasswordContent } from './password-content';
import { ErrorMessage } from './error-message';

export const Input = {
    Root: InputRoot,
    Label: InputLabel,
    NumericContent: InputNumericContent,
    NumericMask: InputNumericMask,
    TextContent: InputTextContent,
    PasswordContent: InputPasswordContent,
    Error: ErrorMessage,
};
