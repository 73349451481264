import React from 'react';

import { twMerge } from 'tailwind-merge';

import style from './style.module.css';

export interface HeaderBreadCrumbProps {
  navigationHistoryLinks: { url: string; label: string }[];
}

const HeaderBreadCrumb: React.FC<HeaderBreadCrumbProps> = ({ navigationHistoryLinks }) => {
  return (
    <div className={style.layoutNavigationLinksHistory}>
      {navigationHistoryLinks?.map((navigationHistoryLink, index) => {
        const isLastLink = index + 1 === navigationHistoryLinks.length;
        return (
          <a
            key={index}
            href={navigationHistoryLink.url}
            className={twMerge('whitespace-nowrap', isLastLink ? 'font-bold' : 'font-regular')}
          >
            {`${navigationHistoryLink.label} ${!isLastLink ? '>' : ''}`}
          </a>
        );
      })}
    </div>
  );
};

export default HeaderBreadCrumb;
