import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CREATE_PROPOSAL_PATH } from '@routers/constants';

import configuration from '@config';

import useAuth from '@hooks/auth';
import { EnergyType, Status, Submarket, TransactionType } from '@hooks/create-mcp-proposal/parser';
import { useMcpProposals } from '@hooks/get-mcp-proposals/useMcpProposals';
import { useUserTrader } from '@hooks/get-user-trader/useUserTrader';
import { useMcpProposalContext } from '@hooks/use-mcp-proposal';

import ButtonComponent from '@components/atoms/button';
import ListSkeleton from '@components/molecules/skeleton/list-skeleton';
import CustomMessage from '@components/molecules/custom-message';
import FilterBtnAndTags from '@components/molecules/filters-buttons';
import { Layout } from '@components/layout';
import FilterDrawer from '@components/organisms/proposals-filter-drawer';
import ProposalItem from '@components/organisms/proposal-item';
import PaginationComponent from '@components/atoms/pagination';

import customMessage, { breadCrumb } from './helper';

export interface Filters {
  transactionType: TransactionType | null;
  status: Status | null;
  volume: { volMin: number; volMax: number }[];
  energyType: EnergyType[];
  submarket: Submarket[];
}

export default function McpProposalsPage() {
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [refetchProposals, setRefetchProposals] = useState<boolean>(false);
  const [clearFilters, setClearFilters] = useState<boolean>(false);
  const [ownership, setOwnership] = useState<string | null>('');
  const [pagination, setPagination] = useState<number>(1);
  const [filters, setFilters] = useState<Filters>({
    energyType: [],
    submarket: [],
    status: null,
    transactionType: null,
    volume: [],
  });

  const navigate = useNavigate();

  const { deletedProposalId } = useMcpProposalContext();
  const { user } = useAuth();
  const { userTrader } = useUserTrader(user.id);

  const isAdmin = user.roles?.includes('admin') ?? false;
  const isTrader = user.roles?.includes('supplier') ?? false;
  const finalTraderId = isAdmin ? configuration.CLARKE_TRADER_ID : userTrader.id;

  const { getMcpProposals, mcpProposals, loading, page, limit, total } = useMcpProposals();

  const fetchProposalsWithFilters = () => {
    getMcpProposals({
      variables: {
        page: pagination,
        traderId: ownership,
        filters: {
          status: filters.status,
          energyType: filters.energyType.length ? filters.energyType : null,
          submarket: filters.submarket.length ? filters.submarket : null,
          volume: filters.volume.length ? filters.volume : null,
          transactionType: filters.transactionType,
        },
      },
    });
  };

  useEffect(() => {
    getMcpProposals({ variables: { page: pagination } });
  }, [pagination]);

  useEffect(() => {
    if (clearFilters) {
      setOwnership('');
      setFilters({
        energyType: [],
        submarket: [],
        status: null,
        transactionType: null,
        volume: [],
      });
    }
  }, [clearFilters]);

  useEffect(() => {
    if (refetchProposals) {
      fetchProposalsWithFilters();
      setRefetchProposals(false);
      setClearFilters(false);
    }
  }, [refetchProposals, filters, pagination]);

  const userNotAuthorized = !isAdmin && !isTrader;

  const getCustomMessage = () => {
    if (userNotAuthorized) return customMessage.UNAUTHORIZED;

    if (!mcpProposals.length) return customMessage.EMPTY;

    return null;
  };

  const message = getCustomMessage();

  const userProposals = mcpProposals.filter(({ trader }) => trader.id === userTrader.id);
  const filterDeletedProposals = deletedProposalId
    ? mcpProposals.filter((proposal) => proposal.id !== deletedProposalId)
    : mcpProposals;

  return (
    <Layout.Content
      header={{
        title: 'Cotações no MCP',
        breadCrumbPaths: breadCrumb,
        buttons: !userNotAuthorized
          ? [
              {
                type: 'button',
                kind: 'primary',
                screen: 'desktop',
                label: 'Comprar ou vender',
                icon: 'PlusIcon',
                iconPosition: 'left',
                size: '3',
                onClick: () => navigate(CREATE_PROPOSAL_PATH),
              },
            ]
          : [],
      }}
    >
      <div className="block md:hidden">
        <ButtonComponent
          type="button"
          kind="primary"
          icon="PlusIcon"
          iconPosition="left"
          className="fixed z-20 right-[0.9375rem] bottom-[0.9375rem]"
          onClick={() => navigate(CREATE_PROPOSAL_PATH)}
        />
      </div>
      <section className="flex flex-col gap-6 min-w-full">
        <FilterBtnAndTags
          setOpenFilters={setOpenFilters}
          filters={filters}
          setFilters={setFilters}
          setRefetchProposals={setRefetchProposals}
          ownership={ownership}
          setOwnership={setOwnership}
        />

        {loading ? (
          <ListSkeleton />
        ) : message ? (
          <CustomMessage icon={message.icon} title={message.title} content={message.content} />
        ) : (
          filterDeletedProposals.map((proposal) => (
            <ProposalItem
              key={proposal.id}
              proposal={proposal}
              validBids={proposal.mcpBids}
              expiredBids={proposal.expiredMcpBids}
              isAdmin={isAdmin && !isTrader}
              traderId={userTrader.id}
              userProposals={userProposals}
            />
          ))
        )}
        <FilterDrawer
          actions={{ isOpen: openFilters, setIsOpen: setOpenFilters }}
          loading={loading}
          onClickAction={() => {
            fetchProposalsWithFilters();
            setOpenFilters(false);
            setClearFilters(false);
          }}
          onClickCancelAction={() => {
            setOpenFilters(false);
            setClearFilters(true);
          }}
          ownership={ownership}
          setOwnership={setOwnership}
          traderId={finalTraderId}
          setFilters={setFilters}
          filters={filters}
        />
        <PaginationComponent
          totalRows={total ?? 0}
          rowsByPage={limit ?? 10}
          currentPage={page ?? 1}
          setCurrentPage={setPagination}
        />
      </section>
    </Layout.Content>
  );
}
