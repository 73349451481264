import React, { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { useAuth0 } from '@auth0/auth0-react';
import ProfileLogo from '@src//components/atoms/profile-logo';
import style from './style.module.css';
import ButtonComponent from '@components/atoms/button';

interface UserProps {
  name: string;
  picture?: string | null;
  collapsed?: boolean;
}

const Profile: React.FC<UserProps> = ({ name, picture, collapsed }) => {
  const { logout } = useAuth0();
  const [showLogout, setShowLogout] = useState<boolean>(false);

  useEffect(() => {
    if (collapsed) setShowLogout(false);
  }, [collapsed]);

  return (
    <div className="flex flex-col gap-3">
      <div
        onClick={() => setShowLogout(!showLogout)}
        className={twMerge(
          style.profileContainer,
          collapsed ? 'justify-center ml-0 mr-0' : 'justify-between pl-4 pr-1 py-2',
        )}
      >
        <div className="flex gap-2 place-items-center truncate text-ellipsis">
          {picture ? (
            <img src={picture} width={40} height={40} className="rounded-pill" alt="user-image" />
          ) : (
            <ProfileLogo />
          )}
          <div className="flex-1 truncate">{!collapsed && <span>{name}</span>}</div>
        </div>
        {!collapsed && (
          <button>
            <ChevronUpDownIcon className="w-4 h-4 text-brand-gray-70" />
          </button>
        )}
      </div>

      {showLogout && (
        <ButtonComponent
          icon="ArrowRightStartOnRectangleIcon"
          iconPosition="right"
          kind="secondary"
          size="1"
          label={collapsed ? '' : 'Sair'}
          className={collapsed ? 'justify-center items-center' : 'w-full items-center justify-center'}
          onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
        />
      )}
    </div>
  );
};

export default Profile;
