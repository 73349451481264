import { $dayjs } from '@utils/dayjs';

import { EnergyTypeEnum, SubmarketEnum } from '@utils/translators';
import { McpProposal } from './types';
import { EditProposalPayload } from '@hooks/edit-mcp-proposal/types';

export interface ProposalInfoParsed {
  id: string;
  deadline: string;
  supply: string;
  submarket: SubmarketEnum;
  energyType: EnergyTypeEnum;
  transactionType: 'SALE' | 'PURCHASE';
  bids: McpProposal['mcpBids'];
}

const parseProposalInfo = (proposal: McpProposal): ProposalInfoParsed => ({
  id: proposal.id,
  deadline: $dayjs(proposal.deadline).format('DD/MM/YYYY [às] HH:mm[h]'),
  supply: $dayjs(proposal.period, 'YYYY-MM-DD').format('MMMM/YYYY'),
  submarket: SubmarketEnum[proposal.submarket],
  energyType: EnergyTypeEnum[proposal.energyType],
  transactionType: proposal.transactionType,
  bids: proposal.mcpBids ?? [],
});

export const parseProposalEditPayload = ({
  id,
  group: { name, docNumber, legalName },
  deadline,
  period,
  submarket,
  energyType,
  transactionType,
  proposalCommission,
  commissionType,
  volume,
  trader: { id: traderId },
}: McpProposal): EditProposalPayload => ({
  id,
  name,
  docNumber,
  legalName,
  traderId,
  deadline: $dayjs(deadline, 'YYYY-MM-DD HH:mm:ss').format('DDMMYYYYHHmm'),
  supply: $dayjs(period, 'YYYY-MM-DD').format('MMYYYY'),
  submarket: submarket,
  energyType: energyType,
  contractType: transactionType,
  commission: commissionType == 'REALS_PER_MEGAWATT' ? proposalCommission : undefined,
  volume: volume,
});

export default parseProposalInfo;
