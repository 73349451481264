import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';
import HeroIcon, { IconName } from '@src//components/atoms/hero-icon';
import style from './style.module.css';

export interface NavigationLinkProps {
  label: string;
  path: string;
  isMenuCollapsed?: boolean;
  iconName?: IconName;
  iconPath?: string;
}

interface MenuItemProps {
  iconName?: IconName;
  iconPath?: string;
  label: string;
  collapsed?: boolean;
  menuClassName?: string;
  path: string;
}

interface NavigationLinksCollectionProps {
  links?: NavigationLinkProps[];
}

interface NavigationLinksProps {
  links: NavigationLinkProps[];
}

const MenuItem = ({ iconName, iconPath, label, collapsed, path }: MenuItemProps) => {
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <div className="flex w-full h-fit">
      <div className={twMerge(style.itemsContent, collapsed ? 'justify-center' : 'p-0')}>
        <div className={twMerge(style.iconContainer, pathname === path ? 'bg-brand-light-20 rounded-pill' : '')}>
          {iconPath ? (
            <img src={iconPath} alt={label} className={style.iconItems} />
          ) : (
            iconName && (
              <HeroIcon
                icon={iconName}
                extraClassNames={twMerge(style.iconItem, pathname === path ? 'fill:bg-brand-gray-80' : '')}
              />
            )
          )}
        </div>
        <div className="flex items-center ml-2">{!collapsed && <p className={style.labelContainer}>{label}</p>}</div>
      </div>
    </div>
  );
};

export const NavigationLink: React.FC<NavigationLinkProps> = ({ label, path, iconName, iconPath, isMenuCollapsed }) => {
  return (
    <NavLink to={path} className={({ isActive }) => `flex py-2 ${isActive ? 'bg-brand-light-30 rounded-pill' : ''}`}>
      <MenuItem label={label} iconName={iconName} iconPath={iconPath} collapsed={isMenuCollapsed} path={path} />
    </NavLink>
  );
};

const NavigationLinksCollection: React.FC<NavigationLinksCollectionProps> = ({ links }) => (
  <>{links?.map((link, index) => <NavigationLink key={index} {...link} />)}</>
);

const NavigationLinks: React.FC<NavigationLinksProps> = ({ links }) => {
  const errorMessage = 'Menu appropriate links were not provided';
  if (!links) throw `ERROR: ${errorMessage}`;

  return (
    <div className="min-h-fit">
      <nav className="flex flex-col">
        <NavigationLinksCollection links={links} />
      </nav>
    </div>
  );
};

export default NavigationLinks;
