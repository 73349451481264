import { EnergyType, ModalityType, SubmarketType } from '@hooks/indicative-prices/types';

export const ENERGY_TYPE_TITLE_GETTER: Record<EnergyType, string> = {
  CONVENTIONAL: 'Convencional',
  INCENTIVIZED_50: 'I50',
  INCENTIVIZED_100: 'I100',
};

export const SUBMARKET_TYPE_TITLE_GETTER: Record<SubmarketType | string, string> = {
  SOUTHEAST: 'SE/CO',
  SOUTH: 'Sul',
  NORTHEAST: 'Nordeste',
  NORTH: 'Norte',
};

export const MODALITY_TYPE_TITLE_GETTER: Record<ModalityType, string> = {
  WHOLESALER: 'Atacadista',
  RETAILER: 'Varejista',
};
