import { useMutation } from '@apollo/client';
import { EditIndicativePriceData } from './types';
import { EDIT_INDICATIVE_PRICES } from './mutation';
import { GET_INDICATIVE_PRICES_BY_SUBMARKET } from '@hooks/indicative-prices/queries/get-by-submarket/query';
import { GetBySubmarketFilter } from '@hooks/indicative-prices/queries/get-by-submarket/types';
import { IndicativePriceInput } from '@hooks/indicative-prices/mutations/create/types';

interface EditIndicativePricesProps {
  onSuccess?: (message: string) => void;
  onError?: (message: string) => void;
}

export const useEditIndicativePrices = ({ onSuccess, onError }: EditIndicativePricesProps) => {
  const [performMutation, { data, loading, error }] = useMutation<EditIndicativePriceData>(EDIT_INDICATIVE_PRICES, {
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      if (data.updateIndicativePrice.success && onSuccess) {
        onSuccess('Preço editado com sucesso.');
      } else if (!data.updateIndicativePrice.success && onError) {
        onError('Erro ao editar o preço.');
      }
    },
    onError: () => {
      if (onError) {
        onError('Erro ao editar o preço.');
      }
    },
  });

  const editIndicativePrices = (inputData: IndicativePriceInput, currentFilter?: GetBySubmarketFilter) => {
    return performMutation({
      variables: { input: inputData },
      refetchQueries: [
        {
          query: GET_INDICATIVE_PRICES_BY_SUBMARKET,
          variables: { filter: currentFilter },
        },
      ],
    });
  };

  return {
    editIndicativePrices,
    success: data?.updateIndicativePrice.success,
    loading,
    error,
  };
};
