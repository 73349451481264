import React from 'react';
import { ApolloProvider, ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { useAuth0 } from '@auth0/auth0-react';

import configuration from '@config';
// import errorTracker from '@services/error-tracking';

export interface LoginState {
  hasLoginFailed: boolean;
  isLoginInProgress: boolean;
}
export interface AuthenticationState {
  isAuthenticated: boolean;
  accessToken: string;
  loginStatus: LoginState;
}

export interface User {
  id: string;
  name: string;
  pictureURL: string;
  roles?: ('admin' | 'supplier' | 'customer' | 'seller')[];
}
export interface IAuthContext {
  authStatus: AuthenticationState;
  user: User;
  logout: () => void;
}

interface ApolloWrapperProps {
  children: JSX.Element;
}

export const authContext = React.createContext({} as IAuthContext);

function ApolloWrapper({ children }: ApolloWrapperProps) {
  const { getAccessTokenSilently, isAuthenticated, error, user, isLoading: isLoginInProgress, logout } = useAuth0();
  const [token, setToken] = React.useState('');
  const hasLoginFailed = error !== undefined;

  React.useEffect(() => {
    const getToken = async () => {
      const bearerToken = isAuthenticated ? await getAccessTokenSilently() : '';
      setToken(bearerToken);
    };

    getToken();
  }, [getAccessTokenSilently, isAuthenticated]);

  const httpLink = createHttpLink({
    uri: configuration.GATEWAY_API_HOST,
  });

  const authLink = setContext((_, { headers, ...rest }) => {
    if (!token) return { headers, ...rest };
    return {
      ...rest,
      headers: { ...headers, Authorization: `Bearer ${token}` },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation, response }) => {
    const { variables, operationName } = operation;
    const context = { name: operationName, values: variables };

    if (networkError) {
      context.values = {
        ...context.values,
        errorType: 'network',
        rawErrorObject: JSON.stringify(networkError),
        rawResponseObject: JSON.stringify(response),
        rawVariables: JSON.stringify(variables),
      };
      // errorTracker.captureException(networkError, context);
    }

    if (graphQLErrors) {
      graphQLErrors.forEach((graphQLError) => {
        // const err = new Error(`GraphQL error on ${operationName}`);
        context.values = {
          ...context.values,
          errorType: 'graphql',
          rawErrorObject: JSON.stringify(graphQLError),
        };
        // errorTracker.captureException(err, context);
      });
    }
  });

  const client = new ApolloClient({
    link: from([errorLink, authLink.concat(httpLink)]),
    cache: new InMemoryCache(),
  });

  const loginStatus: LoginState = { hasLoginFailed, isLoginInProgress };
  const contextValue: IAuthContext = {
    authStatus: { isAuthenticated, accessToken: token, loginStatus },
    user: {
      id: user?.['https://clarke.com.br/uuid'],
      name: user?.name ?? '',
      pictureURL: user?.picture ?? '',
      roles: user?.['https://clarke.com.br/roles'] ?? undefined,
    },
    logout,
  };

  return (
    <ApolloProvider client={client}>
      <authContext.Provider value={contextValue}>{children}</authContext.Provider>
    </ApolloProvider>
  );
}

export default ApolloWrapper;
