import React from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import HeroIcon, { IconName } from '@components/atoms/hero-icon';
import { twMerge } from 'tailwind-merge';

import style from './style.module.css';

interface TooltipTriggerProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: IconName;
  iconClassName?: string;
}

export const TooltipTrigger: React.FC<TooltipTriggerProps> = ({ icon, iconClassName, className, onClick }) => {
  return (
    <Tooltip.Trigger className={className} asChild type="button">
      <button onClick={onClick}>
        <HeroIcon className={twMerge(style.triggerIcon, iconClassName)} extraClassNames="w-4 h-4" icon={icon} />
      </button>
    </Tooltip.Trigger>
  );
};
