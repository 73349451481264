import React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

import style from './style.module.css';
import { twMerge } from 'tailwind-merge';
import HeroIcon from '@components/atoms/hero-icon';

export interface InputNumericContentProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  fieldName: string;
  inputRef?: ((el: HTMLInputElement) => void) | React.Ref<any> | undefined;
  rules?: RegisterOptions;
  error?: string;
  formatProps: Pick<
    NumericFormatProps,
    | 'placeholder'
    | 'prefix'
    | 'suffix'
    | 'allowNegative'
    | 'thousandSeparator'
    | 'decimalSeparator'
    | 'decimalScale'
    | 'fixedDecimalScale'
    | 'valueIsNumericString'
  >;
}

export const InputNumericContent: React.FC<InputNumericContentProps> = ({
  fieldName,
  inputRef,
  rules,
  formatProps: {
    placeholder,
    prefix,
    suffix,
    allowNegative = false,
    thousandSeparator = '.',
    decimalSeparator = ',',
    decimalScale = 2,
    fixedDecimalScale = true,
    valueIsNumericString,
  },
  ...attrs
}) => {
  const { id, className, disabled, required } = attrs;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const fieldError = errors[fieldName];

  const focusClass = fieldError ? style.contentFocusOnError : style.contentFocus;

  const disabledBorder = disabled ? style.disabledBorder : '';

  const dynamicClasses = twMerge(focusClass, className, disabledBorder);

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={rules}
      render={({ field: { name, onChange, onBlur, value } }) => (
        <div className={style.inputContainer}>
          <NumericFormat
            id={id}
            name={name}
            disabled={disabled}
            className={twMerge(style.content, dynamicClasses)}
            thousandSeparator={thousandSeparator}
            decimalSeparator={decimalSeparator}
            decimalScale={decimalScale}
            fixedDecimalScale={fixedDecimalScale}
            allowNegative={allowNegative}
            valueIsNumericString={true}
            prefix={prefix}
            suffix={suffix}
            value={value === undefined ? '' : value}
            placeholder={placeholder}
            required={required}
            onBlur={onBlur}
            onValueChange={(v) => {
              if (valueIsNumericString) {
                return onChange(v.value);
              }

              const value = v.floatValue === undefined ? null : v.floatValue;

              if (value && !allowNegative) {
                onChange(Math.abs(value));
              } else {
                onChange(value);
              }
            }}
            getInputRef={inputRef}
          />
          {fieldError && (
            <HeroIcon
              className={twMerge(style.iconOnError, style.icon, style.iconPositionInsideContent)}
              icon={'ExclamationCircleIcon'}
            />
          )}
        </div>
      )}
    />
  );
};
