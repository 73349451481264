import numeral from 'numeral';

import { Responsive } from '@radix-ui/themes/dist/cjs/props/index.js';
import { NuggetInfoComponentProps, TextWeight } from '@components/atoms/nugget-info';
import { Variant, IconPosition, ButtonComponentProps } from '@components/atoms/button';
import { SubmarketEnum, EnergyTypeEnum } from '@utils/translators';
import { formatStringToMonthYear } from '@utils/dayjs';

import { MainInfoItem } from '.';

interface MainInfoProps {
  id: string;
  volume?: number;
  energyType: keyof typeof EnergyTypeEnum;
  submarket: keyof typeof SubmarketEnum;
  period?: string;
}

export const mainInfo = (proposal: MainInfoProps): MainInfoItem => {
  return {
    id: proposal.id,
    energyType: {
      title: 'Tipo de energia',
      content: EnergyTypeEnum[proposal.energyType],
      iconName: 'BoltIcon',
    },
    volume: {
      title: 'Volume vendido',
      content: `${numeral(proposal.volume).format('0,0.000')} MWh`,
      iconName: 'BoltIcon',
    },
    submarket: {
      title: 'Submercado',
      content: SubmarketEnum[proposal.submarket],
      iconName: 'GlobeAmericasIcon',
    },
    period: {
      title: 'Periodo de fornecimento',
      content: proposal.period ? formatStringToMonthYear(proposal.period) : '',
      iconName: 'CalendarIcon',
    },
  };
};

export interface DataBidListProps {
  deadline: string;
  paymentDeadline: number;
  tusdRefund: string;
  id?: string;
  winnerAt: string | null;
  traderName: string;
  score: number | null;
  position: number;
  spreadWithCommission: string;
  commission: string;
}

export interface NuggetInfo extends NuggetInfoComponentProps {
  id?: string;
  winner?: boolean;
  position?: number;
  deadline?: string;
}

export const dataBidList = (bid: DataBidListProps, boldText: boolean) => {
  const bidInfo: NuggetInfo[] = [
    {
      id: bid.id,
      winner: bid.winnerAt ? true : false,
      position: bid.position,
      deadline: bid.deadline,
      title: { value: 'Fornecedor', weight: boldText ? 'bold' : ('normal' as TextWeight), size: 'small' },
      content: { value: bid.traderName, size: 'medium' },
      tooltip: { display: true, content: bid.score ? `Score de risco: ${bid.score}` : '' },
    },
    {
      title: { value: 'Preço', weight: boldText ? 'bold' : ('normal' as TextWeight), size: 'small' },
      content: { value: `PLD + ${bid.spreadWithCommission}`, size: 'medium' },
    },
    {
      title: { value: 'Validade da Proposta', weight: boldText ? 'bold' : ('normal' as TextWeight), size: 'small' },
      content: { value: bid.deadline, size: 'medium' },
    },
    {
      title: { value: 'Pagamento', weight: boldText ? 'bold' : ('normal' as TextWeight), size: 'small' },
      content: { value: `${bid.paymentDeadline}º dia útil`, size: 'medium' },
    },
    {
      title: { value: 'Ressarcimento (TUSD)', weight: boldText ? 'bold' : ('normal' as TextWeight), size: 'small' },
      content: { value: bid.tusdRefund, size: 'medium' },
    },
  ];

  if (bid.commission) {
    bidInfo.push({
      title: { value: 'Comissão', weight: boldText ? 'bold' : ('normal' as TextWeight), size: 'small' },
      content: { value: bid.commission, size: 'medium' },
    });
  }
  return bidInfo;
};

export const buildButtonsNuggetInfo = (
  hasprimaryBtn: boolean,
  acceptOnClickAction: () => void,
  refusedOnClickAction: () => void,
  isSmallScreen: boolean,
): ButtonComponentProps[] => {
  return [
    {
      kind: hasprimaryBtn ? 'primary' : 'iconPrimary',
      icon: 'CheckCircleIcon',
      label: hasprimaryBtn || isSmallScreen ? 'Aceitar proposta' : '',
      size: '1' as Responsive<'1'>,
      iconPosition: 'left' as IconPosition,
      screen: isSmallScreen ? 'mobile' : 'desktop',
      className: 'p-2',
      onClick: acceptOnClickAction,
    },
    {
      kind: 'iconTertiary' as Variant,
      icon: 'TrashIcon',
      size: '1' as Responsive<'1'>,
      iconPosition: 'left' as IconPosition,
      screen: isSmallScreen ? 'mobile' : 'desktop',
      className: 'p-2',
      onClick: refusedOnClickAction,
    },
  ];
};
