import React, { ReactNode } from 'react';

interface InputRootProps extends React.ComponentProps<'div'> {
  children: ReactNode;
}

const InputSelectRoot: React.FC<InputRootProps> = ({ children, ...restAttr }) => {
  return (
    <div className={`flex flex-col gap-2 ${restAttr.className}`} {...restAttr}>
      {children}
    </div>
  );
};

export default InputSelectRoot;
