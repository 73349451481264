import React from 'react';
import HeroIcon from '@components/atoms/hero-icon';

import style from './style.module.css';

const NoPriceSubmissionReminder: React.FC = () => {
  return (
    <div className={style.reminderContainer}>
      <div className={style.reminderIconContainer}>
        <HeroIcon className={style.reminderIcon} icon={'FaceFrownIcon'} />
      </div>
      <div className="flex flex-col max-w-[28rem]">
        <h2 className={style.reminderTitle}>Você ainda não inseriu seus preços</h2>
        <p className={style.reminderSubtitle}>
          Você ainda não inseriu os preços nesta semana. Adicione seu preço para visualizar o dashboard.
        </p>
      </div>
    </div>
  );
};

export default NoPriceSubmissionReminder;
