import { FormProvider, useForm } from 'react-hook-form';
import Container from '@components/molecules/proposal-form';

interface ProposalFormSkeletonProps {
  isAdmin: boolean;
  loading: boolean;
}

const EditProposalFormSkeleton = ({ isAdmin, loading }: ProposalFormSkeletonProps) => {
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <Container loading={loading} adminMode={isAdmin} formType="PURCHASE" buttons={[]} />;
    </FormProvider>
  );
};

export default EditProposalFormSkeleton;
