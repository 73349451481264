import { gql } from '@apollo/client';

export const EDIT_MCP_BID = gql`
  mutation EditMcpBid($input: EditMCPBidInput!) {
    editMcpBid(input: $input) {
      id
      spread
      deadline
    }
  }
`;
