/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ButtonComponent, { ButtonComponentProps } from '@components/atoms/button';
import { Input } from '@components/atoms/form/input';
import { IndicativePriceInput } from '@hooks/indicative-prices/mutations/create/types';

interface EditIndicativePriceFormProps {
  isLoading: boolean;
  initialPrice: number;
  onSubmit: (values: IndicativePriceInput) => void;
  onCancelEdit: VoidFunction;
}

const EditIndicativePriceForm: React.FC<EditIndicativePriceFormProps> = ({
  isLoading,
  initialPrice,
  onSubmit,
  onCancelEdit,
}) => {
  const methods = useForm<IndicativePriceInput>({
    defaultValues: { price: initialPrice },
  });

  useEffect(() => {
    methods.reset({ price: initialPrice });
  }, [initialPrice, methods]);

  const buttonsProps: ButtonComponentProps[] = [
    {
      kind: 'primary',
      screen: 'desktop',
      label: 'Confirmar',
      loading: isLoading,
    },
    {
      kind: 'secondary',
      screen: 'desktop',
      label: 'Cancelar',
      onClick: onCancelEdit,
    },
  ];

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Input.Root>
          <Input.NumericContent
            className="w-full font-bold text-paragraph-medium bg-brand-light-30"
            content="Preço"
            fieldName="price"
            formatProps={{
              prefix: 'R$ ',
              placeholder: 'R$ 00,00',
            }}
          />
        </Input.Root>
        <div className="grid grid-cols-2 gap-2 mt-8 -mb-5 w-full">
          {buttonsProps.map((props) => (
            <ButtonComponent key={props.label} size="2" {...props} />
          ))}
        </div>
      </form>
    </FormProvider>
  );
};

export default EditIndicativePriceForm;
