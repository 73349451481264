import { useMutation } from '@apollo/client';

import { CREATE_COMMERCIAL_GROUP } from './mutation';

export const useCreateCommercialGroup = () => {
  const [createGroupFn, { data, error, loading }] = useMutation(CREATE_COMMERCIAL_GROUP);

  return {
    createGroupFn,
    groupId: data?.createCommercialGroup?.id || '',
    error,
    loading,
  };
};
