import React from 'react';
import numeral from 'numeral';

import { ButtonComponentProps } from '@components/atoms/button';
import ModalComponent from '@components/atoms/modal';
import NuggetRanking from '@components/molecules/nugget-ranking';

interface ModalSubmitBidProps {
  hasBestPrice: boolean;
  submitBidModal: boolean;
  setSubmitBidModal: React.Dispatch<React.SetStateAction<boolean>>;
  ranking: { bestOffer: boolean; spread: number; userTraderPrice: boolean }[];
  actionOnClick: () => void;
  cancelOnClick: () => void;
}

const ModalSubmitBidComponent: React.FC<ModalSubmitBidProps> = ({
  hasBestPrice,
  submitBidModal,
  setSubmitBidModal,
  ranking,
  cancelOnClick,
  actionOnClick,
}) => {
  const bestPositionRanking = ranking.length >= 3 ? ranking.slice(0, 3) : ranking;

  const bidsInfoContent = bestPositionRanking.map((bid) => ({
    bestOffer: bid.bestOffer,
    spread: `PLD + ${numeral(bid.spread).format('0,0.00')}`,
    userTraderPrice: bid.userTraderPrice,
  }));

  const buttons: ButtonComponentProps[] = [
    {
      label: 'Ver Cotações',
      kind: hasBestPrice ? 'primary' : 'secondary',
      screen: 'desktop',
      size: '2',
      onClick: cancelOnClick,
    },
    {
      label: 'Editar proposta',
      type: 'submit',
      kind: hasBestPrice ? 'secondary' : 'primary',
      screen: 'desktop',
      size: '2',
      onClick: actionOnClick,
    },
  ];

  return (
    <ModalComponent
      isOpen={submitBidModal}
      setOpen={setSubmitBidModal}
      modalIcon={true}
      icon={hasBestPrice ? 'CheckCircleIcon' : 'ExclamationTriangleIcon'}
      iconColor={hasBestPrice ? 'green' : 'yellow'}
      title={hasBestPrice ? 'Você tem a melhor proposta!' : 'Outra proposta tem melhores condições!'}
      description={
        hasBestPrice
          ? 'Por enquanto, a sua proposta apresentou as melhores condições de contratação. Mas algumas propostas se aproximam da sua oferta:'
          : 'Infelizmente a sua proposta não tem as melhores condições de contratação. Mas não se preocupe, retorne a página e realize uma nova proposta. '
      }
      buttonsProps={hasBestPrice ? buttons.reverse() : buttons}
    >
      <NuggetRanking contents={bidsInfoContent} />
    </ModalComponent>
  );
};

export default ModalSubmitBidComponent;
