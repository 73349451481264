import { useLazyQuery } from '@apollo/client';
import { GET_MCP_PROPOSALS } from './queries';
import { AllMcpProposalType } from './types';

export const useMcpProposals = () => {
  const [getMcpProposals, { data, loading, error }] = useLazyQuery<AllMcpProposalType>(GET_MCP_PROPOSALS);

  return {
    getMcpProposals,
    page: data?.mcpProposals.page,
    limit: data?.mcpProposals.limit,
    total: data?.mcpProposals.total,
    mcpProposals: data?.mcpProposals.data || [],
    loading,
    error,
  };
};
