import Text from '@components/atoms/text';
import HeroIcon, { IconName } from '@components/atoms/hero-icon';

import style from './style.module.css';

export interface CustomMessageProps {
  title: string;
  content: string;
  icon: {
    name: IconName;
    className: string;
  };
}

const CustomMessage = ({ title, content, icon }: CustomMessageProps): JSX.Element => {
  return (
    <div className={style.mainContainer}>
      <div className={`${style.iconContainer} ${icon.className}`}>
        <HeroIcon icon={icon.name} className={style.icon} />
      </div>
      <div className={style.textContainer}>
        <Text className={style.mainTitle}>{title}</Text>
        <Text className={style.text}>{content}</Text>
      </div>
    </div>
  );
};

export default CustomMessage;
