import { gql } from '@apollo/client';

export const CHECK_CURRENT_WEEK_PRICE_SUBMISSION = gql`
  query CheckCurrentWeekPriceSubmission($traderId: String!) {
    checkCurrentWeekPriceSubmission(traderId: $traderId) {
      success
      message
    }
  }
`;
