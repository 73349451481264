export enum EnergyType {
  CONVENTIONAL = 'CONVENTIONAL',
  INCENTIVIZED_50 = 'INCENTIVIZED_50',
  INCENTIVIZED_100 = 'INCENTIVIZED_100',
}

export enum SubmarketType {
  SOUTHEAST = 'SOUTHEAST',
  SOUTH = 'SOUTH',
  NORTHEAST = 'NORTHEAST',
  NORTH = 'NORTH',
}

export enum ModalityType {
  WHOLESALER = 'WHOLESALER',
  RETAILER = 'RETAILER',
}

export interface IndicativePrice {
  id?: string;
  trader: Trader;
  year: number;
  price: number;
  energyType: EnergyType;
  submarketType: SubmarketType;
  modalityType: ModalityType;
  upperFlexibility?: string;
  lowerFlexibility?: string;
  modulationType?: string;
  seasonality?: string;
  createdAt?: string;
  dataBase?: string;
}

interface Trader {
  id: string;
  name: string;
}
