import React from 'react';
import { twMerge } from 'tailwind-merge';
import { ChevronUpDownIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/16/solid';

export interface ColumnOptions<T> {
  accessor?: keyof T | null;
  header?: string;
  renderAsElement?: (entry: T) => JSX.Element | string;
  fixedWidthInRem?: number;
  noWrap?: boolean;
  sortableColumn?: boolean;
  ellipsisStyle?: {
    position: string;
  };
}

export interface TableProps<T> extends React.SelectHTMLAttributes<HTMLTableElement> {
  data: Array<T>;
  tableColumns: Array<ColumnOptions<T>>;
  stripedClassName?: string;
  firstColumBold?: boolean;
  lastRow?: {
    fontBold?: boolean;
    highlight?: boolean;
  };
  onRowClickAction?: (entry: T) => void;
  onRowDoubleClickAction?: (entry: T) => void;
  firstColumnFixed?: boolean;
  backgroundColorClassname?: string;
  onSort?: (accessor: keyof T) => void;
  sortedColumn?: keyof T;
  isAscOrder?: boolean;
}

function useTable<T>(columns: ColumnOptions<T>[]) {
  const mapper: Record<string, ColumnOptions<T>['renderAsElement']> = {};
  columns.forEach((column) => {
    mapper[column.accessor as string] = column.renderAsElement;
  });

  const renderCell = (entry: T, accessor: keyof T) => {
    const renderFunction = mapper[accessor as string];
    return renderFunction ? renderFunction(entry) : `${entry[accessor]}`;
  };

  return renderCell;
}

function TableComponent<T>({
  data,
  tableColumns,
  lastRow,
  onRowClickAction,
  onRowDoubleClickAction,
  firstColumnFixed,
  backgroundColorClassname,
  stripedClassName,
  firstColumBold = false,
  onSort,
  sortedColumn,
  isAscOrder,
  ...attrs
}: TableProps<T>) {
  const renderCell = useTable<T>(tableColumns);
  const { className, ...restAttrs } = attrs;

  const stripedClass = stripedClassName ? stripedClassName : '';

  const onRowClickHandler = (entry: T) => {
    if (onRowClickAction && !window.getSelection()?.toString()) {
      onRowClickAction(entry);
    }
  };

  const onRowDoubleClickHandler = (entry: T) => {
    if (onRowDoubleClickAction && !window.getSelection()?.toString()) {
      onRowDoubleClickAction(entry);
    }
  };

  const lastRowClassName = twMerge(
    lastRow?.fontBold && 'font-semibold',
    lastRow?.highlight && 'bg-primary-60 hover:bg-primary-70',
  );

  return (
    <div className={twMerge('min-w-full w-fit', backgroundColorClassname)}>
      <table className={twMerge('w-full', className)} {...restAttrs}>
        <thead>
          <tr>
            {tableColumns.map((column, index) => {
              const hasHeaderContent = column.header || column.sortableColumn;
              return (
                hasHeaderContent && (
                  <th
                    key={index}
                    className={twMerge(
                      'py-[7px] pl-[16px] text-paragraph-small font-bold text-left border-b border-brand-gray-80',
                      firstColumnFixed && index === 0 ? `sticky left-0 z-10 ${backgroundColorClassname}` : 'z-0',
                    )}
                  >
                    <div className="flex gap-3 items-center">
                      {column.header && <span>{column.header}</span>}
                      {column.sortableColumn && (
                        <button
                          onClick={() => onSort?.(column.accessor!)}
                          className="ml-2 text-gray-600 hover:text-gray-800"
                        >
                          {sortedColumn === column.accessor ? (
                            isAscOrder ? (
                              <ChevronDownIcon className="w-5 h-4" aria-hidden="true" />
                            ) : (
                              <ChevronUpIcon className="w-5 h-4" aria-hidden="true" />
                            )
                          ) : (
                            <ChevronUpDownIcon className="w-5 h-5" aria-hidden="true" />
                          )}
                        </button>
                      )}
                    </div>
                  </th>
                )
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            data?.map((entry, rowIndex) => {
              return (
                <tr
                  key={rowIndex}
                  className={twMerge(
                    'border-neutral-20 border-y hover:bg-neutral-10 cursor-normal',
                    stripedClass,
                    (onRowDoubleClickAction || onRowClickAction) && 'cursor-pointer',
                    rowIndex + 1 === data.length && lastRowClassName,
                  )}
                >
                  {tableColumns.map(
                    ({ accessor, renderAsElement, fixedWidthInRem, ellipsisStyle, noWrap }, columnIndex) => (
                      <td
                        key={`item-${columnIndex}`}
                        onClick={() => onRowClickHandler(entry)}
                        onDoubleClick={() => onRowDoubleClickHandler(entry)}
                        className={twMerge(
                          'py-[7px] px-5 text-paragraph-small',
                          firstColumBold && columnIndex === 0 ? 'font-bold' : 'font-normal',
                          firstColumnFixed && columnIndex === 0
                            ? `sticky left-0 z-10 ${backgroundColorClassname}`
                            : 'z-0',
                          noWrap ? 'whitespace-nowrap' : '',
                        )}
                        style={{
                          maxWidth: fixedWidthInRem && `${fixedWidthInRem}rem`,
                          width: fixedWidthInRem && `${fixedWidthInRem}rem`,
                        }}
                      >
                        <div className={ellipsisStyle ? 'truncate' : ''}>
                          {accessor && renderCell(entry, accessor)}
                          {!accessor && renderAsElement && renderAsElement(entry)}
                        </div>
                      </td>
                    ),
                  )}
                </tr>
              );
            })
          ) : (
            <tr className="border-neutral-20 border-y">
              <td>
                <span className="whitespace-nowrap text-paragraph-small">Sem registros disponíveis</span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default TableComponent;
