import React from 'react';
import Skeleton from 'react-loading-skeleton';

interface ListSkeletonProps {
  width?: string;
  height?: string;
}

const ListSkeleton: React.FC<ListSkeletonProps> = ({ width = '100%', height = '5rem' }) => {
  return Array(3)
    .fill({ w: width, h: height })
    .map((skeleton, index) => <Skeleton width={skeleton.w} height={skeleton.h} key={`skeleton-${index}`} />);
};

export default ListSkeleton;
