import { gql } from '@apollo/client';

export const DELETE_BID = gql`
  mutation DeleteMcpBid($id: ID!) {
    deleteMcpBid(mcpBidId: $id) {
      id
      mcpProposal {
        id
        volume
        transactionType
        energyType
        deadline
        period
        status
        submarket
        group {
          name
        }
        mcpBids {
          id
          trader {
            id
            name
            score
          }
          spread
          bestOffer
          deadline
          tusdRefund
          paymentDeadline
          winnerAt
        }
      }
    }
  }
`;
