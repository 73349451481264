import React from "react";

import style from './style.module.css'

type HeaderTitleProps = {
  title: string;
};

const HeaderTitle: React.FC<HeaderTitleProps> = ({
    title,
    }) => {

  return (
      <div>
        <h1 className={style.title}>{title}</h1>
      </div>
  );
};

export default HeaderTitle;

