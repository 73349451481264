import { useMutation } from '@apollo/client';

import GET_MCP_PROPOSAL_BY_ID from '@hooks/get-mcp-proposal-by-id/query';
import { CREATE_BID } from './mutation';

export const useCreateBid = (proposalId: string) => {
  const [createBidFn, { data, error, loading }] = useMutation(CREATE_BID, {
    refetchQueries: [
      {
        query: GET_MCP_PROPOSAL_BY_ID,
        variables: { id: proposalId },
      },
    ],
  });

  const createdBidResponse = data?.createMcpBid;

  return {
    createBidFn,
    data: createdBidResponse,
    error,
    loading,
  };
};
