import React, { useEffect, useRef, useState } from 'react';
import EditButton from './edit-button';

import { IndicativePriceParsed } from '@hooks/indicative-prices/queries/get-by-submarket/parser';

import PaginationComponent from '@components/atoms/pagination';
import TableComponent, { ColumnOptions } from '@components/atoms/table';
import InidicativePricesFilterDrawer from '@components/organisms/indicative-prices-filter-drawer';
import { useIndicativePricesFiltersContext } from '@src/context/indicative-prices-filter';
import IndicativePricesFilterBtnAndTags from '../indicative-prices-filters-buttons';
import ListSkeleton from '../skeleton/list-skeleton';

export interface IndicativePricesTableProps {
  title: string;
  tableData: IndicativePriceParsed[];
  onEditClick: (indicativePriceId: string) => void;
  totalRows: number;
  rowsByPage: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>> | (() => void);
  onSort: (accessor: keyof IndicativePriceParsed) => void;
  sortedColumn: keyof IndicativePriceParsed;
  isAscOrder: boolean;
  isLoading: boolean;
}

const IndicativePricesTable: React.FC<IndicativePricesTableProps> = ({
  title,
  tableData,
  onEditClick,
  totalRows,
  rowsByPage,
  currentPage,
  setCurrentPage,
  onSort,
  sortedColumn,
  isAscOrder,
  isLoading,
}) => {
  type renderFunction = ColumnOptions<IndicativePriceParsed>['renderAsElement'];

  const tableRef = useRef<HTMLDivElement>(null);
  const [tableHeight, setTableHeight] = useState(0);
  const [openFilters, setOpenFilters] = useState<boolean>(false);

  const { clearFilters } = useIndicativePricesFiltersContext();
  const renderEditPriceButton: renderFunction = (entry) => {
    return <EditButton onEditClick={() => onEditClick(entry.id)} />;
  };

  const indicativePriceTableColumn: ColumnOptions<IndicativePriceParsed>[] = [
    { accessor: 'traderName', header: 'Fornecedor', sortableColumn: true },
    { accessor: 'createdAt', header: 'Data', sortableColumn: true },
    { accessor: 'week', header: 'Semana', sortableColumn: true },
    { accessor: 'energyType', header: 'Energia', sortableColumn: true },
    { accessor: 'year', header: 'Ano', sortableColumn: true },
    { accessor: 'price', header: 'Preço (R$/MWh)', sortableColumn: true, noWrap: true },
    { accessor: null, header: ' ', renderAsElement: renderEditPriceButton },
  ];

  useEffect(() => {
    if (tableRef.current) {
      setTableHeight(tableRef.current.clientHeight);
    }
  }, [tableData]);

  return (
    <div className="px-6 pt-6 pb-1 rounded-3xl bg-brand-light-20">
      <div className="flex justify-between">
        <h1 className="mb-8 font-bold text-heading-small">{title}</h1>
        <div>
          <IndicativePricesFilterBtnAndTags setOpenFilters={setOpenFilters} />
        </div>
      </div>
      <div className="overflow-y-auto mb-1" style={{ height: tableHeight }}>
        {isLoading ? (
          <ListSkeleton height="2rem" />
        ) : (
          <div ref={tableRef}>
            <TableComponent<IndicativePriceParsed>
              data={tableData}
              tableColumns={indicativePriceTableColumn}
              onSort={onSort}
              sortedColumn={sortedColumn}
              isAscOrder={isAscOrder}
            />
          </div>
        )}
      </div>
      <InidicativePricesFilterDrawer
        actions={{ isOpen: openFilters, setIsOpen: setOpenFilters }}
        loading={isLoading}
        onClickAction={() => {
          setOpenFilters(false);
        }}
        onClickCancelAction={() => {
          setOpenFilters(false);
          clearFilters();
        }}
      />
      <PaginationComponent
        totalRows={totalRows}
        rowsByPage={rowsByPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default IndicativePricesTable;
