import { HeaderBreadCrumbProps } from '@components/atoms/header/bread-crumb';
import { CustomMessageProps } from '@components/molecules/custom-message';
import { VIEW_PROPOSAL_LIST_PATH } from '@routers/constants';

const customMessage: Record<'EMPTY' | 'UNAUTHORIZED', CustomMessageProps> = {
  EMPTY: {
    icon: {
      name: 'FaceFrownIcon',
      className: 'text-brand-gray-30 bg-white',
    },
    title: 'Ainda não há cotações',
    content:
      'No momento, não há compras ou vendas cadastradas. Assim que houver uma oferta, ela será exibida aqui. Você também pode cadastrar usando o botão acima.',
  },
  UNAUTHORIZED: {
    icon: {
      name: 'LockClosedIcon',
      className: 'text-palette-orange-30 bg-palette-orange-10',
    },
    title: 'Você não tem acesso a essa página!',
    content:
      'Infelizmente, o seu usuário não tem acesso a essa página. Solicite acesso e aguarde liberação para prosseguir ou volte à página inicial.',
  },
};

export default customMessage;

export const breadCrumb: HeaderBreadCrumbProps['navigationHistoryLinks'] = [
  { url: VIEW_PROPOSAL_LIST_PATH, label: 'Mercado de Curto Prazo' },
  { url: VIEW_PROPOSAL_LIST_PATH, label: 'Cotações' },
];
