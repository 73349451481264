import ButtonComponent from '@components/atoms/button';
import TagComponent from '@components/atoms/tag';
import { useIndicativePricesFiltersContext } from '@src/context/indicative-prices-filter';
import { format } from 'date-fns';

import React from 'react';

interface PriceFilterTagsProps {
  setOpenFilters: React.Dispatch<React.SetStateAction<boolean>>;
}

type FilterKeys = 'years' | 'traders' | 'weeks' | 'energyTypes';

const IndicativePriceFilterTags: React.FC<PriceFilterTagsProps> = ({ setOpenFilters }) => {
  const { priceFilters, setPriceFilters, labelMapping, setRefetchFilters } = useIndicativePricesFiltersContext();

  const renderListTagComponent = (list: string[], keyTarget: FilterKeys) => {
    return list.map((tagValue) => (
      <TagComponent
        key={tagValue}
        color="primaryLight"
        label={labelMapping[keyTarget]?.[tagValue] || tagValue}
        kind="remove"
        size="2"
        icon="XMarkIcon"
        onRemoveHandleAction={() => {
          const filteredList = handleRemoveFromFilter(list, tagValue);
          setPriceFilters((prev) => ({ ...prev, [keyTarget]: filteredList }));
          setRefetchFilters(true);
        }}
      />
    ));
  };

  const handleRemoveFromFilter = (list: string[], value: string) => list.filter((item) => item !== value);

  return (
    <div className="flex flex-col-reverse gap-2 md:flex-row md:ml-auto">
      <div className="flex flex-wrap gap-2 self-end md:self-center">
        {priceFilters.years.length > 0 && renderListTagComponent(priceFilters.years, 'years')}
        {priceFilters.traders.length > 0 && renderListTagComponent(priceFilters.traders, 'traders')}
        {priceFilters.weeks.length > 0 && renderListTagComponent(priceFilters.weeks, 'weeks')}
        {priceFilters.energyTypes.length > 0 && renderListTagComponent(priceFilters.energyTypes, 'energyTypes')}
        {priceFilters.startDate && (
          <TagComponent
            key={priceFilters.startDate}
            color="primaryLight"
            label={
              labelMapping.startDate ||
              (() => {
                const adjustedDate = new Date(priceFilters.startDate);
                adjustedDate.setHours(adjustedDate.getHours() + 3);
                return format(adjustedDate, 'dd/MM/yyyy');
              })()
            }
            kind="remove"
            size="2"
            icon="XMarkIcon"
            onRemoveHandleAction={() => {
              setPriceFilters((prev) => ({ ...prev, startDate: null }));
              setRefetchFilters(true);
            }}
          />
        )}
        {priceFilters.endDate && (
          <TagComponent
            key={priceFilters.endDate}
            color="primaryLight"
            label={
              labelMapping.startDate ||
              (() => {
                const adjustedDate = new Date(priceFilters.endDate);
                adjustedDate.setHours(adjustedDate.getHours() + 3);
                return format(adjustedDate, 'dd/MM/yyyy');
              })()
            }
            kind="remove"
            size="2"
            icon="XMarkIcon"
            onRemoveHandleAction={() => {
              setPriceFilters((prev) => ({ ...prev, endDate: null }));
              setRefetchFilters(true);
            }}
          />
        )}
      </div>
      <div className="ml-auto md:ml-0">
        <ButtonComponent
          label="Filtros"
          kind="secondary"
          icon="FunnelIcon"
          iconPosition="left"
          size="2"
          onClick={() => setOpenFilters((prev) => !prev)}
        />
      </div>
    </div>
  );
};

export default IndicativePriceFilterTags;
