import { EnergyTypeEnum } from '@utils/translators';

export type KeyEnergy =
  | EnergyTypeEnum.CONVENTIONAL
  | EnergyTypeEnum.INCENTIVIZED_50
  | EnergyTypeEnum.INCENTIVIZED_100
  | 'NONE';

export interface TusdPaymentOptionValue {
  value: 0 | 35 | 70 | 'NONE';
  label: string;
}
export type TusdPaymentOptionType = {
  [key in KeyEnergy]: TusdPaymentOptionValue;
};

export const paymentOptions = Array.from({ length: 6 }, (_, i) => ({
  value: i + 1,
  label: `${i + 1}º dia útil`,
}));

export const tusdPaymentOption: TusdPaymentOptionType = {
  [EnergyTypeEnum.CONVENTIONAL]: { value: 0, label: 'R$0,00 (padrão Convencional)' },
  [EnergyTypeEnum.INCENTIVIZED_50]: { value: 35, label: 'R$35,00 (padrão i5)' },
  [EnergyTypeEnum.INCENTIVIZED_100]: { value: 70, label: 'R$70,00 (padrão i100)' },
  NONE: { value: 'NONE', label: 'Escolher outro valor' },
};
