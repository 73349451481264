import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { transformToDate, formatDateFromString, $dayjs } from '@utils/dayjs';
import { EditMcpBidPayload } from '@hooks/edit-mcp-bid/types';
import { useEditMcpBid } from '@hooks/edit-mcp-bid/useEditMcpBid';

import ModalComponent from '@components/atoms/modal';
import ToastComponent from '@components/atoms/toast/toast';
import ModalFormComponent from '../modal-form';

interface ModalEditProposalPropos {
  initialValues: EditMcpBidPayload;
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onActionCallback?: () => void;
  onCancelCallback?: () => void;
}

const ModalEditProposal = ({
  initialValues,
  isOpen,
  setOpen,
  onActionCallback,
  onCancelCallback,
}: ModalEditProposalPropos) => {
  const [toastMessage, setToastMessage] = useState<string>('');

  const { editMcpBidFn, loading: loadingEditBid } = useEditMcpBid();

  const methods = useForm<EditMcpBidPayload>({
    defaultValues: { ...initialValues },
    mode: 'all',
  });

  useEffect(() => {
    if (isOpen) {
      methods.reset(initialValues);
    }
  }, [isOpen, methods]);

  const onSubmit = async (values: EditMcpBidPayload) => {
    setToastMessage('');
    try {
      const editMcpBidPayload = {
        id: values.id,
        spread: values.spread,
        deadline:
          typeof values.deadline === 'number'
            ? transformToDate(values.deadline)
            : formatDateFromString(values.deadline, 'DDMMYYYYHHmm', 'YYYY/MM/DD HH:mm:ss'),
      };

      const { data } = await editMcpBidFn({
        variables: { input: { ...editMcpBidPayload } },
      });

      if (data) {
        const {
          editMcpBid: { deadline, spread, id },
        } = data;

        methods.reset({ id, spread, deadline: $dayjs(deadline).format('DDMMYYYYHHmm') });

        setToastMessage('Proposta atualizada com sucesso!');
        setOpen(false);
      }
    } catch (e) {
      setToastMessage('Erro ao atualizar proposta!');
      throw new Error('Mutation error: ' + e);
    }
  };

  return (
    <>
      <ModalComponent
        isOpen={isOpen}
        setOpen={setOpen}
        modalIcon={false}
        title="Editar proposta"
        buttonsProps={[
          {
            label: 'Cancelar',
            kind: 'secondary',
            screen: 'desktop',
            size: '2',
            onClick: () => {
              setOpen(false);

              if (onCancelCallback) onCancelCallback();
            },
          },
          {
            label: 'Confirmar',
            type: 'submit',
            kind: 'primary',
            screen: 'desktop',
            size: '2',
            loading: loadingEditBid,
            onClick: () => {
              methods.handleSubmit(onSubmit)();

              if (onActionCallback) {
                onActionCallback();
              }
            },
          },
        ]}
      >
        <FormProvider {...methods}>
          <form className="flex flex-col gap-4">
            <ModalFormComponent />
          </form>
        </FormProvider>
      </ModalComponent>

      <ToastComponent label={toastMessage} type={toastMessage.length > 0} />
    </>
  );
};

export default ModalEditProposal;
