import React from 'react';

interface ChartXAxisProps {
  years: number[];
}

const ChartXAxis: React.FC<ChartXAxisProps> = ({ years }) => (
  <div className="flex absolute bottom-6 w-full pl-[18rem] gap-[10.5rem] text-caption-medium">
    {years.map((year) => (
      <div key={year} className="text-center">
        {year}
      </div>
    ))}
  </div>
);

export default ChartXAxis;
