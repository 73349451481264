import { useMutation } from '@apollo/client';

import { WINNER_BID } from './mutation';

export const useMcpWinnerBid = () => {
  const [winnerBidFn, { data, error, loading }] = useMutation(WINNER_BID);

  return {
    winnerBidFn,
    data,
    error,
    loading,
  };
};
