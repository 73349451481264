import { useQuery } from '@apollo/client';
import { GET_TRADER_FOR_USER, GetTraderForUserType, Trader } from './queries';

export const useUserTrader = (userId: string) => {
  const { data, loading, error } = useQuery<GetTraderForUserType>(GET_TRADER_FOR_USER, {
    variables: { userId: userId },
  });

  return {
    userTrader: (data?.getTraderForUser.trader as Trader) || {},
    loading,
    error,
  };
};
