import React from 'react';
import HeroIcon from '@components/atoms/hero-icon';

import style from './style.module.css';

const NoSubmarketAverageMessage: React.FC = () => {
  return (
    <div className={style.noAverageMessageContainer}>
      <div className={style.noAverageMessageIconContainer}>
        <HeroIcon className={style.noAverageMessageIcon} icon={'FaceFrownIcon'} />
      </div>
      <div className="flex flex-col max-w-[28rem]">
        <h2 className={style.noAverageMessageTitle}>Nenhum preço foi inserido</h2>
        <p className={style.noAverageMessageSubtitle}>
          Ajude-nos a manter os dados atualizados inserindo os preços para esse submercado.
        </p>
      </div>
    </div>
  );
};

export default NoSubmarketAverageMessage;
