import React from 'react';
import HeroIcon, { IconName } from '@src//components/atoms/hero-icon';
import { twMerge } from 'tailwind-merge';

interface NuggetIconsComponentProps {
  title?: string;
  iconName?: IconName;
  iconPath?: string;
  extraClassNames?: string;
}

const NuggetIconsComponent: React.FC<NuggetIconsComponentProps> = ({
  iconName,
  iconPath,
  title = '',
  extraClassNames = '',
}) => {
  const containerClassName = twMerge('flex items-center', extraClassNames);

  return (
    <>
      <div className={containerClassName}>
        {(iconPath || iconName) && (
          <>
            {iconPath ? (
              <img src={iconPath} width={16} height={16} alt={title} />
            ) : (
              iconName && <HeroIcon icon={iconName} extraClassNames={`w-4 h-4`} />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default NuggetIconsComponent;
