import React from 'react';

import HeroIcon from '@components/atoms/hero-icon';

export interface EditButtonProps {
    onClick: () => void;
}

const EditButton: React.FC<EditButtonProps> = ({ onClick }) => (
    <button onClick={onClick}>
        <HeroIcon icon="PencilSquareIcon" className="w-6 h-6 text-brand-gray-80 cursor-pointer" />
    </button>
);

export default EditButton;
