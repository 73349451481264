import React from 'react';

export interface TitleSectionProps {
  title: string;
  subtitle?: string;
}

const TitleSection: React.FC<TitleSectionProps> = ({ title, subtitle }) => {
  return (
    <div>
      <h1 className="text-heading-small font-bold">{title}</h1>
      {subtitle && <span className="text-paragraph-small text-brand-gray-80">{subtitle}</span>}
    </div>
  );
};

export default TitleSection;
