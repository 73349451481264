import { validateHour } from '@components/organisms/create-proposal-form/validation';
import { validateCompleteDate } from '@components/organisms/create-proposal-bid-form/validation';
import { Input } from '@components/atoms/form/input';
import FormEditModal from './modal-edit';

const ModalFormComponent = (): JSX.Element => {
  return (
    <>
      <FormEditModal title="Spread" description="A comissão da Clarke será incluída automaticamente.">
        <Input.Root>
          <Input.Label />
          <Input.NumericContent
            className="bg-brand-light-30 w-[18.75rem]"
            fieldName="spread"
            formatProps={{
              placeholder: 'R$',
              prefix: 'R$',
              allowNegative: false,
              fixedDecimalScale: true,
              decimalScale: 2,
            }}
            rules={{ required: { value: true, message: 'Campo obrigatório' } }}
          />
          <Input.Error fieldName="spread" />
        </Input.Root>
      </FormEditModal>
      <FormEditModal title="Validade">
        <Input.Root>
          <Input.Label />
          <Input.NumericMask
            className="bg-brand-light-30 w-[18.75rem]"
            fieldName="deadline"
            formatProps={{
              format: '##/##/#### - ##:##',
              placeholder: '00/00/0000 - 00:00',
              valueIsNumericString: true,
            }}
            rules={{
              validate: (value) => {
                const hourValidation = validateHour(value);
                const dateValidation = validateCompleteDate(value);

                if (typeof hourValidation !== 'boolean') return hourValidation;

                if (typeof dateValidation !== 'boolean') return dateValidation;

                return true;
              },
            }}
          />
          <Input.Error fieldName="deadline" />
        </Input.Root>
      </FormEditModal>
    </>
  );
};

export default ModalFormComponent;
