import { gql } from '@apollo/client';

export const GET_DISTINCT_FILTERS_FOR_INDICATIVE_PRICES = gql`
  query GetDistinctFilters($input: GetDistinctFiltersInput!) {
    getDistinctFilters(input: $input) {
      traders {
        id
        name
      }
      weeks
      years
    }
  }
`;
