import { useState, useEffect, useRef } from 'react';
import numeral from '@utils/numeral';
import { useNavigate } from 'react-router-dom';

import { TransactionTypeEnum } from '@utils/translators';
import { $dayjs } from '@utils/dayjs';

import { Bid, McpProposal } from '@hooks/get-mcp-proposal-by-id/types';
import { McpProposalType } from '@hooks/get-mcp-proposals/types';
import useProposalById from '@hooks/get-mcp-proposal-by-id/useProposalById';
import { useMcpDeleteProposal } from '@hooks/delete-mcp-proposal/useDeleteProposal';
import { NuggetInfo, dataBidList, mainInfo } from '@components/organisms/proposal-drawer-content/helper';
import ProposalDrawer from '@components/organisms/proposal-drawer-content';
import { NuggetInfoComponentProps } from '@components/atoms/nugget-info';
import { ButtonComponentProps } from '@components/atoms/button';
import Text from '@components/atoms/text';
import ModalComponent from '@components/atoms/modal';
import ToastComponent from '@components/atoms/toast/toast';
import AdminActionButtons from './admin-buttons-actions';

import { handleModalDescription } from './helper';
import { EDIT_PROPOSAL_PATH } from '@routers/constants';
import { useMcpProposalContext } from '@hooks/use-mcp-proposal';

interface DetailProposalItemProps {
  proposal: McpProposalType;
  displayAdminButtons: boolean;
  isAdmin: boolean;
}

const DetailProposalItem = ({ proposal, displayAdminButtons, isAdmin }: DetailProposalItemProps): JSX.Element => {
  const getToCreateBid = false;
  const { getProposal, rawData: proposalDrawerData, loading: loadingProposalDrawer } = useProposalById(getToCreateBid);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const navigate = useNavigate();

  const { deleteProposalFn, loading } = useMcpDeleteProposal();
  const { setDeletedProposalId } = useMcpProposalContext();

  const [openAdminProposalDrawer, setOpenAdminProposalDrawer] = useState<boolean>(false);
  const [bidsInfo, setBidsInfo] = useState<Record<string, NuggetInfoComponentProps[]>>({});
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');

  const emptyBids = proposal.mcpBids == null && proposal.expiredMcpBids == null;

  const deleteAndEditButtons: ButtonComponentProps[] = displayAdminButtons
    ? [
        {
          kind: 'iconSecondary',
          icon: 'PencilSquareIcon',
          iconPosition: 'right',
          label: 'Editar Cotação',
          size: '1',
          onClick: () => setOpenEditModal(!openEditModal),
        },
        {
          kind: 'iconTertiary',
          icon: 'TrashIcon',
          iconPosition: 'right',
          label: 'Excluir Cotação',
          size: '1',
          onClick: () => setOpenDeleteModal(!openDeleteModal),
        },
      ]
    : [];

  const proposalBUtton: ButtonComponentProps[] =
    proposal.mcpBids || proposal.expiredMcpBids
      ? [
          {
            kind: 'primary',
            label: 'Ver propostas enviadas',
            size: '1',
            onClick: () => setOpenAdminProposalDrawer(true),
          },
        ]
      : [];

  const handleDeleteProposal = async (mcpProposalId: string) => {
    setToastMessage('');
    try {
      const {
        data: { deleteMcpProposal },
      } = await deleteProposalFn({ variables: { mcpProposalId } });
      setOpenDeleteModal(false);
      setToastMessage('Cotação deletada com sucesso!');

      setTimeout(() => {
        setDeletedProposalId(deleteMcpProposal.id);
      }, 1000);
    } catch (error) {
      setToastMessage('Erro ao deletar cotação.');
      setOpenDeleteModal(false);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (openAdminProposalDrawer) getProposal({ variables: { id: proposal.id } });
  }, [openAdminProposalDrawer]);

  useEffect(() => {
    if (proposalDrawerData) {
      const nuggetBidInfoCollection = DrawerBidInfo(proposalDrawerData);
      const nuggetFormatted = transformMatrixToRecord(nuggetBidInfoCollection);
      setBidsInfo(nuggetFormatted);
    }
  }, [proposalDrawerData]);

  const DrawerBidInfo = (proposal: McpProposal) => {
    const bids = proposal.mcpBids;
    const nuggetBidInfoCollection: NuggetInfoComponentProps[][] = [];

    bids?.forEach((bid: Bid, idx: number) => {
      const bidList: NuggetInfoComponentProps[] = dataBidList(
        {
          deadline: $dayjs(bid.deadline).format('DD/MM/YYYY - HH:mm'),
          paymentDeadline: parseInt(bid.paymentDeadline),
          tusdRefund: numeral(bid.tusdRefund).format('$ 0,0.00'),
          id: bid.id,
          winnerAt: bid.winnerAt,
          traderName: bid.trader.name,
          score: bid.trader.score ?? null,
          position: idx + 1,
          spreadWithCommission: isAdmin
            ? numeral(bid.spread).format('$ 0,0.00').concat('/MWh')
            : numeral(bid.spreadWithCommission).format('$ 0,0.00'),
          commission:
            isAdmin && proposal.commissionType === 'PERCENTAGE'
              ? numeral(proposal.proposalCommission).format('%')
              : isAdmin && proposal.commissionType === 'REALS_PER_MEGAWATT'
                ? numeral(proposal.proposalCommission).format('$ 0,0.00').concat('/MWh')
                : '',
        },
        idx === 0,
      );
      nuggetBidInfoCollection.push(bidList);
    });
    return nuggetBidInfoCollection;
  };

  const transformMatrixToRecord = (matrix: NuggetInfo[][]): Record<string, NuggetInfoComponentProps[]> => {
    return matrix.reduce(
      (acc, currentArray) => {
        const key = currentArray[0].id;
        acc[key as string] = currentArray;
        return acc;
      },
      {} as Record<string, NuggetInfoComponentProps[]>,
    );
  };

  return (
    <>
      {emptyBids && proposal.status === 'ACTIVE' ? (
        <>
          <Text className="mt-4" size="text-xs">
            Essa cotação ainda não recebeu nenhuma proposta
          </Text>
          <AdminActionButtons buttons={deleteAndEditButtons} />
        </>
      ) : (
        <div>
          <AdminActionButtons buttons={[...proposalBUtton, ...deleteAndEditButtons]} />
        </div>
      )}
      <ProposalDrawer
        loading={loadingProposalDrawer}
        title={`Oferta de ${TransactionTypeEnum[proposal.transactionType as keyof typeof TransactionTypeEnum]} de energia`}
        content={{
          mainInfo: mainInfo(proposal),
          nuggetButtons: bidsInfo,
        }}
        isOpen={openAdminProposalDrawer}
        setOpen={setOpenAdminProposalDrawer}
        isAdmin={isAdmin}
      />
      <ModalComponent
        isOpen={openDeleteModal || openEditModal}
        setOpen={openDeleteModal ? setOpenDeleteModal : setOpenEditModal}
        modalIcon={true}
        title={`Tem certeza que deseja ${openDeleteModal ? 'excluir' : 'editar'} essa cotação?`}
        icon={openDeleteModal ? 'TrashIcon' : 'PencilSquareIcon'}
        description={handleModalDescription(openDeleteModal)}
        iconColor={openDeleteModal ? 'red' : 'yellow'}
        buttonsProps={[
          {
            kind: 'secondary',
            label: 'Não, manter cotação',
            size: '1',
            onClick: () => (openDeleteModal ? setOpenDeleteModal(false) : setOpenEditModal(false)),
          },
          openDeleteModal
            ? {
                size: '1',
                kind: 'tertiary',
                label: 'Sim, excluir',
                loading,
                onClick: () => handleDeleteProposal(proposal.id),
              }
            : {
                size: '1',
                kind: 'primary',
                label: 'Sim, editar',
                loading,
                onClick: () => navigate(`${EDIT_PROPOSAL_PATH}/${proposal.id}`),
              },
        ]}
      />
      <ToastComponent type={toastMessage.length > 0 ? true : false} label={toastMessage} />
    </>
  );
};

export default DetailProposalItem;
