import { useMutation } from '@apollo/client';

import { DELETE_BID } from './mutation';

export const useMcpDeleteBid = () => {
  const [deleteBidFn, { data, error, loading }] = useMutation(DELETE_BID);

  return {
    deleteBidFn,
    data,
    error,
    loading,
  };
};
