import React, { useState } from 'react';
import { createContext } from 'use-context-selector';

import { McpProposalType } from '@hooks/get-mcp-proposals/types';

export type McpProposalContextType = {
  proposal: McpProposalType | null;
  setProposal: React.Dispatch<React.SetStateAction<McpProposalType | null>>;
  deletedProposalId: string | null;
  setDeletedProposalId: React.Dispatch<React.SetStateAction<string | null>>;
};

export const McpProposalContext = createContext({} as McpProposalContextType);

interface Provider {
  children: React.ReactNode;
}

const McpProposalProvider: React.FC<Provider> = ({ children }: Provider) => {
  const [proposal, setProposal] = useState<McpProposalType | null>(null);
  const [deletedProposalId, setDeletedProposalId] = useState<string | null>(null);

  return (
    <McpProposalContext.Provider
      value={{
        proposal,
        setProposal,
        setDeletedProposalId,
        deletedProposalId,
      }}
    >
      {children}
    </McpProposalContext.Provider>
  );
};

export default McpProposalProvider;
