import React from 'react';
import dayjs from 'dayjs';
import numeral from 'numeral';
import { twMerge } from 'tailwind-merge';

import { $dayjs } from '@utils/dayjs';

import TrophyFirstPlaceIcon from '@assets/medal-gold.svg';
import SilverMedalSecondPlaceIcon from '@assets/medal-silver.svg';
import BronzeMedalThirdPlaceIcon from '@assets/medal-bronze.svg';

import TagComponent from '@components/atoms/tag';
import ButtonComponent from '@components/atoms/button';
import Text from '@components/atoms/text';
import { Status } from '@components/organisms/proposal-item/helper';
import EditButton from './edit-button';
import BestPrice from './best-price';

import style from './style.module.css';

export interface RankingInfoComponentProps {
  id: string;
  supplier: string;
  price: number;
  deadline: string;
  status?: Status;
  onClick?: (value?: any) => void;
  icon?: string;
  winnerTag?: boolean;
  applyWinnerTagStyle?: boolean;
}

export interface RankingComponentCollectionProps {
  rankingInfos: RankingInfoComponentProps[];
  onClick?: (value?: any) => void;
}

export const RankingComponentItem: React.FC<RankingInfoComponentProps> = ({
  icon,
  supplier,
  price,
  deadline,
  status,
  id,
  onClick,
  winnerTag,
  applyWinnerTagStyle,
}) => {
  const iconstyle: {
    [key: string]: { path: string; name: string; color: string };
  } = {
    iconGold: {
      path: TrophyFirstPlaceIcon,
      name: 'Medalha de Ouro',
      color: 'bg-palette-yellow-20',
    },
    iconSilver: {
      path: SilverMedalSecondPlaceIcon,
      name: 'Medalha de Prata',
      color: 'bg-brand-gray-20',
    },
    iconBronze: {
      path: BronzeMedalThirdPlaceIcon,
      name: 'Medalha de Bronze',
      color: 'bg-palette-yellow-50',
    },
  };

  const customIconPath: string | undefined = icon ? iconstyle[icon].path : 'opacity-0';
  const customIconName: string | undefined = icon ? iconstyle[icon].name : 'opacity-0';
  const customIconColor: string | undefined = icon ? iconstyle[icon].color : 'opacity-0';
  const mergedClasses = twMerge('flex items-center rounded-pill p-[6px]', customIconColor);

  const handleEditOnClick = () => {
    const bidSelected = {
      id,
      deadline: $dayjs(deadline).format('DDMMYYYYHHmm'),
      spread: price,
    };
    if (onClick) onClick(bidSelected);
  };

  const handleIconContent = () => {
    if (status === 'BEST PRICE' || status === 'BEST PRICE EDITION') {
      return <BestPrice status={status} onClick={() => handleEditOnClick()} />;
    }

    if (status === 'EDITION') {
      return <EditButton onClick={() => handleEditOnClick()} />;
    }

    if (status === 'EXPIRED') {
      return <ButtonComponent kind="primary" label="Nova proposta" size="1" onClick={onClick} />;
    }

    if (winnerTag) {
      return <TagComponent label="Proposta vencedora" color="primaryLight" size="1" />;
    }

    return null;
  };

  return (
    <div className={style.RankingRoot}>
      <div className={style.RankingItem} role="region" aria-label="Ranking">
        <div className={supplier ? style.RankingItemContentLeft : ''}>
          <div className={supplier ? style.RankingItemContentLeft_IconsAndText : ''}>
            {icon ? (
              <div className={mergedClasses}>
                <img src={customIconPath ?? ''} alt={customIconName || ''} className="min-w-3 min-h-3" />
              </div>
            ) : (
              <span className="min-w-3 min-h-3"></span>
            )}
            <Text
              className={twMerge(
                style.SupplierText,
                supplier === 'Seu Preço'
                  ? style.RankingItemContent_SupplierTextBold
                  : style.RankingItemContent_SupplierTextRegular,
              )}
            >
              {supplier}
            </Text>
          </div>

          <div className={style.RankingItemContentLeftTitles}>
            <Text className={style.RankingPLDTitle}>
              <Text className="text-paragraph-medium font-regular text-brand-gray-80">
                {'PLD + ' + numeral(price).format('$ 0,0.00').concat('/MWh')}
              </Text>
            </Text>
            <div className={style.SupplierTime}>
              <Text>{dayjs(deadline).format('DD/MM - HH:mm[h]')}</Text>
            </div>
          </div>
        </div>
        <div className={style.RankingItemContentRight}>
          <div
            className={twMerge(style.RankingItemContentRightIcons, applyWinnerTagStyle ? 'md:min-w-32' : 'min-w-10')}
          >
            {handleIconContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

const RankingComponent: React.FC<RankingComponentCollectionProps> = ({ rankingInfos, onClick }) => {
  const hasWinnerTag = rankingInfos.some(({ winnerTag }) => winnerTag);
  return (
    <>
      {rankingInfos?.map((item) => (
        <RankingComponentItem
          key={item.id}
          id={item.id}
          icon={item.icon as 'iconGold' | 'iconSilver' | 'iconBronze' | undefined}
          supplier={item.supplier}
          price={item.price as number}
          deadline={item.deadline}
          status={item.status}
          winnerTag={item.winnerTag}
          applyWinnerTagStyle={hasWinnerTag}
          onClick={onClick}
        />
      ))}
    </>
  );
};

export default RankingComponent;
