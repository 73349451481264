import { gql, useLazyQuery } from '@apollo/client';
import React from 'react';
import { Navigate } from 'react-router-dom';

import useAuth from '@hooks/auth';

import { DOES_NOT_EXIST_PATH } from './constants';
import { ServerValidationError } from '@utils/errors';

interface TraderInfoContext {
  traderData: TraderInfo;
  loading: boolean;
  isTraderLoaded: boolean;
  setTraderData: React.Dispatch<React.SetStateAction<TraderInfo>>;
}

export const traderInfoContext = React.createContext<TraderInfoContext>({} as TraderInfoContext);

const GET_MY_TRADER_DATA = gql`
  query GetTraderForUser($userId: ID!) {
    getTraderForUser(userId: $userId) {
      error
      trader {
        id
      }
    }
  }
`;

interface TraderInfo {
  id: string;
}

const EMPTY_TRADER_INFO: TraderInfo = { id: '' };

export interface TraderInfoGraphQLData {
  getTraderForUser: {
    error?: string;
    trader?: {
      id: string;
    };
  };
}

export function useTrader(): TraderInfoContext {
  return React.useContext(traderInfoContext);
}

export const TraderProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();

  const [isTraderLoaded, setIsTraderLoaded] = React.useState<boolean>(false);
  const [traderData, setTraderData] = React.useState<TraderInfo>({ ...EMPTY_TRADER_INFO });

  const [queryFunction, { loading }] = useLazyQuery<TraderInfoGraphQLData, { userId: string }>(GET_MY_TRADER_DATA);

  const getMyTraderData = async (userId: string): Promise<void> => {
    await queryFunction({
      variables: { userId },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (
          data.getTraderForUser === undefined ||
          data.getTraderForUser.error ||
          data.getTraderForUser.trader === undefined
        )
          throw new ServerValidationError('user not associated with trader');

        const trader = data.getTraderForUser.trader;
        setTraderData({ id: trader.id });
        setIsTraderLoaded(true);
      },
      onError: () => {
        setTraderData({ ...EMPTY_TRADER_INFO });
        setIsTraderLoaded(false);
      },
    });
  };

  React.useEffect(() => {
    if (user.id) {
      getMyTraderData(user.id);
    }
  }, [user.id]);

  const initialValues = { traderData, loading, isTraderLoaded, fetchTraderData: getMyTraderData, setTraderData };

  return <traderInfoContext.Provider value={initialValues}>{children}</traderInfoContext.Provider>;
};

interface TraderRouteProps {
  children: JSX.Element;
}

/**
 * Elements under this route will have trader data available using the useTrader hook.
 * */
const TraderRoute: React.FC<TraderRouteProps> = ({ children }) => {
  const {
    user,
    authStatus: { isAuthenticated },
  } = useAuth();

  if (isAuthenticated && !user.roles?.includes('supplier') && !user.roles?.includes('admin'))
    return <Navigate to={DOES_NOT_EXIST_PATH} />;

  return children;
};

export default TraderRoute;
