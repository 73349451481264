/* eslint-disable no-console */
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import domtoimage from 'dom-to-image';
import { INDICATIVE_PRICES_DASHBOARD, SEND_INDICATIVE_PRICES } from '@routers/constants';
import useAuth from '@hooks/auth';
import { Header } from '@components/atoms/header';
import { Layout } from '@components/layout';
import IndicativePricesDashboard from '@components/organisms/indicative-prices-dashboard';
import { LAPTOP_BREAKPOINT } from '@utils/constants/breakpoints';
import CustomMessage from '@components/molecules/custom-message';

const IndicativePricesDashboardPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const exportRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const isAdmin = user.roles?.includes('admin') ?? false;
  const isTrader = user.roles?.includes('supplier') ?? false;
  const isSeller = user.roles?.includes('seller') ?? false;

  const userNotAuthorized = !isAdmin && !isTrader && !isSeller;

  const handleExportClick = () => {
    domtoimage
      .toPng(exportRef.current)
      .then((dataUrl: string) => {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = `dashboard-precos-indicativos.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error: string) => {
        console.error('Error capturing component as image:', error);
      });
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Layout.HeaderContainer>
        <Header.BreadCrumb
          navigationHistoryLinks={[
            { url: '/', label: 'Home' },
            { url: INDICATIVE_PRICES_DASHBOARD, label: 'Preços Indicativos' },
          ]}
        />
        <Header.Title title="Dashboard de preços indicativos - Atacadista" />

        {isTrader && windowWidth >= LAPTOP_BREAKPOINT && (
          <Header.Buttons
            buttons={[
              {
                type: 'button',
                kind: 'secondary',
                screen: 'desktop',
                label: 'Exportar',
                icon: 'InboxArrowDownIcon',
                iconPosition: 'left',
                size: '3',
                onClick: handleExportClick,
              },
              {
                type: 'button',
                kind: 'primary',
                screen: 'desktop',
                label: 'Enviar Preços',
                icon: 'PlusIcon',
                iconPosition: 'left',
                size: '3',
                onClick: () => navigate(SEND_INDICATIVE_PRICES),
              },
            ]}
          />
        )}
      </Layout.HeaderContainer>
      <Layout.Content ref={exportRef}>
        {userNotAuthorized ? (
          <CustomMessage
            icon={{
              name: 'LockClosedIcon',
              className: 'text-palette-orange-30 bg-palette-orange-10',
            }}
            title="Você não tem acesso a essa página!"
            content="Infelizmente, o seu usuário não tem acesso a essa página. Solicite acesso e aguarde liberação para prosseguir ou volte à página inicial."
          />
        ) : (
          <IndicativePricesDashboard />
        )}
      </Layout.Content>
    </>
  );
};

export default IndicativePricesDashboardPage;
