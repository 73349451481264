import React from 'react';

interface ChartLegendProps {
  colors: string[];
  dates: string[];
  barsCount: number;
}

const ChartLegend: React.FC<ChartLegendProps> = ({ colors, dates, barsCount }) => (
  <div className="flex absolute -bottom-0 justify-center space-x-8 pl-[25rem] text-caption-small text-nowrap md:text-caption-medium">
    <div className="flex items-center space-x-2">
      {colors.slice(0, barsCount).map((color, index) => (
        <div key={index} className="flex items-center space-x-2">
          <div className="w-6 h-3 rounded-full" style={{ backgroundColor: color }}></div>
          <span>{dates[index]}</span>
        </div>
      ))}
    </div>
    <div className="flex items-center space-x-1">
      {[...Array(5)].map((_, i) => (
        <div key={i} className="w-1 h-1 bg-[#FFD97A]"></div>
      ))}
      <span>Ref. mínima</span>
    </div>
    <div className="flex items-center space-x-1">
      {[...Array(5)].map((_, i) => (
        <div key={i} className="w-1 h-1 bg-[#AD7C00]"></div>
      ))}
      <span>Ref. máxima</span>
    </div>
  </div>
);

export default ChartLegend;
