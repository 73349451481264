import React, { useState } from 'react';

import { useMcpDeleteBid } from '@hooks/delete-mcp-bid/useDeleteBid';

import ToastComponent from '@components/atoms/toast/toast';
import ModalComponent from '@components/atoms/modal';
import Text from '@components/atoms/text';
import { useMcpProposalContext } from '@hooks/use-mcp-proposal';

type Actions = {
  setSuccessAction: React.Dispatch<React.SetStateAction<boolean>>;
  setRefuseBid: React.Dispatch<React.SetStateAction<boolean>>;
  setTargetBid: React.Dispatch<React.SetStateAction<string>>;
};
interface RefuseBidModalProps {
  refuseBid: boolean;
  actions: Actions;
  targetId: string;
}

const RefuseBidModal = ({ actions, refuseBid, targetId }: RefuseBidModalProps): JSX.Element => {
  const { setProposal } = useMcpProposalContext();
  const [toastMessage, setToastMessage] = useState<string>('');

  const { deleteBidFn, loading } = useMcpDeleteBid();

  const { setRefuseBid, setSuccessAction, setTargetBid } = actions;

  return (
    <>
      <ModalComponent
        isOpen={refuseBid}
        setOpen={setRefuseBid}
        modalIcon={true}
        icon="TrashIcon"
        title="Tem certeza que deseja excluir essa proposta?"
        buttonsProps={[
          {
            label: 'Não, manter proposta',
            kind: 'secondary',
            screen: 'desktop',
            size: '2',
            onClick: () => setRefuseBid(false),
          },
          {
            label: 'Sim, excluir',
            type: 'submit',
            kind: 'tertiary',
            screen: 'desktop',
            size: '2',
            loading: loading,
            onClick: async () => {
              setToastMessage('');
              try {
                const {
                  data: {
                    deleteMcpBid: { id, mcpProposal },
                  },
                } = await deleteBidFn({ variables: { id: targetId } });
                setToastMessage('Proposta deletada com sucesso!');
                setProposal(mcpProposal);

                setRefuseBid(false);
                setTargetBid(id);
                setSuccessAction(true);
              } catch (error) {
                setToastMessage('Erro ao deletar proposta.');
                setRefuseBid(false);
                setSuccessAction(false);
                setTargetBid('');
                throw new Error(`Error refuse proposal: ${error}`);
              }
            },
          },
        ]}
      >
        <Text className="flex flex-wrap p-2">
          Essa ação não poderá ser revertida e irá excluir definitivamente a proposta do sistema.
        </Text>
      </ModalComponent>
      <ToastComponent type={toastMessage.length > 0 ? true : false} label={toastMessage} swipeDirection="left" />
    </>
  );
};

export default RefuseBidModal;
