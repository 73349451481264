import { useState } from 'react';

interface CopiedData {
  [key: string]: Record<string, any>;
}

const useCopyPasteData = () => {
  const [copiedData, setCopiedData] = useState<CopiedData>({});

  const handleCopyData = (data: Record<string, any>, energyType: string, submarketType: string) => {
    const dataToCopy = Object.keys(data)
      .filter((key) => key.startsWith(`${energyType}-${submarketType}`))
      .reduce((acc: Record<string, any>, key) => {
        acc[key] = data[key];
        return acc;
      }, {});

    setCopiedData({ [`${energyType}-${submarketType}`]: dataToCopy });
  };

  const handlePasteData = (setValue: any, destEnergyType: string, destSubmarketType: string) => {
    const dataToPaste = Object.values(copiedData).reduce(
      (acc, data) => {
        Object.keys(data).forEach((key) => {
          const newKey = key.replace(/^[^-]+-[^-]+/, `${destEnergyType}-${destSubmarketType}`);
          acc[newKey] = data[key];
        });
        return acc;
      },
      {} as Record<string, any>,
    );

    Object.keys(dataToPaste).forEach((key) => {
      setValue(key, dataToPaste[key]);
    });
  };

  return {
    handleCopyData,
    handlePasteData,
  };
};

export default useCopyPasteData;
