import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { INDICATIVE_PRICES_DASHBOARD } from '@routers/constants';
import { EnergyType, SubmarketType } from '@hooks/indicative-prices/types';
import useCopyPasteData from '@hooks/copy-paste-data/use-copy-paste-data';
import ModalComponent from '@components/atoms/modal';
import IndicativePricesByEnergyTypeSection from './energy-type-section';
import { initialSubmarketCheckboxStates, SubmarketCheckboxStates } from './helper';

type CheckboxStates = {
  [key: number]: boolean;
};

interface StepsProps {
  id: number;
  title: string;
  description: string;
  energyType: EnergyType;
  progressValue: number;
}

const CreateIndicativePricesForm: React.FC = () => {
  const methods = useForm();
  const navigate = useNavigate();
  const { handleCopyData, handlePasteData } = useCopyPasteData();
  const [step, setStep] = useState<number>(1);
  const [progress, setProgress] = useState<number>(0);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [checkboxStates, setCheckboxStates] = useState<CheckboxStates>({
    1: false,
    2: false,
    3: false,
  });
  const [submarketCheckboxStates, setSubmarketCheckboxStates] =
    useState<SubmarketCheckboxStates>(initialSubmarketCheckboxStates);

  const handleSendPrices = () => {
    setProgress(100);
    setOpenConfirmationModal(true);
  };

  const onCloseModal = () => {
    setProgress(66);
    setOpenConfirmationModal(false);
  };

  const setProgressValue = (currentStep: number) => {
    let progressValue;
    switch (currentStep) {
      case 1:
        progressValue = 0;
        break;
      case 2:
        progressValue = 33;
        break;
      case 3:
        progressValue = 66;
        break;
      default:
        progressValue = 0;
    }
    setProgress(progressValue);
  };

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const handleNavigateToDashboardClick = () => {
    navigate(INDICATIVE_PRICES_DASHBOARD);
  };

  const handleSendNewPricesClick = () => {
    setOpenConfirmationModal(false);
    setOpenErrorModal(false);
    methods.reset();
    setStep(1);
    setCheckboxStates({
      1: false,
      2: false,
      3: false,
    });
    setSubmarketCheckboxStates(initialSubmarketCheckboxStates);
  };

  const areAllCheckboxesChecked = () => {
    return checkboxStates[1] && checkboxStates[2] && checkboxStates[3];
  };

  const handleSubmarketCheckboxChange = (energyType: EnergyType, submarketType: SubmarketType, checked: boolean) => {
    setSubmarketCheckboxStates((prev) => ({
      ...prev,
      [energyType]: {
        ...prev[energyType],
        [submarketType]: checked,
      },
    }));
  };

  const steps: StepsProps[] = [
    {
      id: 1,
      title: 'Energia 50% Incentivada',
      description: 'Preços de energia 50% incentivada conforme o período neste submercado.',
      energyType: EnergyType.INCENTIVIZED_50,
      progressValue: 0,
    },
    {
      id: 2,
      title: 'Energia 100% Incentivada',
      description: 'Preços de energia 100% incentivada conforme o período neste submercado.',
      energyType: EnergyType.INCENTIVIZED_100,
      progressValue: 33,
    },
    {
      id: 3,
      title: 'Energia Convencional',
      description: 'Preços de energia convencional conforme o período neste submercado.',
      energyType: EnergyType.CONVENTIONAL,
      progressValue: progress,
    },
  ];

  useEffect(() => {
    setProgressValue(step);
  }, [step]);

  return (
    <div className="flex flex-col py-4 px-2 w-vw">
      <FormProvider {...methods}>
        {steps.map(
          (formStepProps, idx) =>
            step === formStepProps.id && (
              <IndicativePricesByEnergyTypeSection
                key={idx}
                {...formStepProps}
                step={step}
                nextStep={nextStep}
                prevStep={prevStep}
                onFormSuccess={handleSendPrices}
                isChecked={checkboxStates[formStepProps.id]}
                setIsChecked={(checked) => setCheckboxStates({ ...checkboxStates, [formStepProps.id]: checked })}
                areAllCheckboxesChecked={areAllCheckboxesChecked}
                setOpenErrorModal={setOpenErrorModal}
                handleCopyData={handleCopyData}
                handlePasteData={handlePasteData}
                submarketCheckboxStates={submarketCheckboxStates[formStepProps.energyType]}
                onSubmarketCheckboxChange={handleSubmarketCheckboxChange}
              />
            ),
        )}
        {openConfirmationModal && (
          <ModalComponent
            title="Preços Enviados"
            description="Agradecemos o envio dos preços indicativos. Em caso de dúvidas, entre em contato com nosso time."
            modalIcon={true}
            icon="CheckBadgeIcon"
            isOpen={openConfirmationModal}
            setOpen={onCloseModal}
            buttonsProps={[
              {
                label: 'Enviar outros preços',
                kind: 'secondary',
                screen: 'desktop',
                size: '2',
                onClick: handleSendNewPricesClick,
              },
              {
                label: 'Voltar para home',
                kind: 'primary',
                screen: 'desktop',
                size: '2',
                onClick: handleNavigateToDashboardClick,
              },
            ]}
          />
        )}
        {openErrorModal && (
          <ModalComponent
            className="w-[26rem]"
            title="Você não preencheu nenhum preço"
            description="Para finalizar, você precisa enviar os valores de pelo menos um tipo de energia."
            modalIcon={true}
            icon="ExclamationTriangleIcon"
            iconColor="yellow"
            isOpen={openErrorModal}
            setOpen={setOpenErrorModal}
            buttonsProps={[
              {
                label: 'Cancelar',
                kind: 'secondary',
                screen: 'desktop',
                size: '2',
                onClick: () => setOpenErrorModal(false),
              },
              {
                label: 'Voltar e enviar preços',
                kind: 'primary',
                screen: 'desktop',
                size: '2',
                onClick: handleSendNewPricesClick,
              },
            ]}
          />
        )}
      </FormProvider>
    </div>
  );
};

export default CreateIndicativePricesForm;
