import { gql } from '@apollo/client';

export const WINNER_BID = gql`
  mutation SetWinningMcpBid($mcpProposalId: ID!, $winnerMcpBidId: ID!) {
    setWinningMcpBid(mcpProposalId: $mcpProposalId, winnerMcpBidId: $winnerMcpBidId) {
      id
      mcpProposal {
        id
        volume
        transactionType
        energyType
        deadline
        period
        status
        submarket
        group {
          name
        }
        mcpBids {
          id
          trader {
            id
            name
            score
          }
          spread
          bestOffer
          deadline
          tusdRefund
          paymentDeadline
          winnerAt
        }
      }
    }
  }
`;
