import React, { useState } from 'react';

import config from '@config';
import useAuth from '@hooks/auth';
import { useUserTrader } from '@hooks/get-user-trader/useUserTrader';

import { Layout } from '@components/layout';
import CreateProposalForm from '@components/organisms/create-proposal-form';

import { breadCrumb, headerTitleTranslate } from './helper';

const CreateProposalPage: React.FC = () => {
  const [headerTitle, setHeaderTitle] = useState<'PURCHASE' | 'SALE'>('SALE');

  const { user } = useAuth();
  const { userTrader } = useUserTrader(user.id);

  const isAdmin = user.roles?.includes('admin');
  const traderId = isAdmin ? config.CLARKE_TRADER_ID : userTrader?.id;

  return (
    <Layout.Content
      header={{
        title: `Cotação de ${headerTitleTranslate[headerTitle]}`,
        breadCrumbPaths: breadCrumb,
      }}
    >
      <CreateProposalForm traderId={traderId} isAdmin={!!isAdmin} setHeaderTitle={setHeaderTitle} />
    </Layout.Content>
  );
};

export default CreateProposalPage;
