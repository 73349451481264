import React from 'react';
import Skeleton from 'react-loading-skeleton';

import {
  validateCompleteDate,
  validateHour,
  validateMonthYear,
} from '@components/organisms/create-proposal-form/validation';
import { Input } from '@components/atoms/form/input';
import { InputSelect } from '@components/atoms/form/select';
import FormSection from '../form';

const ProposalEnergySection: React.FC<{ loading: boolean }> = ({ loading }) => {
  const energyTypeOptions = [
    { value: 'CONVENTIONAL', label: 'Convencional' },
    { value: 'INCENTIVIZED_50', label: 'Incentivada 50% (I5)' },
    { value: 'INCENTIVIZED_100', label: 'Incentivada 100% (I100)' },
  ];
  const submarketOptions = [
    { value: 'SOUTHEAST', label: 'Sudeste/Centro-Oeste' },
    { value: 'SOUTH', label: 'Sul' },
    { value: 'NORTHEAST', label: 'Nordeste' },
    { value: 'NORTH', label: 'Norte' },
  ];

  const volumeFormat = {
    placeholder: '653,566',
    allowNegative: false,
    fixedDecimalScale: true,
    decimalScale: 3,
  };

  const supplyFormat = {
    placeholder: 'MM/AAAA',
    format: '##/####',
    mask: '_',
    valueIsNumericString: true,
  };

  const deadlineFormat = {
    placeholder: 'DD/MM/AAAA - HH:MM',
    format: '##/##/#### - ##:##',
    mask: '_',
    valueIsNumericString: true,
  };

  return (
    <FormSection title="Proposta de Energia" description="Dados sobre as condições de contratação de energia.">
      {loading ? (
        <Skeleton height="7rem" className="md:w-[19.5rem] lg:w-[33.3rem] xl:w-[47rem] 2xl:w-[61rem]" />
      ) : (
        <>
          <InputSelect.Root>
            <InputSelect.Label content="Tipo de Energia" className="font-normal text-paragraph-medium" required />
            <InputSelect.Input
              options={energyTypeOptions}
              fieldName="energyType"
              rules={{ required: { value: true, message: 'Campo obrigatório' } }}
            />
            <Input.Error fieldName="energyType" />
          </InputSelect.Root>

          <Input.Root>
            <Input.Label content="Volume (MWh/mês)" className="font-normal text-paragraph-medium" required />
            <Input.NumericContent
              fieldName="volume"
              formatProps={volumeFormat}
              rules={{
                required: { value: true, message: 'Campo obrigatório' },
                validate: (value) => {
                  if (value == 0) return 'Valor deve ser maior que zero';

                  return true;
                },
              }}
            />
            <Input.Error fieldName="volume" />
          </Input.Root>

          <Input.Root>
            <Input.Label content="Período de fornecimento" className="font-normal text-paragraph-medium" required />
            <Input.NumericMask
              fieldName="supply"
              formatProps={supplyFormat}
              rules={{
                required: { value: true, message: 'Campo obrigatório' },
                validate: (value) => validateMonthYear(value),
              }}
            />

            <Input.Error fieldName="supply" />
          </Input.Root>

          <InputSelect.Root>
            <InputSelect.Label content="Submercado" required />
            <InputSelect.Input
              options={submarketOptions}
              fieldName="submarket"
              rules={{ required: { value: true, message: 'Campo obrigatório' } }}
            />
            <Input.Error fieldName="submarket" />
          </InputSelect.Root>

          <Input.Root>
            <Input.Label content="Prazo de envio" className="font-normal text-paragraph-medium" required />
            <Input.NumericMask
              fieldName="deadline"
              formatProps={deadlineFormat}
              rules={{
                required: { value: true, message: 'Campo obrigatório' },
                validate: (value) => {
                  const hourValidation = validateHour(value);
                  const dateValidation = validateCompleteDate(value);

                  if (typeof hourValidation !== 'boolean') return hourValidation;

                  if (typeof dateValidation !== 'boolean') return dateValidation;

                  return true;
                },
              }}
            />
            <Input.Error fieldName="deadline" />
          </Input.Root>
        </>
      )}
    </FormSection>
  );
};

export default ProposalEnergySection;
