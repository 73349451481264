import { Badge, Theme } from '@radix-ui/themes';
import { twMerge } from 'tailwind-merge';
import HeroIcon, { IconName } from '@components/atoms/hero-icon';

export type TagColor =
  | 'default'
  | 'primaryLight'
  | 'orangeLight'
  | 'yellowLight'
  | 'grayLight'
  | 'primary'
  | 'gray'
  | 'secondary'
  | 'secondaryLight'
  | 'ghost';

type CustomStyle = {
  [key in TagColor]: React.CSSProperties;
};

export interface TagComponentProps {
  color: TagColor;
  kind?: 'icon' | 'none' | 'remove';
  label?: string;
  headerLabel?: string;
  icon?: IconName;
  customAttrs?: any;
  size?: '1' | '2' | '3';
  className?: string;
  onRemoveHandleAction?: () => void;
}

const customStyle: Partial<CustomStyle> = {
  grayLight: { boxShadow: 'inset 0 0 0 1px #C5C5C5' },
  ghost: { border: '1px solid #E8E8E8' },
};

const ColorClasses = {
  default: twMerge('text-brand-gray-50', 'bg-brand-gray-10'),
  primaryLight: twMerge('text-brand-primary-70', 'bg-brand-primary-10'),
  orangeLight: twMerge('text-palette-orange-30', 'bg-palette-orange-10'),
  yellowLight: twMerge('text-palette-yellow-30', 'bg-palette-yellow-10'),
  grayLight: twMerge('text-brand-gray-20'),
  secondaryLight: twMerge('text-brand-secondary-0', 'bg-brand-secondary-10'),
  primary: twMerge('text-brand-light-10', 'bg-brand-primary-0'),
  gray: twMerge('text-brand-light-10', 'bg-brand-gray-30'),
  secondary: twMerge('text-brand-light-10', 'bg-brand-secondary-0'),
  ghost: 'text-brand-gray-20',
};

const BadgeFonts = {
  mobile: twMerge('text-caption-medium', 'font-regular', 'text-[10px]'),
  desktop: twMerge('text-paragraph-small', 'font-regular', 'text-[12px]'),
};

const TagComponent = ({
  color,
  className,
  customAttrs,
  headerLabel,
  icon,
  kind,
  label,
  onRemoveHandleAction,
  size = '2',
}: TagComponentProps) => {
  const colorStyles = ColorClasses[color];
  const fontStyles = size === '1' ? BadgeFonts.mobile : BadgeFonts.desktop;

  const badgeClasses = twMerge(
    'flex justify-center items-center rounded-pill gap-1 py-1 px-2',
    colorStyles,
    fontStyles,
    className,
  );

  const badgeHeaderStyle = 'text-caption-small font-light text-brand-gray-80 opacity-90';

  return (
    <Theme>
      <div className="flex flex-col items-start">
        {headerLabel && <span className={badgeHeaderStyle}>{headerLabel}</span>}

        <Badge size={size} className={badgeClasses} style={customStyle[color]} {...customAttrs}>
          {kind === 'icon' && (
            <HeroIcon
              icon={icon as IconName}
              extraClassNames={twMerge('place-self-center', 'w-[18px]', 'h-[18px]', colorStyles)}
            />
          )}
          <span className={colorStyles}>{label}</span>
          {kind === 'remove' && (
            <HeroIcon
              icon={icon as IconName}
              extraClassNames={twMerge('place-self-center', 'w-[18px]', 'h-[18px] cursor-pointer', colorStyles)}
              onClick={onRemoveHandleAction}
            />
          )}
        </Badge>
      </div>
    </Theme>
  );
};

export default TagComponent;
