import { gql } from '@apollo/client';

export const EDIT_INDICATIVE_PRICES = gql`
  mutation UpdateIndicativePrice($input: IndicativePricesInput!) {
    updateIndicativePrice(input: $input) {
      success
      message
    }
  }
`;
