import React, { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import Sidebar from '@components/organisms/sidebar';
import SidebarMobile from '@components/organisms/sidebar-mobile';
import { LAPTOP_BREAKPOINT } from '@utils/constants/breakpoints';
import styles from './style.module.css';

type LayoutProps = {
  extraClasses?: string;
  children: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ extraClasses, children }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= LAPTOP_BREAKPOINT) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={twMerge('flex flex-col w-full md:h-full md:flex-row md:fixed bg-brand-light-20', extraClasses)}>
      <div className="md:hidden">
        <SidebarMobile />
        {children}
      </div>
      <div className={twMerge(styles.mainContent, !collapsed ? 'grid-cols-sidebar' : 'grid-cols-sidebar-collapsed')}>
        <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
        <div className="overflow-y-auto">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
