import { gql } from '@apollo/client';

export const GET_MCP_PROPOSALS = gql`
  query GET_MCP_PROPOSALS($page: Int!, $traderId: ID, $filters: FilterInput) {
    mcpProposals(page: $page, traderId: $traderId, filters: $filters) {
      page
      limit
      total
      data {
        id
        volume
        transactionType
        energyType
        deadline
        period
        status
        submarket
        trader {
          id
        }
        group {
          name
        }
        expiredMcpBids {
          id
          trader {
            id
          }
          spread
          bestOffer
          deadline
          winnerAt
        }
        mcpBids {
          id
          trader {
            id
          }
          spread
          bestOffer
          deadline
          winnerAt
        }
      }
    }
  }
`;
