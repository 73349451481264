import { gql } from '@apollo/client';

export const CREATE_INDICATIVE_PRICES = gql`
  mutation CreateIndicativePrices($input: [IndicativePricesInput!]!) {
    createIndicativePrices(input: $input) {
      success
      message
    }
  }
`;
