import { formatDateFromString } from '@utils/dayjs';
import { ProposalPayload } from './types';

export type TransactionType = 'SALE' | 'PURCHASE';
export type Submarket = 'NORTH' | 'NORTHEAST' | 'SOUTH' | 'SOUTHEAST';
export type EnergyType = 'CONVENTIONAL' | 'INCENTIVIZED_0' | 'INCENTIVIZED_50' | 'INCENTIVIZED_80' | 'INCENTIVIZED_100';
export type Status = 'ACTIVE' | 'FINISHED' | 'CANCELED';
export type ReviewStatus = 'FINAL' | 'DRAFT';

export interface ProposalPayloadParsed {
  transactionType: TransactionType;
  traderId?: string | null;
  groupId?: string | null;
  energyType: EnergyType;
  volume: number | null;
  period: string;
  deadline: string;
  status: Status;
  submarket: Submarket;
  proposalCommission?: number;
  reviewStatus: ReviewStatus;
}

const parsePayload = (payload: ProposalPayload, groupId: string, traderId: string): ProposalPayloadParsed => {
  const { contractType, energyType, submarket, supply, volume, commission, deadline } = payload;

  return {
    traderId,
    groupId,
    transactionType: contractType as TransactionType,
    energyType: energyType as EnergyType,
    volume,
    period: formatDateFromString(supply, 'MMYYYY', 'YYYY-MM-DD'),
    deadline: formatDateFromString(deadline, 'DDMMYYYYHHmm', 'YYYY-MM-DD HH:mm:ss'),
    status: 'ACTIVE',
    submarket: submarket as Submarket,
    proposalCommission: commission ?? undefined,
    reviewStatus: 'FINAL',
  };
};

export default parsePayload;
