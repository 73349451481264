import React, { useState, useEffect } from 'react';

import CommissionSection from '@components/molecules/proposal-form/commission-section ';
import ContractTypeSection from '@components/molecules/proposal-form/contract-type-section';
import GroupInfoSection from '@components/molecules/proposal-form/group-info-section';
import ProposalEnergySection from '@components/molecules/proposal-form/proposal-energy-section';
import ButtonComponent, { ButtonComponentProps } from '@components/atoms/button';

import style from './style.module.css';

interface ContainerProps {
  adminMode: boolean;
  formType: 'SALE' | 'PURCHASE';
  buttons: ButtonComponentProps[];
  loading?: boolean;
}

const Container: React.FC<ContainerProps> = ({ formType, buttons, adminMode, loading }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 767);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  return (
    <>
      <div className="flex flex-col gap-9 max-w-[56.25rem] bg md:gap-[3.750rem]">
        <ContractTypeSection loading={loading ?? false} />
        <GroupInfoSection type={loading ? '' : formType} loading={loading ?? false} />
        <ProposalEnergySection loading={loading ?? false} />
        {adminMode && <CommissionSection loading={loading ?? false} />}
      </div>

      {!loading && (
        <div className={style.ProposalFormButtons}>
          {buttons.map((button, idx) => (
            <ButtonComponent {...button} screen={isSmallScreen ? 'mobile' : 'desktop'} key={`btn-${idx}`} />
          ))}
        </div>
      )}
    </>
  );
};

export default Container;
