import React from 'react';
import ReactECharts, { EChartsReactProps } from 'echarts-for-react';

interface ChartProps extends EChartsReactProps {
  className?: string;
}

export const ChartContent: React.FC<ChartProps> = ({ className, ...attrs }) => {
  const options = {
    ...attrs.option,
    series: attrs.option?.series.map((series: any) => ({
      ...series,
      ...(series.type === 'line' && {
        showSymbol: false,
        lineStyle: {
          type: 'dotted',
        },
      }),
      ...(series.type === 'bar' && {
        barWidth: 24,
        barGap: 0.25,
        itemStyle: {
          ...series.itemStyle,
          borderRadius: [12, 12, 0, 0],
          width: 24,
          color: series.itemStyle?.color ?? '#55EC8F',
        },
      }),
    })),
    xAxis: {
      ...attrs.option.xAxis,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
  };

  return <ReactECharts className={className} {...attrs} option={options} />;
};
