import numeral from 'numeral';
import dayjs from 'dayjs';

import { checkIfDateIsBeforeCurrentDate, formatStringToMonthYear, nearExpirationDate } from '@utils/dayjs';

import TreeIcon from '@assets/tree-icon.svg';
import { EnergyTypeEnum, SubmarketEnum } from '@utils/translators';
import {
  EnergyType,
  ExpiredBids,
  MCPBidsType,
  McpProposalType,
  SubmarketType,
  TransactionType,
} from '@hooks/get-mcp-proposals/types';
import { RankingInfoComponentProps } from '@components/molecules/ranking';
import NuggetIconsComponent from '@components/atoms/nugget-info/nugget-icons';
import { TagComponentProps } from '@components/atoms/tag';

interface NuggetProposalInfoProps {
  deadline: string;
  period: string;
  energyType: EnergyType;
  submarket: SubmarketType;
}

interface TagsInfoListProps {
  isAdmin: boolean | null;
  totalBids: number | undefined;
  meAsBestPrice: boolean;
  bestOffer: string | undefined;
  proposalStatus: McpProposalType['status'];
  proposalDeadline: string;
  userNotSentBid: boolean;
  expiredProposal: boolean;
  hasExpiredBidForTrader: boolean;
  userSentProposal: boolean;
  nearToDeadline: boolean;
}

export type Status = 'BEST PRICE EDITION' | 'BEST PRICE' | 'EDITION' | 'EXPIRED' | undefined;

const checkStatus = (index: number, ableToEdit: boolean) => {
  if (index === 0 && ableToEdit) return 'BEST PRICE EDITION';

  if (index === 0 && !ableToEdit) return 'BEST PRICE';

  if (index !== 0 && ableToEdit) return 'EDITION';

  return undefined;
};

const findBidOutsideTopPositionsRanking = (ranking: RankingInfoComponentProps[]) =>
  ranking.find(({ supplier }, idx) => supplier === 'Seu Preço' && idx > 2);

export const rankingList = (
  bids: McpProposalType['mcpBids'] | McpProposalType['expiredMcpBids'],
  traderId: string,
  proposalStatus: string,
  proposalExpired: boolean,
) => {
  if (!bids) return;

  const ranking: RankingInfoComponentProps[] = [];

  const forEdit = (bid: MCPBidsType | ExpiredBids) => bid.trader.id === traderId && proposalStatus === 'ACTIVE';

  const lastBestOfferIndex = bids.reduce((lastIndex, bid, index) => {
    return bid.bestOffer ? index : lastIndex;
  }, -1);

  bids.forEach((bid: MCPBidsType | ExpiredBids, index: number) =>
    ranking.push({
      id: bid.id,
      icon: bid.bestOffer
        ? 'iconGold'
        : index === lastBestOfferIndex + 1
          ? 'iconSilver'
          : index === lastBestOfferIndex + 2
            ? 'iconBronze'
            : undefined,
      supplier: bid.trader.id === traderId ? 'Seu Preço' : `Fornecedor ${index + 1}`,
      price: bid.spread,
      deadline: bid.deadline,
      status: checkStatus(index, proposalExpired ? forEdit(bid) : false) as Status,
      winnerTag: bid.winnerAt !== null,
    }),
  );

  const traderBidOutsideTopPositions = findBidOutsideTopPositionsRanking(ranking);

  const rankingBestPositions = ranking.length > 3 ? ranking.slice(0, 3) : ranking;

  const targetTraderBidWithBestPosisitionsRanking = traderBidOutsideTopPositions
    ? [...rankingBestPositions, traderBidOutsideTopPositions]
    : rankingBestPositions;

  return targetTraderBidWithBestPosisitionsRanking;
};

const createBidTag = (tags: TagsInfoListProps): TagComponentProps => {
  if (tags.userNotSentBid && !tags.hasExpiredBidForTrader) {
    return {
      kind: 'icon',
      label: 'Proposta não enviada',
      color: 'ghost',
      icon: 'DocumentIcon',
      size: '1',
    };
  }

  if (tags.hasExpiredBidForTrader && tags.userNotSentBid) {
    return {
      kind: 'icon',
      label: 'Proposta expirada',
      color: 'yellowLight',
      icon: 'ClockIcon',
      size: '1',
    };
  }

  if (tags.meAsBestPrice) {
    return {
      kind: 'icon',
      label: 'Melhor preço',
      color: 'primaryLight',
      icon: 'StarIcon',
      size: '1',
    };
  }

  return {
    kind: 'icon',
    headerLabel: 'Melhor preço: ',
    label: `PLD + ${tags.bestOffer}`,
    color: 'yellowLight',
    icon: 'CurrencyDollarIcon',
    size: '1',
  };
};

const createBidAmountTag = (totalBids: number): TagComponentProps => ({
  kind: 'icon',
  label: `${totalBids ?? 0} propostas`,
  color: 'default',
  size: '1',
  icon: 'DocumentIcon',
});

const createProposalTag = (
  status: McpProposalType['status'],
  deadline: string,
  expired: boolean,
  nearToDeadline: boolean,
): TagComponentProps => {
  if (status === 'ACTIVE' && !expired) {
    return {
      kind: 'icon',
      label: `Prazo: ${deadline}`,
      color: nearToDeadline ? 'orangeLight' : 'secondaryLight',
      icon: 'ClockIcon',
      size: '1',
    };
  }

  if (status === 'ACTIVE' && expired) {
    return {
      kind: 'icon',
      label: 'Prazo expirado',
      color: 'ghost',
      icon: 'ClockIcon',
      size: '1',
    };
  }

  return {
    kind: 'icon',
    label: 'Concluído',
    color: 'default',
    icon: 'CheckIcon',
    size: '1',
  };
};

export const tagsInfoList = (tags: TagsInfoListProps): TagComponentProps[] => {
  const bidsAmountTag = createBidAmountTag(tags.totalBids || 0);
  const bidTag = tags.userSentProposal ? bidsAmountTag : createBidTag(tags);
  const proposalTag = createProposalTag(
    tags.proposalStatus,
    tags.proposalDeadline,
    tags.expiredProposal,
    tags.nearToDeadline,
  );

  if (tags.isAdmin) {
    return [bidsAmountTag, proposalTag];
  }

  return [bidTag, proposalTag];
};

export const dataProposalList = (nuggetProposalInfo: NuggetProposalInfoProps) => [
  {
    icon: (
      <NuggetIconsComponent
        iconName="ClockIcon"
        title="Icone de Relogio"
        extraClassNames="rounded-small p-2 bg-brand-light-30 text-brand-gray-70"
      />
    ),
    title: { value: 'Limite para o envio de propostas' },
    content: { value: nuggetProposalInfo.deadline },
  },
  {
    icon: (
      <NuggetIconsComponent
        iconName="CalendarIcon"
        title="Icone de Calendario"
        extraClassNames="rounded-small p-2 bg-brand-light-30 text-brand-gray-70"
      />
    ),
    title: { value: 'Período do fornecimento' },
    content: { value: nuggetProposalInfo.period },
  },
  {
    icon: (
      <NuggetIconsComponent
        iconPath={TreeIcon}
        title="Icone de Arvore"
        extraClassNames="rounded-small p-2 bg-brand-light-30 text-brand-gray-70"
      />
    ),
    title: { value: 'Tipo de energia' },
    content: { value: EnergyTypeEnum[nuggetProposalInfo.energyType as keyof typeof EnergyTypeEnum] },
  },
  {
    icon: (
      <NuggetIconsComponent
        iconName="GlobeAmericasIcon"
        title="Icone do Planeta"
        extraClassNames="rounded-small p-2 bg-brand-light-30 text-brand-gray-70"
      />
    ),
    title: { value: 'Submercado' },
    content: { value: SubmarketEnum[nuggetProposalInfo.submarket as keyof typeof SubmarketEnum] },
  },
];

export interface EnergyProposalListProps {
  proposalId: string;
  isSellingStatus: boolean;
  companyName: string;
  volume?: number;
}

export const energyProposalList = (proposalInfo: EnergyProposalListProps) => [
  {
    id: proposalInfo.proposalId,
    isSellingStatus: proposalInfo.isSellingStatus,
    companyName: proposalInfo.companyName,
    megaWattPerHour: `${numeral(proposalInfo.volume).format('0,0.000')}`,
  },
];

export const TagsInfoData = (
  proposal: McpProposalType,
  traderId: string,
  isAdmin: boolean,
  userSentProposal: boolean,
): TagsInfoListProps => {
  const totalValidBids = proposal.mcpBids?.length ?? 0;
  const totalExpiredBids = proposal.expiredMcpBids?.length ?? 0;
  const bestBids = proposal.mcpBids ? proposal.mcpBids.filter((bid) => bid.bestOffer === true) : undefined;
  const meAsBestPrice = bestBids?.find((bid) => bid.trader.id == traderId) ? true : false;
  const bestOffer = bestBids ? numeral(bestBids[0]?.spread).format('$ 0,0.00') : undefined;
  const proposalDeadline = dayjs(proposal.deadline).format('DD/MM, HH:mm[h]');
  const proposalStatus = proposal.status;
  const totalBids = totalValidBids + totalExpiredBids;
  const hasExpiredBidForTrader =
    proposal.expiredMcpBids?.find(({ trader }) => trader.id === traderId) === undefined ? false : true;
  const userNotSentBid = proposal.mcpBids?.find(({ trader }) => trader.id === traderId) === undefined ? true : false;
  const expiredProposal = checkIfDateIsBeforeCurrentDate(proposal.deadline, 'YYYY-MM-DD HH:mm:ss');
  const nearToDeadline = nearExpirationDate(proposal.deadline);

  return {
    isAdmin,
    totalBids,
    meAsBestPrice,
    bestOffer,
    proposalDeadline,
    proposalStatus,
    userNotSentBid,
    expiredProposal,
    hasExpiredBidForTrader,
    userSentProposal,
    nearToDeadline,
  };
};

export const ProposalInfo = (proposal: McpProposalType, userSentProposal: boolean) => {
  const sellingStatus: TransactionType = 'SALE';

  const proposalId = proposal.id;

  const isSellingStatus = proposal.transactionType === sellingStatus;
  const companyName = userSentProposal ? '' : proposal.group.name;
  const volume = proposal.volume;

  return {
    proposalId,
    isSellingStatus,
    companyName,
    volume,
  };
};

export const NuggetProposalInfo = (proposal: McpProposalType) => {
  const deadline = dayjs(proposal.deadline).format('DD/MM/YYYY [às] HH:mm[h]');
  const period = proposal.period ? formatStringToMonthYear(proposal.period) : '';
  const energyType = proposal.energyType;
  const submarket = proposal.submarket;

  return {
    deadline,
    period,
    energyType,
    submarket,
  };
};

export const handleModalDescription = (isDeleteMode: boolean) =>
  isDeleteMode
    ? 'Essa ação não poderá ser revertida e irá excluir definitivamente a cotação do sistema.'
    : 'Ao confirmar você será redirecionado para o formulário.';
