import React from 'react';
import { twMerge } from 'tailwind-merge';
import HeroIcon from '../hero-icon';
import style from './style.module.css';
import { DOTS, usePaginationRange } from '@hooks/pagination/usePaginationPage';

export interface PaginationComponentProps extends React.HTMLAttributes<HTMLDivElement> {
  totalRows: number;
  rowsByPage: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const PaginationComponent: React.FC<PaginationComponentProps> = ({
  totalRows,
  rowsByPage,
  currentPage,
  setCurrentPage,
  ...attrs
}) => {
  const { className, ...restAttrs } = attrs;
  const totalPages = Math.ceil(totalRows / rowsByPage);
  const paginationRange = usePaginationRange({
    totalPageCount: totalPages,
    currentPage,
    siblingCount: 1,
  });

  const nextPage = () => {
    if (currentPage !== totalPages) setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  return (
    <div className={twMerge(style.paginationContainer, className)} {...restAttrs}>
      <div className="flex items-center h-6 sm:h-7">
        <div className={style.paginationButtons} onClick={previousPage}>
          <HeroIcon icon={'ChevronLeftIcon'} extraClassNames={'w-4 h-full sm:w-5'} />
        </div>

        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <div key={index} className="flex justify-center items-center w-5 h-full sm:w-7">
                ...
              </div>
            );
          }

          const pageIndex = pageNumber as number;
          const isCurrentPage = currentPage === pageIndex;
          return (
            <div
              key={index}
              className={twMerge(
                style.paginationButtons,
                isCurrentPage ? 'bg-brand-primary-10 text-brand-primary-70 font-bold rounded-lg cursor-default' : '',
              )}
              onClick={() => setCurrentPage(pageIndex)}
            >
              {pageIndex}
            </div>
          );
        })}

        <div className={style.paginationButtons} onClick={nextPage}>
          <HeroIcon icon={'ChevronRightIcon'} extraClassNames={'w-4 h-full sm:w-5'} />
        </div>
      </div>
    </div>
  );
};

export default PaginationComponent;
