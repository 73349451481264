import { checkIfDateIsBeforeCurrentDate, checkIfDateisValid } from '@utils/dayjs';

export const validateCompleteDate = (dateStr: string) => {
  const format = 'DDMMYYYYHHmm';

  const isBeforeCurrentDate = checkIfDateIsBeforeCurrentDate(dateStr, format);

  const isValidDate = checkIfDateisValid(dateStr, format);

  if (dateStr.length !== 12) {
    return 'Data incompleta';
  }

  if (isBeforeCurrentDate) {
    return 'Data menor que a data atual';
  }

  if (!isValidDate) {
    return 'Data inválida';
  }

  return true;
};
