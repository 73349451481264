import { Layout } from '@components/layout';
import { INDICATIVE_PRICES_DASHBOARD, SEND_INDICATIVE_PRICES } from '@routers/constants';
import useAuth from '@hooks/auth';
import CreateIndicativePricesForm from '@components/organisms/create-indicative-prices-form';
import CustomMessage from '@components/molecules/custom-message';

const SendIndicativePricesPage = () => {
  const { user } = useAuth();

  const isAdmin = user.roles?.includes('admin') ?? false;
  const isTrader = user.roles?.includes('supplier') ?? false;

  const userNotAuthorized = !isAdmin && !isTrader;

  return (
    <Layout.Content
      header={{
        title: 'Formulário de preços indicativos',
        breadCrumbPaths: [
          { url: INDICATIVE_PRICES_DASHBOARD, label: 'Dashboard' },
          { url: SEND_INDICATIVE_PRICES, label: 'Preços Indicativos' },
        ],
      }}
    >
      {userNotAuthorized ? (
        <CustomMessage
          icon={{
            name: 'LockClosedIcon',
            className: 'text-palette-orange-30 bg-palette-orange-10',
          }}
          title="Você não tem acesso a essa página!"
          content="Infelizmente, o seu usuário não tem acesso a essa página. Solicite acesso e aguarde liberação para prosseguir ou volte à página inicial."
        />
      ) : (
        <CreateIndicativePricesForm />
      )}
    </Layout.Content>
  );
};

export default SendIndicativePricesPage;
