import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import useAuth from '@hooks/auth';
import useProposalById from '@hooks/get-mcp-proposal-by-id/useProposalById';
import { EditProposalPayload } from '@hooks/edit-mcp-proposal/types';
import { parseProposalEditPayload } from '@hooks/get-mcp-proposal-by-id/parser';

import { Layout } from '@components/layout';
import EditProposalFormSkeleton from '@components/organisms/mcp-edit-proposal-form/proposal-skeleton';
import EditProposalForm from '@components/organisms/mcp-edit-proposal-form';

import { breadCrumb, headerTitleTranslate } from '@pages/mcp-create-proposal/helper';

const EditProposalPage: React.FC = () => {
  const [headerTitle, setHeaderTitle] = useState<'PURCHASE' | 'SALE'>('SALE');
  const { proposalId } = useParams();

  const getToCreateBid = false;
  const { getProposal, rawData, loading } = useProposalById(getToCreateBid);

  useEffect(() => {
    getProposal({ variables: { id: proposalId } });
  }, []);

  const { user } = useAuth();

  const isAdmin = user.roles?.includes('admin');

  const formInitialValues = !loading && rawData ? parseProposalEditPayload(rawData) : {};

  return (
    <Layout.Content
      header={{
        title: `Cotação de ${headerTitleTranslate[headerTitle]}`,
        breadCrumbPaths: breadCrumb,
      }}
    >
      {loading ? (
        <EditProposalFormSkeleton isAdmin={!!isAdmin} loading={loading} />
      ) : (
        <EditProposalForm
          isAdmin={!!isAdmin}
          setHeaderTitle={setHeaderTitle}
          initialValues={formInitialValues as EditProposalPayload}
          loading={loading}
        />
      )}
    </Layout.Content>
  );
};

export default EditProposalPage;
