import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { CheckCurrentWeekPriceSubmissionData } from './types';
import { CHECK_CURRENT_WEEK_PRICE_SUBMISSION } from './queries';

export const useCheckCurrentWeekPriceSubmission = (isAdmin: boolean) => {
  if (!isAdmin) {
    const [traderId, setTraderId] = useState<string>('');
    const skipQuery = traderId === '';

    const { data, error, loading } = useQuery<CheckCurrentWeekPriceSubmissionData>(
      CHECK_CURRENT_WEEK_PRICE_SUBMISSION,
      {
        variables: { traderId },
        skip: skipQuery,
      },
    );

    return {
      success: data?.checkCurrentWeekPriceSubmission.success,
      error,
      loading,
      setTraderId,
    };
  } else {
    return {
      success: [],
      error: null,
      loading: false,
      setTraderId: () => {},
    };
  }
};
