export type TransactionType = 'SALE' | 'PURCHASE';

export interface ProposalBidPayload {
  spread: number | string;
  paymentDay: number | string;
  tusdPayment: number | string;
  numericTusd?: number | string;
  numericPaymentDay?: number | string;
  deadline: string;
}

export const EMPTY_PROPOSAL_BID_PAYLOAD: ProposalBidPayload = {
  spread: '',
  paymentDay: 6,
  numericPaymentDay: '',
  tusdPayment: '',
  numericTusd: '',
  deadline: '',
};

export interface ParsedBidPayload {
  mcpProposalId: string;
  traderId: string;
  deadline: string;
  paymentDay: number;
  spread: number | null;
  tusdRefund: number | null;
  transactionType: TransactionType;
}
