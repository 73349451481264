import { useLazyQuery } from '@apollo/client';
import { GET_GROUP_BY_DOC_NUMBER } from './queries';
import { GroupResponse } from './types';

export const useGroupInfo = () => {
  const [getGroupInfo, { data, loading, error }] = useLazyQuery<GroupResponse>(GET_GROUP_BY_DOC_NUMBER);

  return {
    getGroupInfo,
    data: data?.groupByDocNumber,
    loading,
    error,
  };
};
