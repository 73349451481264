import HeaderTitle from './title';
import HeaderButtons from './buttons';
import HeaderBreadCrumb from './bread-crumb';
import LayoutHeader from './container';

export const Header = {
  Container: LayoutHeader,
  Title: HeaderTitle,
  Buttons: HeaderButtons,
  BreadCrumb: HeaderBreadCrumb,
};
