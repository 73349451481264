import React from 'react';
import { twMerge } from 'tailwind-merge';
import * as Progress from '@radix-ui/react-progress';
import { ProgressProps, ProgressSize } from '@components/atoms/progress/root';
import style from './style.module.css';

interface ProgressLabelProps {
  progressValue: number;
}

export interface ProgressBarProps extends ProgressProps {}
const ProgressLabel: React.FC<ProgressLabelProps> = ({ progressValue }) => (
  <div className={twMerge(style.label, progressValue < 50 ? 'text-brand-gray-30' : 'text-brand-light-20')}>
    {`${progressValue}%`}
  </div>
);

export const ProgressBar: React.FC<ProgressBarProps> = ({ progressValue, size }) => {
  const SIZE_CLASS_NAME: Record<ProgressSize, string> = {
    small: 'h-2',
    medium: 'h-3 text-caption-small',
    large: 'h-4 text-caption-medium',
  };

  const dynamicClassName = twMerge(SIZE_CLASS_NAME[size]);

  return (
    <Progress.Root className={twMerge(style.progressRoot, dynamicClassName)} value={progressValue}>
      <Progress.Indicator
        className={style.progressIndicator}
        style={{ transform: `translateX(-${100 - progressValue}%)` }}
        aria-valuetext={`${progressValue}%`}
      />
      {size !== 'small' && <ProgressLabel progressValue={progressValue} />}
    </Progress.Root>
  );
};
