import { GetIndicativePricesBySubmarketQueryFilter } from '@hooks/indicative-prices/queries/get-by-submarket/types';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

interface IndicativePricesFilterContextProps {
  priceFilters: GetIndicativePricesBySubmarketQueryFilter;
  setPriceFilters: React.Dispatch<React.SetStateAction<GetIndicativePricesBySubmarketQueryFilter>>;
  labelMapping: LabelMapping;
  setLabelMapping: React.Dispatch<React.SetStateAction<LabelMapping>>;
  refetchFilters: boolean;
  setRefetchFilters: React.Dispatch<React.SetStateAction<boolean>>;
  clearFilters: () => void;
}

interface LabelMapping {
  years: Record<string, string>;
  traders: Record<string, string>;
  weeks: Record<string, string>;
  energyTypes: Record<string, string>;
  startDate: string | null;
  endDate: string | null;
}

const IndicativePricesFilterContext = createContext<IndicativePricesFilterContextProps | undefined>(undefined);

export const IndicativePricesFilterProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const initialFilters: GetIndicativePricesBySubmarketQueryFilter = {
    startDate: null,
    endDate: null,
    traders: [],
    energyTypes: [],
    years: [],
    weeks: [],
  };

  const [priceFilters, setPriceFilters] = useState<GetIndicativePricesBySubmarketQueryFilter>(initialFilters);

  const [labelMapping, setLabelMapping] = useState<LabelMapping>({
    years: {},
    traders: {},
    weeks: {},
    energyTypes: {},
    startDate: null,
    endDate: null,
  });

  const [refetchFilters, setRefetchFilters] = useState(false);

  const clearFilters = useCallback(() => {
    setPriceFilters(initialFilters);
    setRefetchFilters(true);
  }, [initialFilters]);

  const contextValue = useMemo(
    () => ({
      priceFilters,
      setPriceFilters,
      labelMapping,
      setLabelMapping,
      refetchFilters,
      setRefetchFilters,
      clearFilters,
    }),
    [priceFilters, setPriceFilters, labelMapping, setLabelMapping, refetchFilters, setRefetchFilters, clearFilters],
  );

  return (
    <IndicativePricesFilterContext.Provider value={contextValue}>{children}</IndicativePricesFilterContext.Provider>
  );
};

export const useIndicativePricesFiltersContext = (): IndicativePricesFilterContextProps => {
  const context = useContext(IndicativePricesFilterContext);
  if (!context) {
    throw new Error('useIndicativePricesFiltersContext must be used within an IndicativePricesFilterProvider');
  }
  return context;
};
