import React from 'react';
import Text from '@components/atoms/text';

interface FormEditModalProps {
    title?: string;
    description?: string;
    children: React.ReactNode;
}

const FormEditModal: React.FC<FormEditModalProps> = ({ title, description, children }) => {
    return (
        <div className="flex flex-col gap-2">
            <div>
                <Text weight="font-bold" size="text-paragraph-medium" color="text-brand-gray-80">
                    {title}
                </Text>
            </div>

            {children}

            <Text weight="font-normal" size="text-caption-small" color="text-brand-gray-80">
                {description}
            </Text>
        </div>
    );
};
export default FormEditModal;
