import React, { ReactNode } from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import { twMerge } from 'tailwind-merge';

interface TabsRootProps {
  children: ReactNode;
  tabValue: string;
  setTabValue: (value: string) => void;
  className?: string;
  defaultValue?: string;
}

export const TabsRoot: React.FC<TabsRootProps> = ({ children, className, defaultValue, tabValue, setTabValue }) => {
  return (
    <Tabs.Root
      className={twMerge('flex flex-col w-full', className)}
      defaultValue={defaultValue}
      value={tabValue}
      onValueChange={setTabValue}
    >
      {children}
    </Tabs.Root>
  );
};
