import React from 'react';
import { Button, ButtonProps, Theme } from '@radix-ui/themes';
import { Responsive } from '@radix-ui/themes/dist/cjs/props/index.js';
import { twMerge } from 'tailwind-merge';
import { IconName } from '@src/components/atoms/hero-icon';
import HeroIcon from '@src/components/atoms/hero-icon';

import style from './style.module.css';
import LoadingIcon from '../load-icon';

export type Variant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'iconPrimary'
  | 'iconSecondary'
  | 'iconTertiary'
  | 'iconQuaternary';
export type IconPosition = 'none' | 'left' | 'right';
export type ButtonScreen = 'mobile' | 'desktop';

export interface ButtonComponentProps extends ButtonProps {
  kind: Variant;
  id?: string;
  icon?: IconName;
  label?: string;
  iconPosition?: IconPosition;
  size?: Responsive<'1' | '2' | '3'>;
  screen?: ButtonScreen;
  customAttrs?: Record<string, string>;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
}

const BUTTON_COLORS = {
  primary: style.primaryStyle,
  secondary: style.secondaryStyle,
  tertiary: style.tertiaryStyle,
  iconPrimary: style.iconPrimaryStyle,
  iconSecondary: style.iconSecondaryStyle,
  iconTertiary: style.iconTertiaryStyle,
  iconQuaternary: style.iconQuaternaryStyle,
};

const BUTTON_style = {
  mobile: {
    minWidth: 'w-full',
    badgeIconSize: 'w-[15px] h-[15px]',
  },
  desktop: {
    badgeIconSize: 'w-[18px] h-[18px]',
  },
};

const ButtonComponent: React.FC<ButtonComponentProps> = ({
  kind,
  label,
  icon,
  iconPosition,
  size,
  screen,
  className,
  onClick,
  loading,
  disabled,
  ...attrs
}) => {
  const btnStyle = BUTTON_COLORS[kind];

  const widthBtn = screen === 'mobile' ? BUTTON_style.mobile.minWidth : '';

  const iconBtn = screen === 'mobile' ? BUTTON_style.mobile.badgeIconSize : BUTTON_style.desktop.badgeIconSize;

  const buttonClasses = twMerge(
    style.btnBehaviour,
    size === '1'
      ? 'p-2 justify-center '
      : size === '2'
        ? 'py-3 px-6 justify-center '
        : size === '3'
          ? 'py-4 px-8 justify-center'
          : 'w-[54px] h-[54px] justify-center',
    widthBtn,
    btnStyle,
    className,
  );

  return (
    <Theme>
      <Button
        role="button"
        aria-label={label}
        aria-disabled={disabled}
        size={size}
        onClick={onClick}
        className={buttonClasses}
        disabled={disabled}
        {...attrs}
      >
        {loading ? (
          <div className="flex gap-2 justify-center items-center">
            <LoadingIcon />
            <p key={2}>Carregando...</p>
          </div>
        ) : (
          <>
            {iconPosition === 'left' && <HeroIcon icon={icon as IconName} className={`place-self-center ${iconBtn}`} />}
            {label}
            {iconPosition === 'right' && (
              <HeroIcon icon={icon as IconName} className={`place-self-center ${iconBtn}`} />
            )}
          </>
        )}
      </Button>
    </Theme>
  );
};

export default ButtonComponent;
