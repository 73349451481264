import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { SubmarketType } from '@hooks/indicative-prices/types';
import { GET_AVERAGE_INDICATIVE_PRICES } from '@hooks/indicative-prices/queries/get_averages/query';
import { GetAllAveragePricesData } from '@hooks/indicative-prices/queries/get_averages/types';

export const useGetAllAveragePrices = () => {
  const [submarketType, setSubmarketType] = useState<SubmarketType | string>(SubmarketType.SOUTHEAST);

  const { data, loading, error } = useQuery<GetAllAveragePricesData>(GET_AVERAGE_INDICATIVE_PRICES, {
    variables: { submarketType },
  });

  return {
    averagePrices: data?.getAveragePrices || [],
    error,
    loading,
    submarketType,
    setSubmarketType,
  };
};
