import React from 'react';
import { twMerge } from 'tailwind-merge';

import style from './style.module.css';

interface ErrorMesageProps extends React.ComponentProps<'span'> {
  error?: string;
  className?: string;
}

const ErrorMessage: React.FC<ErrorMesageProps> = ({ error, ...restAttr }) => {
  return (
    <span className={twMerge(restAttr.className, style.errorText, error ? 'block' : 'hidden')} {...restAttr}>
      {error}
    </span>
  );
};

export default ErrorMessage;
