import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import config from '@config';

import useAuth from '@hooks/auth';
import { useUserTrader } from '@hooks/get-user-trader/useUserTrader';
import useProposalById from '@hooks/get-mcp-proposal-by-id/useProposalById';
import { ProposalInfoParsed } from '@hooks/get-mcp-proposal-by-id/parser';

import { Layout } from '@components/layout';
import BidForm from '@components/organisms/create-proposal-bid-form';

import { breadCrumb } from './helper';

const CreateBidPage: React.FC = () => {
  const [transactionType, setTransactionType] = useState<'PURCHASE' | 'SALE' | ''>('');

  const { proposalId } = useParams<{ proposalId: string }>();

  const { user } = useAuth();
  const { userTrader } = useUserTrader(user.id);

  const isAdmin = user.roles?.includes('admin');
  const traderId = isAdmin ? config.CLARKE_TRADER_ID : userTrader?.id;

  const proposalToCreateBid = true;
  const { getProposal, data, loading } = useProposalById(proposalToCreateBid);

  const proposalInfo = data ?? {};

  const handleTransactionType = () => {
    if (!transactionType.length) return '';

    if (transactionType === 'PURCHASE') return 'Compra';

    return 'Venda';
  };

  useEffect(() => {
    getProposal({ variables: { id: proposalId } });
  }, []);

  return (
    <Layout.Content
      header={{
        title: `Cadastro de Proposta - ${handleTransactionType()}`,
        breadCrumbPaths: breadCrumb,
      }}
    >
      <BidForm
        traderId={traderId}
        setTransactionType={setTransactionType}
        proposal={proposalInfo as ProposalInfoParsed}
        loading={loading}
      />
    </Layout.Content>
  );
};

export default CreateBidPage;
