import { gql } from '@apollo/client';

export const GET_INDICATIVE_PRICES_BY_SUBMARKET = gql`
  query GetIndicativePricesBySubmarket($filter: GetBySubmarketFilter!) {
    getIndicativePricesBySubmarket(filter: $filter) {
      page
      total
      limit
      data {
        id
        trader {
          id
          name
        }
        year
        price
        energyType
        submarketType
        modulationType
        seasonality
        upperFlexibility
        lowerFlexibility
        modalityType
        createdAt
        deletedAt
      }
    }
  }
`;
