import React, { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import HeaderContainer from '../atoms/header/container';
import { HeaderBreadCrumbProps } from '@components/atoms/header/bread-crumb';
import { Header } from '@components/atoms/header';

import styles from './style.module.css';
import { ButtonComponentProps } from '@components/atoms/button';

type LayoutRootProps = {
  extraClasses?: string;
  children: React.ReactNode;
  header?: {
    title: string;
    buttons?: ButtonComponentProps[];
    breadCrumbPaths: HeaderBreadCrumbProps['navigationHistoryLinks'];
  };
};

const LayoutContent = forwardRef<HTMLDivElement, LayoutRootProps>(({ extraClasses, children, header }, ref) => {
  return (
    <>
      {header && (
        <HeaderContainer>
          <Header.BreadCrumb navigationHistoryLinks={header.breadCrumbPaths} />
          <Header.Title title={header.title} />
          {header.buttons && <Header.Buttons buttons={header.buttons} />}
        </HeaderContainer>
      )}
      <div ref={ref} className={twMerge(styles.layoutContent, extraClasses)}>
        {children}
      </div>
    </>
  );
});

LayoutContent.displayName = 'LayoutContent';

export default LayoutContent;
