import ButtonComponent, { ButtonComponentProps } from '@components/atoms/button';

interface AdminActionButtonsProps {
  buttons: ButtonComponentProps[];
}

const AdminActionButtons = ({ buttons }: AdminActionButtonsProps) => {
  return (
    <div className="gap-4 mt-3 lg:flex">
      {buttons.map((button, idx) => (
        <ButtonComponent key={`btn-admin-${idx}`} {...button} className="mt-3 lg:mt-0" />
      ))}
    </div>
  );
};

export default AdminActionButtons;
