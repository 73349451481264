import { TabsTriggers } from '@components/atoms/tabs/list';
import { EnergyType, SubmarketType } from '@hooks/indicative-prices/types';

export const COLORS_GETTER: Record<EnergyType, string[]> = {
  INCENTIVIZED_50: ['#002C80', '#0047CC', '#4787FF', '#80ACFF'],
  INCENTIVIZED_100: ['#008C4C', '#00BF6A', '#19E58A', '#96E9B6'],
  CONVENTIONAL: ['#991700', '#E5391A', '#FF6347', '#F5B0A3'],
};

export const TABS_TRIGGERS: TabsTriggers[] = [
  {
    name: SubmarketType.SOUTHEAST,
    description: 'SE/CO',
  },
  {
    name: SubmarketType.SOUTH,
    description: 'SUL',
  },
  {
    name: SubmarketType.NORTHEAST,
    description: 'Nordeste',
  },
  {
    name: SubmarketType.NORTH,
    description: 'Norte',
  },
];
