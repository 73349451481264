import React from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import style from './style.module.css';
import { twMerge } from 'tailwind-merge';

export interface TabsTriggers {
  name: string;
  description: string;
  className?: string;
}

export interface TabsListProps {
  tabsTriggers: TabsTriggers[];
  className?: string;
}

export const TabsList: React.FC<TabsListProps> = ({ tabsTriggers, className }) => {
  return (
    <Tabs.List className={twMerge(style.listContainer, className)}>
      <div className="w-fit flex">
        {tabsTriggers.map((trigger) => (
          <Tabs.Trigger
            key={trigger.name}
            className={twMerge(style.triggerContainer, trigger.className)}
            value={trigger.name}
          >
            <label className={style.triggerContent}>{trigger.description}</label>
          </Tabs.Trigger>
        ))}
      </div>
    </Tabs.List>
  );
};
