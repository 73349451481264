import { useMutation } from '@apollo/client';

import { EDIT_PROPOSAL } from './mutation';

export const useEditProposal = () => {
  const [editProposalFn, { data, error, loading }] = useMutation(EDIT_PROPOSAL);

  return {
    editProposalFn,
    data,
    error,
    loading,
  };
};
