import { gql } from '@apollo/client';

export const GET_GROUP_BY_DOC_NUMBER = gql`
  query GetGroupByDocNumber($docNumber: String!) {
    groupByDocNumber(docNumber: $docNumber) {
      id
      name
      legalName
    }
  }
`;
