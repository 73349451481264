import ApolloWrapper from './ApolloWrapper';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import configuration from '@config';
import MainRouter from '@routers/main-router';

function App() {
  const navigate = useNavigate();
  const onRedirectCallback = (appState?: AppState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={configuration.AUTH0_DOMAIN}
      clientId={configuration.AUTH0_CLIENT_ID}
      authorizationParams={{
        audience: `https://${configuration.AUTH0_DOMAIN}/api/v2/`,
        redirect_uri: window.location.origin,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <ApolloWrapper>
        <MainRouter />
      </ApolloWrapper>
    </Auth0Provider>
  );
}

export default App;
