import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import style from './style.module.css';

interface InputRootProps {
  children: ReactNode;
  className?: string;
}

export const InputRoot: React.FC<InputRootProps> = ({ children, className }) => {
  return <div className={twMerge(style.root, className)}>{children}</div>;
};
