import { useMutation } from '@apollo/client';
import { GET_MCP_PROPOSALS } from '@hooks/get-mcp-proposals/queries';

import { EDIT_MCP_BID } from './mutation';

export const useEditMcpBid = () => {
  const [editMcpBidFn, { data, error, loading }] = useMutation(EDIT_MCP_BID, {
    fetchPolicy: 'network-only',
    refetchQueries: [{ query: GET_MCP_PROPOSALS }],
  });

  return {
    editMcpBidFn,
    data,
    error,
    loading,
  };
};
