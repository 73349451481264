import React, { LabelHTMLAttributes, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import style from './style.module.css';
import { TooltipComponent } from '@components/atoms/tooltip';

export interface InputLabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  tooltipContent?: string;
  required?: boolean;
}

export const InputLabel: React.FC<InputLabelProps> = ({ content, tooltipContent, required, className }) => {
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  return (
    <div className={style.labelContainer}>
      <label className={twMerge(style.labelContent, className)}>{content}</label>
      {required && <span className="text-palette-orange-30">*</span>}
      {tooltipContent && (
        <>
          <TooltipComponent.Provider>
            <TooltipComponent.Root open={openTooltip} onOpenChange={setOpenTooltip}>
              <TooltipComponent.Trigger
                icon="InformationCircleIcon"
                iconClassName="fill-brand-gray-50 text-brand-light-20"
                onClick={() => setOpenTooltip((prev) => !prev)}
              />
              <TooltipComponent.Portal>
                <TooltipComponent.Content content={tooltipContent} side="top" />
              </TooltipComponent.Portal>
            </TooltipComponent.Root>
          </TooltipComponent.Provider>
        </>
      )}
    </div>
  );
};
