import React, { ReactNode } from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import { twMerge } from 'tailwind-merge';

interface TabsContentProps {
  children: ReactNode;
  className?: string;
  triggerName: string;
}

export const TabsContent: React.FC<TabsContentProps> = ({ children, className, triggerName }) => {
  return (
    <Tabs.Content className={twMerge('grow', className)} value={triggerName}>
      {children}
    </Tabs.Content>
  );
};
