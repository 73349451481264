import React, { useState } from 'react';

import style from './style.module.css';
import HeroIcon from '@components/atoms/hero-icon';
import { twMerge } from 'tailwind-merge';

export interface InputPasswordContentProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'ref' | 'type'> {
  value: string | number;
  inputRef?: ((el: HTMLInputElement) => void) | React.Ref<any> | undefined;
  error?: string;
}

export const InputPasswordContent: React.FC<InputPasswordContentProps> = ({ inputRef, error, ...attrs }) => {
  const { id, className, disabled, required, placeholder, ...restAttrs } = attrs;
  const [isPasswordHidden, setIsPasswordHidden] = useState<boolean>(true);

  const focusClass = error ? style.contentFocusOnError : style.contentFocus;

  const disabledBorder = disabled ? style.disabledBorder : '';

  const dynamicClasses = twMerge(focusClass, className, disabledBorder);

  return (
    <div className={style.inputContainer}>
      <input
        id={id}
        type={isPasswordHidden ? 'password' : 'text'}
        className={twMerge(style.content, dynamicClasses)}
        disabled={disabled}
        required={required}
        placeholder={placeholder}
        {...restAttrs}
        ref={inputRef}
      />
      <div className={twMerge(style.iconPositionInsideContent, 'flex gap-1')}>
        {error && <HeroIcon className={twMerge(style.icon, style.iconOnError)} icon={'ExclamationCircleIcon'} />}
        <button type="button" onClick={() => setIsPasswordHidden(!isPasswordHidden)}>
          <HeroIcon className={style.icon} icon={isPasswordHidden ? 'EyeIcon' : 'EyeSlashIcon'} />
        </button>
      </div>
    </div>
  );
};
