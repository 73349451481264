import React from 'react';
import HeroIcon from '@components/atoms/hero-icon';

export interface EditButtonProps {
  onEditClick: VoidFunction;
}

const EditButton: React.FC<EditButtonProps> = ({ onEditClick }) => {
  return (
    <div
      className="w-7 flex items-center justify-center cursor-pointer p-1 hover:bg-brand-gray-10 rounded-lg"
      onClick={onEditClick}
    >
      <HeroIcon className="w-5 h-5" icon={'PencilSquareIcon'} />
    </div>
  );
};

export default EditButton;
