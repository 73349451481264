import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import Text from '../text';
import { TooltipComponent } from '../tooltip';
import style from './style.module.css';

export type TextSize = 'small' | 'medium' | 'large';
export type TextWeight = 'normal' | 'bold';
export type TextColor = 'black' | 'gray';

interface Text {
  weight?: TextWeight;
  size?: TextSize;
  className?: string;
  value: string;
  color?: TextColor;
}

export interface NuggetInfoComponentProps {
  icon?: React.ReactNode;
  title: Text;
  content?: Text;
  extraClassNames?: string;
  badgeColor?: string;
  tooltip?: {
    display: boolean;
    content: string;
  };
}

export const NuggetInfoComponent: React.FC<NuggetInfoComponentProps> = ({
  icon,
  title,
  content,
  extraClassNames = '',
  badgeColor,
  tooltip,
}) => {
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);

  const textTitleSize =
    title.size === 'large'
      ? 'text-paragraph-medium md:text-heading-xSmall'
      : title.size === 'medium'
        ? 'text-caption-medium'
        : title.size === 'small'
          ? 'text-paragraph-small'
          : 'text-caption-small';

  const textTitleWeight = title.weight === 'bold' ? 'font-bold' : 'font-regular';

  const textContentSize =
    content?.size === 'large'
      ? 'text-heading-xSmall'
      : content?.size === 'medium'
        ? 'text-caption-medium md:text-paragraph-medium'
        : 'text-paragraph-small';
  const textContentWeight = content?.weight === 'bold' ? 'font-bold' : 'font-regular';

  const textTitleColor = title.color === 'gray' ? 'text-brand-gray-40' : 'text-brand-gray-80';
  const textContentColor = content?.color === 'gray' ? 'text-brand-gray-40' : 'text-brand-gray-80';
  return (
    <div className={twMerge(style.NuggetInfoContainer, extraClassNames)}>
      {icon ? <div className={twMerge(style.BadgeRankingStyle, badgeColor)}>{icon}</div> : null}
      <div className="flex flex-col text-brand-gray-80">
        <div>
          <Text size={textTitleSize} weight={textTitleWeight} color={textTitleColor}>
            {title.value}
          </Text>
          {tooltip?.display && (
            <TooltipComponent.Provider>
              <TooltipComponent.Root open={openTooltip} onOpenChange={setOpenTooltip}>
                <TooltipComponent.Trigger
                  icon="InformationCircleIcon"
                  iconClassName="fill-brand-gray-50 text-brand-light-20"
                  onClick={() => setOpenTooltip((prev) => !prev)}
                />
                <TooltipComponent.Portal>
                  <TooltipComponent.Content content={tooltip.content} side="top" />
                </TooltipComponent.Portal>
              </TooltipComponent.Root>
            </TooltipComponent.Provider>
          )}
        </div>
        <Text size={textContentSize} weight={textContentWeight} color={textContentColor}>
          {content?.value}
        </Text>
      </div>
    </div>
  );
};

export interface NuggetInfoCollectionProps {
  infos: NuggetInfoComponentProps[];
}

const NuggetInfoCollection: React.FC<NuggetInfoCollectionProps> = ({ infos }) => {
  return (
    <>
      {infos?.map((info, index) => (
        <NuggetInfoComponent
          key={`ngt-${index}`}
          icon={info.icon}
          title={info.title}
          content={info.content}
          badgeColor={info.badgeColor}
          tooltip={info.tooltip}
        />
      ))}
    </>
  );
};

export default NuggetInfoCollection;
