import React, { useState } from 'react';

import Text from '@components/atoms/text';

import sytle from './style.module.css';
import { TooltipComponent } from '@components/atoms/tooltip';

interface Tooltip {
  display: boolean;
  content: string;
  boldWorlds?: string[];
}

interface FormSectionProps {
  title: string;
  description: string;
  tooltip?: Tooltip;
  children: React.ReactNode;
}

const FormSection: React.FC<FormSectionProps> = ({ title, description, tooltip, children }) => {
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);

  return (
    <div className={sytle.mainContainer}>
      <div className={sytle.titleAndDescriptionContainer}>
        <div>
          <Text weight="font-bold" size="text-paragraph-large md:text-heading-xSmall" color="text-brand-gray-70">
            {title}
          </Text>
          {tooltip?.display && (
            <TooltipComponent.Provider>
              <TooltipComponent.Root open={openTooltip} onOpenChange={setOpenTooltip}>
                <TooltipComponent.Trigger
                  icon="InformationCircleIcon"
                  iconClassName="fill-brand-gray-50 text-brand-light-20"
                  onClick={() => setOpenTooltip((prev) => !prev)}
                />
                <TooltipComponent.Portal>
                  <TooltipComponent.Content content={tooltip?.content} side="top" />
                </TooltipComponent.Portal>
              </TooltipComponent.Root>
            </TooltipComponent.Provider>
          )}
        </div>
        <Text
          weight="font-light md:font-normal"
          size="text-caption-medium md:text-paragraph-medium"
          color="text-brand-gray-40"
        >
          {description}
        </Text>
      </div>
      <div className={sytle.fieldsContainer}>{children}</div>
    </div>
  );
};

export default FormSection;
