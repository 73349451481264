import { DistinctFilters, Trader } from './types';

export interface DistinctFiltersParsed {
  traders: { label: string; value: string }[];
  weeks: { label: string; value: string }[];
  years: { label: string; value: string }[];
}

const parseDistinctFilters = (filters: DistinctFilters): DistinctFiltersParsed => {
  if (!filters) return { traders: [], weeks: [], years: [] };
  return {
    traders: filters.traders.map((trader: Trader) => ({
      label: trader.name || 'Desconhecido',
      value: trader.id,
    })),
    weeks: filters.weeks
      .map((week) => ({
        label: `${week}`,
        value: `${week}`,
      }))
      .sort((a, b) => parseInt(a.value) - parseInt(b.value)),
    years: filters.years
      .map((year) => ({
        label: `${year}`,
        value: `${year}`,
      }))
      .sort((a, b) => parseInt(a.value) - parseInt(b.value)),
  };
};

export default parseDistinctFilters;
