import React from 'react';
import { useFormContext } from 'react-hook-form';
import { EnergyType, SubmarketType } from '@hooks/indicative-prices/types';
import { Input } from '@components/atoms/form/input';
import SubmarketFormContainer from './submarket-form-container';
import { CheckboxComponent } from '@components/atoms/checkbox';

export interface SubmarketSectionProps {
  title: string;
  description: string;
  submarketType: SubmarketType;
  energyType: EnergyType;
  isRequired: boolean;
  isDisabled: boolean;
  onCheckboxChange: (submarketType: SubmarketType, checked: boolean) => void;
  handleCopyData: (data: Record<string, any>, energyType: string, submarketType: string) => void;
  handlePasteData: (setValue: any, destEnergyType: string, destSubmarketType: string) => void;
}

const SubmarketSection: React.FC<SubmarketSectionProps> = ({
  title,
  description,
  submarketType,
  energyType,
  isRequired,
  isDisabled,
  onCheckboxChange,
  handleCopyData,
  handlePasteData,
}) => {
  const currentYear = new Date().getFullYear();
  const submarketFormat = {
    placeholder: 'R$ 00,00',
    prefix: 'R$ ',
    allowNegative: false,
    fixedDecimalScale: true,
    decimalScale: 2,
  };

  const { getValues, setValue, clearErrors } = useFormContext();
  const idFieldDisabled = !isRequired || isDisabled;

  const onCopy = () => {
    const currentValues = getValues();
    handleCopyData(currentValues, energyType, submarketType);
  };

  const onPaste = () => {
    handlePasteData(setValue, energyType, submarketType);
  };

  const handleCheckboxChange = (checked: boolean) => {
    onCheckboxChange(submarketType, checked);
    const fieldsToClearErrors: string[] = [];

    for (let i = 0; i < 5; i++) {
      const year = currentYear + i;
      const fieldName = `${energyType}-${submarketType}-${year}`;
      fieldsToClearErrors.push(fieldName);
    }

    clearErrors(fieldsToClearErrors);
  };

  return (
    <SubmarketFormContainer title={title} description={description} onCopy={onCopy} onPaste={onPaste}>
      <div className="flex flex-col gap-2">
        <div className="grid grid-cols-3 gap-4 w-full md:grid-cols-5 lg:gap-5">
          {Array.from({ length: 5 }, (_, idx) => (
            <Input.Root className="w-full" key={idx}>
              <Input.Label
                content={`${currentYear + idx}`}
                className="font-bold text-paragraph-medium"
                required={!idFieldDisabled}
              />
              <Input.NumericContent
                fieldName={`${energyType}-${submarketType}-${currentYear + idx}`}
                className="w-full"
                formatProps={submarketFormat}
                rules={{ required: { value: !idFieldDisabled, message: 'Campo obrigatório' } }}
                disabled={idFieldDisabled}
              />
              <Input.Error fieldName={`${energyType}-${submarketType}-${currentYear + idx}`} />
            </Input.Root>
          ))}
        </div>
        <CheckboxComponent.Root className="mb-4" size="small">
          <CheckboxComponent.Indicator
            checked={isDisabled}
            onCheckedChange={(checked) => handleCheckboxChange(checked)}
            type="semi-rounded"
          />
          <CheckboxComponent.Label className="text-caption-small" content={`Opto por não preencher este submercado.`} />
        </CheckboxComponent.Root>
      </div>
    </SubmarketFormContainer>
  );
};

export default SubmarketSection;
