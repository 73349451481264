import React from 'react';

import Text from '@components/atoms/text';
import RankingComponent, { RankingInfoComponentProps } from '@components/molecules/ranking';
import style from './style.module.css';

interface ValidRankingProps {
  ranking: RankingInfoComponentProps[];
  onClick: () => void;
}

const ValidRanking: React.FC<ValidRankingProps> = ({ ranking, onClick }) => {
  return (
    <>
      <Text className={style.RankingTitleProposalItem}>Ranking das propostas enviadas</Text>
      <RankingComponent rankingInfos={ranking} onClick={onClick} />
    </>
  );
};

export default ValidRanking;
